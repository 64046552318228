import React, {Component} from 'react';

import css from "./AnimatedLogo.module.scss"
import ReactSVG from "react-svg";
import LogoBackground from "../../../assets/figures/logo-background.svg";
import Logo from "../../../assets/full-logo.svg";

class AnimatedLogo extends Component {

    componentDidMount(): void {
        this.setState({loaded: true});
    }

    state = {
        loaded: false
    };

    render() {
        return (
            <React.Fragment>
                <div className={css["logo-background"]}>
                    <div className={css["ripple-wrapper"] + " " + (this.state.loaded ? css["running"] : '')}>
                        <div className={css.ripple}/>
                        <div className={css.ripple}/>
                        <div className={css.ripple}/>
                    </div>
                    <ReactSVG src={LogoBackground}/>
                </div>
                <div className={css.container}>
                    <div className={css["full-logo"] + " " + (this.state.loaded ? css["running"] : '')}>
                        <ReactSVG src={Logo}/>
                    </div>
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}

AnimatedLogo.propTypes = {};

export default AnimatedLogo;
