import React, {Component} from 'react';
import PropTypes from 'prop-types';
import css from "./CodeSuggester.module.scss"

class CodeSuggester extends Component {

    static defaultProps = {
        highlightedClass: ''
    };

    state = {
        codeIndex: 0,
        timer: null,
        highlightedLetterCount: 0,
        caretStanding: true
    };

    timer = null;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.start()
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        this.timer = null;
    }

    getActualCode() {
        const code = this.props.codes[this.state.codeIndex];
        if (!code.typedCount) {
            code.typedCount = code.code.length;
        }
        return code;
    }

    isOverLastHighlightedLetter() {
        let code = this.getActualCode();
        return code.typedCount <= this.state.highlightedLetterCount;
    }

    isTyping() {
        return !this.isOverLastHighlightedLetter();
    }

    isAtLastLetter() {
        const code = this.getActualCode();
        return code.code.length === this.state.highlightedLetterCount;
    }

    isJustShowingSuggestion() {
        const code = this.getActualCode();
        return this.state.highlightedLetterCount === code.typedCount;
    }

    stop() {
        clearTimeout(this.timer);
    }

    getNextTickDelay() {
        if (this.isAtLastLetter()) {
            return this.props.delayBetweenCodes;
        } else if (this.isJustShowingSuggestion()) {
            return this.props.postSuggestionDelay;
        } else {
            return Math.floor(Math.random() * ((this.props.avgTypingSpeed + 150) - this.props.avgTypingSpeed - 150) + 1) + this.props.avgTypingSpeed - 150
        }
    }

    type() {
        this.timer = setTimeout(() => {

                let actualCode = this.getActualCode();
                let updatedState = {};

                // Inc. highlighted counter
                if (this.state.highlightedLetterCount < actualCode.code.length) {
                    if (this.isOverLastHighlightedLetter()) {
                        updatedState.highlightedLetterCount = actualCode.code.length;
                    } else {
                        updatedState.highlightedLetterCount = this.state.highlightedLetterCount + 1;
                    }
                }

                // Pick next
                else {
                    updatedState.highlightedLetterCount = 1;
                    if (this.state.codeIndex < this.props.codes.length - 1) {
                        updatedState.codeIndex = this.state.codeIndex + 1;
                    } else {
                        updatedState.codeIndex = 0;
                    }
                }

                this.setState(updatedState);

                this.setState({caretStanding: this.isTyping() && !this.isAtLastLetter()});

                // Continue typing
                this.type();

            },
            this.getNextTickDelay()
        );
    }

    start() {

        this.setState({highlightedLetterCount: 0});
        this.stop();

        if (this.props.initialDelay) {
            setTimeout(() => this.type(), this.props.initialDelay)
        } else {
            this.type();
        }
    }

    render() {

        let actualCode = this.getActualCode();
        let letters = [];
        let caretClasses = [
            (this.props.caretClass || css["suggestion-caret"]),
        ];

        if (this.state.caretStanding) {
            caretClasses.push((this.props.caretStandingClass || css.standing))
        }

        // Highlighted letters
        letters.push(
            <span key={"highlighted"}
                  className={this.props.highlightedClass + " " + css.highlighted}>
                {actualCode.code.slice(0, this.state.highlightedLetterCount)}
                <span key={"caret"} className={caretClasses.join(" ")}/>
            </span>
        );

        // Continue to the end of code
        if (actualCode.suggestAtCount <= this.state.highlightedLetterCount && this.state.highlightedLetterCount < actualCode.code.length) {
            letters.push(
                <span key={"leftover"}
                      className={this.props.defaultClass}>{actualCode.code.slice(this.state.highlightedLetterCount, actualCode.code.length)}</span>
            )
        }

        return (letters);
    }
}

CodeSuggester.propTypes = {
    codes: PropTypes.array,
    avgTypingSpeed: PropTypes.number,
    defaultClass: PropTypes.string,
    highlightedClass: PropTypes.string,
    delayBetweenCodes: PropTypes.number,
    caretClass: PropTypes.string,
    caretStandingClass: PropTypes.string,
    postSuggestionDelay: PropTypes.number,
    initialDelay: PropTypes.number
};

export default CodeSuggester;
