import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt, faEnvelope, faPaperPlane, faUser, faUsers} from "@fortawesome/free-solid-svg-icons";
import css from "./ContactForm.module.scss";
import appCss from "../App.module.scss";
import {extractClassNames} from "../helpers/ExtractClassNames";
import SmartInput from "./SmartInput";
import {Email, MaxLength, MinLength, Required} from "./SmartInput/Validators";
import {sendContact, setContactState} from "../store/app/actions";
import {connect} from "react-redux";
import IconLogoLoader from "./IconLogoLoader";
import LoadGently from "../layout/LoadGently";

const MaxCompanyLength = 100;
const MaxNameLength = 50;
const MaxEmailLength = 100;
const MaxMessageLength = 1000;

const InputsFields = ['givenName', 'familyName', 'email', 'companyName', 'message'];

class ContactForm extends Component {

    state = {
        givenName: null,
        familyName: null,
        email: null,
        companyName: null,
        message: null,
        valid: false,
        loading: false
    };

    componentWillUnmount(): void {
        this.props.resetContactState();
        this.setState({loading: false});
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {
        this.setState({loading: nextProps.processState === null})
    }

    isValid(intermediateState) {
        return InputsFields.reduce((acc, fieldName) => (intermediateState[fieldName] !== null && acc), true);
    }

    inputControllers = (name: string) => {
        return {
            onPassing: (value) => {

                this.setState((prevState) => {

                    const preparedState = {...prevState};
                    preparedState[name] = value;
                    preparedState.valid = this.isValid(preparedState);

                    return preparedState;
                });

            },
            onFailing: () => {

                this.setState((prevState) => {

                    const preparedState = {...prevState};
                    preparedState[name] = null;
                    preparedState.valid = this.isValid(preparedState);

                    return preparedState;
                });

            }
        }
    };

    submit = (e) => {

        e.preventDefault();

        // Skip in case of forced submission.
        if (!this.isValid(this.state)) {
            return false;
        }

        this.props.sendContact(
            this.state.familyName,
            this.state.givenName,
            this.state.email,
            this.state.companyName,
            this.state.message
        );

        this.setState({loading: true});

        return false;
    };

    render() {

        let result = null;

        if (this.props.processState !== null) {
            if (this.props.processState === true) {
                result = (
                    <div className={css['result'] + ' ' + appCss['text-dark']}>
                        <h5>Köszönjük!</h5>
                        <p>Hamarosan jelentkezünk!</p>
                    </div>
                );
            } else {
                result = (
                    <div className={css['result'] + ' ' + appCss['text-danger']}>
                        <h5>Elnézést!</h5>
                        <p>Ismeretlen hiba lépett fel. Kérjük próbálja újra később!</p>
                    </div>
                );
            }
        }

        return (
            <LoadGently>
                <form className={css['contact-form']} onSubmit={this.submit} id="TrackableContactForm">

                    {
                        this.state.loading
                            ? <div className={css['loader']}>
                                <IconLogoLoader inverse className={extractClassNames('scale-center in', appCss)}>
                                    <p className={appCss['mt-2']}>Kérjük várjon...</p>
                                </IconLogoLoader>
                            </div>
                            : null
                    }

                    {
                        result
                    }

                    <SmartInput {...this.inputControllers('familyName')}
                                inputType={"text"}
                                id={"familyName"}
                                counter={MaxNameLength}
                                validators={[new Required(), new MaxLength(MaxNameLength)]}
                                label={(
                                    <label htmlFor={"familyName"}><FontAwesomeIcon icon={faUser}/> Vezetéknév*</label>)}
                                immediate={true}/>
                    <SmartInput {...this.inputControllers('givenName')}
                                inputType={"text"}
                                id={"givenName"}
                                counter={MaxNameLength}
                                validators={[new Required(), new MaxLength(MaxNameLength)]}
                                label={(
                                    <label htmlFor={"givenName"}><FontAwesomeIcon icon={faUser}/> Keresztnév*</label>)}
                                immediate={true}/>
                    <SmartInput {...this.inputControllers('email')}
                                limitErrors={1}
                                inputType={"text"}
                                id={"email"}
                                counter={MaxEmailLength}
                                validators={[new Required(), new MaxLength(MaxEmailLength), new Email()]}
                                label={(<label htmlFor={"email"}><FontAwesomeIcon icon={faAt}/> email*</label>)}
                                immediate={true}/>
                    <SmartInput {...this.inputControllers('companyName')}
                                inputType={"text"}
                                id={"company"}
                                counter={MaxCompanyLength}
                                validators={[new Required(), new MaxLength(MaxCompanyLength)]}
                                label={(
                                    <label htmlFor={"company"}><FontAwesomeIcon icon={faUsers}/> cég /
                                        szervezet*</label>)}
                                immediate={true}/>
                    <SmartInput {...this.inputControllers('message')}
                                component={"textarea"}
                                id={"company"}
                                counter={MaxMessageLength}
                                validators={[new MinLength(20), new MaxLength(MaxMessageLength)]}
                                label={(
                                    <label htmlFor={"company"}><FontAwesomeIcon icon={faEnvelope}/> üzenet*</label>)}
                                immediate={true}/>


                    <div className={appCss['button-perspective']}>
                        <button {...(this.state.valid ? {} : {disabled: true})}
                                className={extractClassNames('btn btn-primary btn-block text-uppercase', appCss)}
                                id="sendButton">
                            <span>küldés</span>
                            <FontAwesomeIcon icon={faPaperPlane}/>
                        </button>
                    </div>
                </form>
            </LoadGently>
        );
    }
}

ContactForm.propTypes = {};

const mapStateToProps = (state) => ({
    processState: state.app.sendContactState
});

const mapDispatchToProps = (dispatch) => ({
    resetContactState: () => dispatch(setContactState(null)),
    sendContact: (givenName, familyName, email, organizationName, message) => dispatch(sendContact({
        firstName: givenName,
        lastName: familyName,
        organizationName,
        email,
        message
    }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactForm);
