import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Validator from "./Validator";

const Dummy = () => {
};

type Config = {
    className: string,
    passingClassName: string,
    failingClassName: string,
    template(params: TemplateParams): Symbol,
    errorTemplate(error: ErrorTemplateParam): Symbol,
    counterTemplate(count: CounterTemplateParam, max: Number): Symbol,
}

type TemplateParams = {
    input: Symbol,
    label: Symbol,
    focused: Boolean,
    counter: Symbol | null,
    errors: Symbol,
    isValidating: Boolean,
    isCounterPassing: Boolean,
    isPassing: Boolean,
    isTouched: Boolean
}
type ErrorTemplateParam = String
type CounterTemplateParam = Number | null

class SmartInput extends Component {

    static config = {
        className: '',
        passingClassName: '',
        failingClassName: '',
        template: ({input, label, counter, errors, isValidating}: TemplateParams) => (<div>
            <div>
                {label}
                {input}
            </div>
            <div>
                {counter}
            </div>
            <div>
                {errors}
            </div>
        </div>),
        errorTemplate: (error: ErrorTemplateParam, className: String) => (<small className={className}>{error}</small>),
        counterTemplate: (count: CounterTemplateParam, max: Number, className: String) => (
            <small className={className}>{count} / {max}</small>)
    };

    static defaultProps = {
        inputRef: Dummy,
        className: '',
        passingClassName: '',
        failingClassName: '',
        component: 'input',
        counter: null,
        validators: [],
        beforeValidate: Dummy,
        afterValidate: Dummy,
        onPassing: Dummy,
        onFailing: Dummy,
        label: Dummy,
        validateOn: ["keyUp", "blur"],
        validateOnBlur: true,
        validateOnMount: false,
        limitErrors: null,
        showCounter: true,
        debounceTime: 1000,
        immediate: false,
        autofocus: false
    };

    state = {
        touched: false,
        focused: false,
        valid: false,
        valueLength: 0,
        errors: [],
        validating: false
    };

    input = null;
    debounceTimer = null;

    componentDidMount(): void {

        if (this.props.validateOnMount === true) {
            this.validate().finally();
        }

        this.setState({valueLength: this.input.value.length});
    }

    componentWillUnmount(): void {
        this.destroyDebounceTimer();
    }

    /**
     * Get normalized list of applicable classes of input.
     * @returns {string}
     */
    get className() {
        return (
            (this.props.className || SmartInput.config.className) + " " +
            (
                this.isFailing()
                    ? this.props.failingClassName || SmartInput.config.failingClassName
                    : this.props.passingClassName || SmartInput.config.passingClassName
            )
        ).trim();
    }

    /**
     * Destroys debounce timer.
     */
    destroyDebounceTimer = () => {
        clearTimeout(this.debounceTimer);
        this.debounceTimer = null;
    };

    /**
     * Pass validation parameters to validator.
     * Restricts debounce time.
     * @returns {Promise<void>}
     */
    validate = async () => {

        if (this.props.immediate === false && this.debounceTimer) {
            this.destroyDebounceTimer();
        }

        if (this.props.immediate === false) {

            // Prevent debounce
            this.debounceTimer = setTimeout(async () => {
                this.extractErrors().finally();
            }, this.props.debounceTime);
        } else {
            this.extractErrors().finally();
        }
    };

    extractErrors = async () => {

        // Capture value
        const value = this.input.value;

        this.setState({validating: true});
        // Invoke before listener.
        this.props.beforeValidate();

        const errors = await Validator.validate(this.props.validators, value, this.props.limitErrors);

        this.setState({errors, validating: false});

        // Invoke after listener.
        this.props.afterValidate(errors);
        errors.length ? this.props.onFailing(value) : this.props.onPassing(value);
    };

    /**
     * Focus event happened on the input.
     * @param e
     */
    focus = (e) => {
        this.setState({focused: true})
    };

    /**
     * Character input happened
     * @param e
     */
    keyUp = (e) => {

        const newState = {};

        // Validate
        if (this.props.validateOn.includes("keyUp")) {
            this.validate().finally();
        }

        // Determine character amount
        if (this.props.counter != null) {
            newState.valueLength = e.target.value.length;
        }

        this.setState(newState);
    };

    keyDown = (e) => {

        const newState = {};

        // Validate
        if (this.props.validateOn.includes("keyDown")) {
            this.validate().finally();
        }

        this.setState(newState);
    };

    blur = (e) => {

        const newState = {
            focused: false,
            touched: true
        };

        // Validate
        if (this.props.validateOn.includes("blur")) {
            this.validate().finally();
        }

        this.setState(newState);
    };

    change = (e) => {

        if (this.props.onChange) {
            this.props.onChange(e);
        }

        this.setState({touched: true});
    };

    template = (name) => {
        return this.props[name] || SmartInput.config[name];
    };

    isCounterFailing = () => {
        return (!!this.props.counter && this.state.valueLength > this.props.counter);
    };

    isFailing = () => {
        return !!this.state.errors.length;
    };

    /**
     *
     * @returns {*}
     */
    render() {

        const isCounterFailing = this.isCounterFailing();
        const isPassing = !this.isFailing();

        // Rendering input
        const input = React.createElement(this.props.component, {
            className: this.className,
            id: this.props.id,
            ref: (e => {
                this.props.inputRef(e);
                this.input = e;
            }),
            defaultValue: this.props.value,
            // Add event handlers
            onChange: this.change,
            onFocus: this.focus,
            onKeyUp: this.keyUp,
            onKeyDown: this.keyDown,
            onBlur: this.blur,
            autoFocus: !!this.props.autofocus
        });

        // Rendering counter
        const counter = this.props.counter
            ? this.template('counterTemplate')(this.state.valueLength, this.props.counter,
                isCounterFailing
                    ? this.props.counterFailingClassName
                    : this.props.counterPassingClassName
            )
            : null;

        // Rendering errors
        const errors = this.state.errors.map((error, i) => {
            return React.cloneElement(this.template('errorTemplate')(error, this.props.errorClassName || ''), {key: i});
        });

        // Rendering label
        const label = typeof this.props.label === "function"
            ? this.props.label()
            : typeof this.props.label === "string"
                ? <label htmlFor={this.props.id}>{this.props.label}</label>
                : this.props.label;

        return (
            this.template('template')({
                input,
                label,
                counter,
                errors,
                isPassing,
                isCounterPassing: !isCounterFailing,
                focused: this.state.focused,
                isTouched: this.state.touched
            })
        );
    }

    static configure(config: Config) {
        SmartInput.config = {
            ...SmartInput.config,
            ...config
        };
    }

}

SmartInput.propTypes = {
    inputRef: PropTypes.func,
    className: PropTypes.string,
    passingClassName: PropTypes.string,
    failingClassName: PropTypes.string,
    component: PropTypes.oneOf(["input", "textarea"]),
    validateOn: PropTypes.arrayOf(PropTypes.string),
    validateOnBlur: PropTypes.bool,
    validateOnMount: PropTypes.bool,
    // Relevant on case of component defined as an input
    inputType: PropTypes.string,
    // ID applied on the input
    id: PropTypes.string,

    // Initially specify value of input
    value: PropTypes.any,

    // Character counter
    counter: PropTypes.number,
    counterFailingClassName: PropTypes.string,
    counterPassingClassName: PropTypes.string,

    // Optionally a bunch of validator rules against the input data.
    validators: PropTypes.any,

    // Input is being under the validation process.
    // Possibly transform the inner data via this method.
    // The returned value will be used under the validation process.
    beforeValidate: PropTypes.func,
    // Used to hook logic right after validation process finished.
    // Can be useful in case of using async validators.
    // This might determine a loading state finish.
    afterValidate: PropTypes.func,

    // Input data passing against the specified rules.
    onPassing: PropTypes.func,

    // Input data failing against the specified rules.
    onFailing: PropTypes.func,

    // Custom inline error component template
    errorTemplate: PropTypes.func,

    // Custom inline counter component template
    counterTemplate: PropTypes.func,

    // Custom outer template
    template: PropTypes.func,
    label: PropTypes.any,

    // Limit the error visibility count.
    limitErrors: PropTypes.number,
    // Validation debounce time.
    debounceTime: PropTypes.number,

    // Indicates no debounce time will be used for
    // event throttling.
    immediate: PropTypes.bool,
    errorClassName: PropTypes.string,
    onChange: PropTypes.func,
    autofocus: PropTypes.bool
};

export default SmartInput;
