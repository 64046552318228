import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ScrollThresholdTrigger from "./ScrollThresholdTrigger";

class ToggleAnimation extends Component {


    state = {
        in: false,
        out: false,
        previousPosition: null
    };

    in = () => {
        this.setState({in: true, out: false});
    };

    out = () => {
        this.setState({in: false, out: true});
    };

    animationClassName = () => {
        return this.props.animationClassName + " " + (this.state.in ? (this.props.inClassName || 'in') : "") + (this.state.out ? (this.props.outClassName || 'out') : "");
    };

    render() {
        return (
            <ScrollThresholdTrigger debug={this.props.debug || false} onIn={this.in} onOut={this.out} triggerPercent={this.props.togglePercent}>
                <div
                    id={this.props.id}
                    style={this.props.style}
                    className={(this.props.className ? this.props.className : "") + " " + this.animationClassName()}>
                    {this.props.children}
                </div>
            </ScrollThresholdTrigger>
        );
    }
}

ToggleAnimation.propTypes = {
    togglePercent: PropTypes.number,
    animationClassName: PropTypes.string,
    inClassName: PropTypes.string,
    outClassName: PropTypes.string,
    style: PropTypes.any,
    className: PropTypes.any,
    id: PropTypes.string,
    debug: PropTypes.bool
};

export default ToggleAnimation;
