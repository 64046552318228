import React, {Component} from 'react';
import LoadGently from "../../layout/LoadGently";
import ScrollToTopOnMount from "../../helpers/ScrollToTopOnMount";
import Heading from "../../layout/Heading";
import {faChevronRight, faGlobe, faMobileAlt, faProjectDiagram, faTabletAlt} from "@fortawesome/free-solid-svg-icons";
import {extractClassNames} from "../../helpers/ExtractClassNames";
import appCss from "../../App.module.scss";
import css from "./Projects.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";
import {DynamicLocation} from "../../helpers/DynamicLocation";
import ImageLoader from "../../shared/ImageLoader";
import IconLogoLoader from "../../shared/IconLogoLoader";


const ProjectsList = [
    {
        themeName: 'digikip',
        title: 'Digi KIP',
        uri: 'digi-kip',
        subTitle: 'Digitális Komplex Instrukciós Program - 2018',
        thumbnail: require('./assets/digi-kip.png'),
        icons: [faGlobe, faTabletAlt, faMobileAlt],
        intro: (
            <React.Fragment>
                A Digi KIP projekt egy már alkalmazásban lévő analóg oktatási módszer digitalizálása. A KIP (Komplex
                Instrukciós Program) lényegében különböző kompetenciák szerint 4-6 fős heterogén csoportokba rendezi a
                diákokat. A módszer szerint specifikusan kell a diákokra meghatározni mind a csoport és egyéni
                feladatokat.
            </React.Fragment>
        )
    },
    {
        themeName: 'project-manager',
        title: 'Projekt Menedzser',
        uri: 'projekt-menedzser',
        subTitle: 'Projekt és munkaidő kezelő - 2017',
        thumbnail: require('./assets/project-manager.png'),
        icons: [faGlobe],
        intro: (
            <React.Fragment>
                Webalapú időelszámolási és projektkövetési rendszer, mely rögzíti az egyes ügyfélre fordított személyi
                és egyéb költségeket, ráfordításokat, valamint az ügyféltől érkező bevételeket. Ezek alapján
                statisztikát készít az ügyfél jövedelmezőségéről, a dolgozó időbeli leterheltségéről, és a dolgozó
                munkabérének fedezeti arányáról.
            </React.Fragment>
        )
    },
    {
        themeName: 'press-watcher',
        title: 'Média Figyelés',
        uri: 'media-figyeles',
        subTitle: 'Média Figyelés - 2017',
        thumbnail: require('./assets/press-watcher.png'),
        icons: [faGlobe],
        intro: (
            <React.Fragment>
                Olyan kalkulációs szoftver, amely webes felületen érhető el és megoldja a mindennapok
                táblázatkezelési nehézségeit, ezzel megkönnyítve és egyszerűsítve az ügyfélkommunikációs folyamatokat
                is.
            </React.Fragment>
        )
    }
];

const parallaxStyle = {
    height: '40vw',
    minHeight: '400px'
};

class Projects extends Component {
    render() {
        return (
            <React.Fragment>

                <Helmet>
                    <title>Projektek | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Projektek,Megvalósítás,Lezárt,Megrendelő,Valós,Igény,Megoldás,Szoftver,Mobil"/>
                    <meta name="description"
                          content="Tekintse meg eddig sikeresen lezárt nagy szabású, izgalmas projektjeinket. Kihívással teli megvalósítások, ahol az ügyfél elképzelésit megvalósítottuk."/>
                    <meta property="og:title" content="Projektek | SzuniSOFT"/>
                    <meta property="og:description"
                          content="Tekintse meg eddig sikeresen lezárt nagy szabású, izgalmas projektjeinket. Kihívással teli megvalósítások, ahol az ügyfél elképzelésit megvalósítottuk."/>
                    <meta property="og:image"
                          content={DynamicLocation.build(require('../../assets/logo/Powered by Logo-100.jpg?size=600').src)}/>
                </Helmet>

                <LoadGently className={extractClassNames('d-flex flex-grow-1 flex-column fade in', appCss)}>
                    <ScrollToTopOnMount/>
                    <Heading heading="Projektek" icon={faProjectDiagram}/>
                    <div id={css['projects']}
                         className={extractClassNames('d-flex flex-grow-1 flex-column container', appCss)}>
                        {
                            ProjectsList.map(p => (
                                <div id={css[p.themeName + '-project-theme']} key={p.themeName} className={css.project}>
                                    <div className={css['image']}>
                                        <ImageLoader src={p.thumbnail.src}
                                                     srcSet={p.thumbnail.srcSet}
                                                     loaded={<img className="fade in"
                                                                  src={p.thumbnail.src}
                                                                  srcSet={p.thumbnail.srcSet}
                                                                  alt={p.title}/>}
                                                     loading={
                                                         <IconLogoLoader inverse/>
                                                     }
                                        />
                                    </div>
                                    <div className={css['content']}>
                                        <h1>{p.title}</h1>
                                        <h4>{p.subTitle}</h4>
                                        <hr/>
                                        <p>{p.intro}</p>

                                        <div className={extractClassNames('d-flex flex-column', appCss)}>
                                            <div className={css['icons']}>
                                                {p.icons.map((icon, i) => <div className={css['icon']} key={i}>
                                                    <div className={css['icon-wrapper']}>
                                                        <FontAwesomeIcon icon={icon}/>
                                                    </div>
                                                </div>)}
                                            </div>

                                            <div
                                                className={extractClassNames('text-center button-perspective', appCss) + ' ' + css['btn-wrapper']}>
                                                <Link to={`/projektek/${p.uri}`}
                                                      className={extractClassNames('btn p-3', appCss) + ' ' + extractClassNames('btn', css)}>
                                                    <span>bővebben</span>
                                                    <FontAwesomeIcon icon={faChevronRight}/>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </LoadGently>
            </React.Fragment>
        );
    }
}

Projects.propTypes = {};

//export default {Component: Projects};
export default Projects;
