import React, {Component} from 'react';
import PropTypes from 'prop-types';
import css from "./Loader.module.scss";
import ReactSVG from "react-svg";
import LogoBackground from "../assets/figures/logo-background.svg";
import {extractClassNames} from "../helpers/ExtractClassNames";
import IconLogoLoader from "./IconLogoLoader";

class Loader extends Component {

    render() {
        return (
            <div className={this.props.className || null}>
                <div className={extractClassNames('ripple-loader', css)}>
                    <IconLogoLoader className={extractClassNames('logo-loader', css)} inverse/>
                    {/*<div className={extractClassNames('text', css)}>
                        <span>betöltés</span>
                        <span className={extractClassNames('dots', css)}>
                            <span className={extractClassNames('dot', css)}>.</span>
                            <span className={extractClassNames('dot', css)}>.</span>
                            <span className={extractClassNames('dot', css)}>.</span>
                        </span>
                    </div>*/}
                    <div className={extractClassNames('ripple-wrapper', css)}>
                        <div className={extractClassNames('ripple', css)}/>
                    </div>
                    <ReactSVG svgClassName={extractClassNames('logo-background', css)} src={LogoBackground}/>
                </div>
            </div>
        );
    }
}

Loader.propTypes = {
    className: PropTypes.string
};

export default Loader;
