import type {NewsActionT, NewsT} from "./types";
import {NewsActionTypes} from "./actions";

export const initialState: NewsT = Object.freeze({
    news: {
        articles: null,
        links: null,
        meta: null
    },
    article: null,
    newsCancelSource: null,
    articleCancelSources: []
});

export default (state: NewsT = initialState, action: NewsActionT): NewsT => {

    const {type, payload} = action;

    switch (type) {
        case NewsActionTypes.SET_META: {
            return {
                ...state,
                news: {
                    ...state.news,
                    meta: payload
                }
            }
        }
        case NewsActionTypes.SET_LINKS: {
            return {
                ...state,
                news: {
                    ...state.news,
                    links: payload
                }
            }
        }
        case NewsActionTypes.SET_ARTICLES: {
            return {
                ...state,
                news: {
                    ...state.news,
                    articles: payload
                }
            }
        }
        case NewsActionTypes.SET_ARTICLE: {
            return {
                ...state,
                article: payload
            };
        }
        case NewsActionTypes.SET_NEWS_CANCELLATION_SOURCE: {
            return {
                ...state,
                newsCancelSource: payload
            };
        }
        case NewsActionTypes.CANCEL_NEWS_SOURCE: {

            if (state.newsCancelSource !== null && state.newsCancelSource.cancel) {
                state.newsCancelSource.cancel('User cancellation');
            }

            return {
                ...state,
                newsCancelSource: null
            };
        }
        case NewsActionTypes.ADD_ARTICLE_CANCELLATION_SOURCE: {
            return {
                ...state,
                articleCancelSources: [
                    ...state.articleCancelSources,
                    payload
                ]
            }
        }
        case NewsActionTypes.CANCEL_ARTICLE_SOURCE: {

            const cancellation = state.articleCancelSources.find(e => e.id === payload);

            if (!!cancellation && cancellation.source.cancel) {
                cancellation.source.cancel('User cancellation');

                return {
                    ...state,
                    articleCancelSources: state.articleCancelSources.filter(e => e.id !== payload.id)
                }
            }
            return state;
        }
        default:
            return state;
    }

}
