import type {LocaleT, SendContactParams} from './types';
import {delay} from "q";
import axios from 'axios';

export const AppActionTypes = {
    SET_LOCALE: 'app/set-locale',
    SET_CONTACT_STATE: 'app/send-contact'
};

export const setLocale = (locale: LocaleT) => ({
    type: AppActionTypes.SET_LOCALE,
    payload: locale,
});

export const sendContact = (params: SendContactParams) => async (dispatch) => {

    dispatch(setContactState(null));

    const generalDelay = delay(2500);
    const request = axios.post(`${API_HOST}/api/contact`, params);

    Promise.all([request, generalDelay])
        .then(x => {
            dispatch(setContactState(true));
        })
        .catch(e => dispatch(setContactState(false)))
    ;

};

export const setContactState = (to: boolean | null) => ({
    type: AppActionTypes.SET_CONTACT_STATE,
    payload: to
});
