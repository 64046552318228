import React, {Component} from 'react';
import { DynamicLocation } from "../../../helpers/DynamicLocation";
import ScrollToTopOnMount from "../../../helpers/ScrollToTopOnMount";
import Heading from "../../../layout/Heading";
import {faNetworkWired} from "@fortawesome/free-solid-svg-icons";
import appCss from "../../../App.module.scss";
import serviceCss from "../Services.module.scss";
import {extractClassNames} from "../../../helpers/ExtractClassNames";
import {Helmet} from "react-helmet";
import LoadGently from "../../../layout/LoadGently";
import LoaderParallax from "../../../layout/LoaderParallax";
import ToggleAnimation from "../../../shared/ToggleAnimation";
import ContactForm from "../../../shared/ContactForm";

const parallaxStyle = {
    height: '30vw',
    minHeight: '400px'
};
const NetworkOgImage = require('./assets/section-1.jpg');

class Network extends Component {
    render() {
        return (

            <React.Fragment>

                <Helmet>
                    <title>Hálózatfejlesztés | Szoftverfejlesztés | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Hálózat,Fejlesztés,Biztonság,Karbantartás,Szegmentálás,Védelem,Audit,Védvonal,VLAN,Kibertámadás"/>
                    <meta name="description"
                          content="Hálózatfejlesztés kiemelten fontos napjainkban, itt találhatóak az internettel kapcsolatba lévő eszközök, amelyek nagy terhelés és veszélynek vannak kitéve."/>
                    <meta property="og:title" content="Hálózatfejlesztés | Szoftverfejlesztés | SzuniSOFT"/>
                    <meta property="og:description" content="Hálózatfejlesztés kiemelten fontos napjainkban, itt találhatóak az internettel kapcsolatba lévő eszközök, amelyek nagy terhelés és veszélynek vannak kitéve."/>
                    <meta property="og:image" content={DynamicLocation.build(NetworkOgImage.src)}/>
                </Helmet>

                <LoadGently className={appCss['flex-grow-1']}>
                    <ScrollToTopOnMount/>
                    <Heading heading="Hálózatfejlesztés"
                             icon={faNetworkWired}
                             breadCrumb={[
                                 {text: 'Szolgáltatások', to: '/szolgaltatasok'},
                                 'Hálózatfejlesztés'
                             ]}
                    />
                    <div
                        className={extractClassNames('d-flex flex-column flex-grow-1', appCss) + ' ' + serviceCss['service']}>

                        <LoaderParallax {...require("./assets/section-1.jpg")}
                                        style={parallaxStyle}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                                        parallaxBgImageStyle={{marginTop: '-20%'}}
                        />

                        <div className={extractClassNames('container py-5', appCss)}>
                            <h2>Házon belüli eszközök</h2>
                            <p>Ahol internet elérés van ott megtalálhatóak a csomóponti hálózati eszközök. Ezeket az eszközöket mind
                                a sebesség, mind pedig biztonsági szempontból érdemes időnként felülvizsgálni és az auditnak
                                megfelelő fejlesztési lépéseket időben elkezdeni.</p>
                        </div>


                        <LoaderParallax {...require("./assets/section-2.jpg?quality=90")}
                                        style={parallaxStyle}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                        />

                        <section className={serviceCss['primary']}>
                            <div className={extractClassNames('container py-5', appCss)}>
                                <h2>Hálózati audit</h2>
                                <p className={serviceCss['subheading']}>Kerüljön képbe!</p>
                                <p>Egy audit egyenlő egy teljes mértékű felméréssel. Kollégáink részleteibe menő kimutatással
                                    prezentálják Önnek a hálózata jelenlegi állapotát és a megoldási javaslatokat.</p>
                            </div>
                        </section>

                        <LoaderParallax {...require("./assets/section-3.jpg?quality=90")}
                                        style={parallaxStyle}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                        />

                        <section>
                            <div className={extractClassNames('container py-5', appCss)}>
                                <h2>Hálózati eszközök szeparálása</h2>
                                <p className={serviceCss['subheading']}>Többszintű védelmi vonalak felállítása!</p>
                                <p>Nagyon fontosnak tartjuk, hogy amikor egy hálózat kellően nagyra duzzad akkor megfelelő
                                    struktúrárával alakuljon ki a hálózat nem pedig az "oda dugom ahol van hely" alapon.
                                    Mert ha nem így történik és akár egy apró porszem kerül a gépezetbe, ami nem oda illik
                                    akkor könnyedén mindent is a feje tetejére állíthat. Míg, ha megfelelő körültekintéssel
                                    rendszerezetten szegmentált hálózatnak jó esélyel egy időben csak egy apró szegmensét
                                    lehet tönkretenni. Ehhez természetesen sokszor igény a megfelelő technológiai szinttel
                                    rendelkező eszközök is, de ezek az eszközök már könnyedén oldanak meg olyan szituációkat
                                    is, hogy amennyiben alattuk lévő hálózati szegmensben történő esemény hatására védve a
                                    hálózatot megszünteti a hálózat más komponenseivel történő kommunikációt védve így a
                                    teljes rendszert.</p>
                            </div>
                        </section>

                        <LoaderParallax {...require("./assets/section-4.jpg")}
                                        style={{...parallaxStyle, height: '40vw'}}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                                        parallaxBgImageStyle={{marginTop: '10%'}}
                        />

                        <section className={serviceCss['secondary']}>
                            <div className={extractClassNames('container py-5', appCss)}>
                                <h2>Belső ellenség</h2>
                                <p className={serviceCss['subheading']}>Ahonnan a legkevésbé várnánk!</p>
                                <p>Közintézményi hálózatoknál találkoztunk olyan esettel amikor a teljes hálózatot kellet
                                    megvédeni 1-2 személytől, akik akár fizikai akár szoftveres támadást indítottak a teljes
                                    ökoszisztéma ellen. Ezen személyek kiszűrése és megtalálása akár hosszabb folyamat is
                                    lehet, de mindenképpen nagy segítséget adnak a megfelelően beállított és kezelt napló
                                    rendszerek.</p>
                            </div>
                        </section>

                        <LoaderParallax {...require("./assets/section-5.jpg")}
                                        style={parallaxStyle}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                        />

                        <section className={serviceCss['inverse']}>
                            <div className={extractClassNames('container py-5', appCss)}>
                                <h2>Nagyvállalati eszközök SOHO környezetben</h2>
                                <p className={serviceCss['subheading']}>Optimalizálás, költségcsökkentés, hatékonyság!</p>
                                <p>Sokan tévedésben élnek azt illetően, hogy egy hálózati eszköz csak akkor nyújthat
                                    megfelelő teljesítményt ha kellően nagy, nehéz és márkás. Manapság a fizikai képesség
                                    egy nagy szűk keresztmetszete a hálózati eszközöknek. Mivel a munka nehezét a szoftver
                                    végzi. Ennek megfelelően egy jól beállított alacsonyabb költségű eszköz tudja ugyan azt
                                    nyújtani, mint a nagyok. Itt pedig egy pillanatra meg kell állni és végig gondolni. Mi
                                    érheti meg jobban nagyon drága eszközök vásárlása vagy megbízni egy olyan céget, aki
                                    megfelelő szaktudással az alacsonyabb költségvetésű eszközökből is ki tudja hozni a
                                    színvonalat így a magas az eszköz beszerzési díjjak csökkenek.</p>

                                <p>Ezen példák töredékei, hogy mindennapokban milyen esetekkel találkozunk de egy folyamatosan
                                    fejlődő cégként szeretjük a kihívásokat. Hálózattal kapcsolatos kérdésekkel is forduljon
                                    felénk bizalommal.
                                </p>

                            </div>
                        </section>

                        <section>
                            <div className={extractClassNames('pt-5 py-md-5', appCss)}>
                                <div className={appCss['container']}>
                                    <div>
                                        <h4 className={extractClassNames('my-5 text-center', appCss)}>Vegye fel velünk a kapcsolatot
                                            bátran!</h4>
                                    </div>
                                    <ToggleAnimation
                                        animationClassName={extractClassNames('slide-bottom row', appCss)}
                                        togglePercent={10}>
                                        <div
                                            className={extractClassNames('col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3', appCss)}>
                                            <div id="contactCard" className={extractClassNames('bg-white', appCss)}>
                                                <ContactForm/>
                                            </div>
                                        </div>
                                    </ToggleAnimation>
                                </div>
                            </div>
                        </section>

                    </div>

                </LoadGently>
            </React.Fragment>
        );
    }
}

Network.propTypes = {};

export default Network;
