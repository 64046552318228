// @flow
import * as React from 'react';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import {withNamespaces} from 'react-i18next';
import {setLocale} from './store/app/actions';
/*import { ReactComponent as ReactLogo } from './assets/react.svg';
import Features from './components/Features';*/
import appCss from './App.module.scss';
import SmartInput from "./shared/SmartInput";
import {extractClassNames} from "./helpers/ExtractClassNames";
import Header from "./layout/Header";
import {renderRoutes} from "react-router-config";
import {Routes} from "./routes";
import Footer from "./layout/Footer";
import {StickyContainer} from 'react-sticky';
import Favicon from './assets/favicon.ico';
import Moment from 'react-moment';
import 'moment/locale/hu';
import {withRouter} from "react-router";
import smoothscroll from 'smoothscroll-polyfill';
import {DynamicLocation} from "./helpers/DynamicLocation";
import TechnologyViewer from "./shared/Technologies/TechnologyViewer";

const DefaultOgImage = require('./assets/logo/Full Logo-100.jpg?size=600');

if (typeof window !== "undefined") {
    smoothscroll.polyfill();
}

Moment.globalLocale = 'hu';

/* Configure Smart Input template */
SmartInput.configure({
    className: appCss['form-control'],
    template: (params) => {

        return (
            <div className={extractClassNames(
                (params.isTouched ? (params.isPassing ? 'success' : 'danger') : ''),
                'form-group position-relative',
                appCss
            )}>
                {params.input}
                {params.label}
                <div className={
                    extractClassNames(
                        (params.isCounterPassing ? 'text-success' : 'text-danger'),
                        (params.focused ? 'in' : 'out'),
                        'p-1 to-right position-absolute slide-left',
                        appCss
                    )
                }>
                    {params.counter}
                </div>
                <div className={extractClassNames('mt-2 text-danger slide-right in', appCss)}>
                    {params.errors}
                </div>
            </div>
        );
    },
    errorTemplate: error => (
        <small>{error}</small>
    ),
    counterTemplate: count => (
        <small>{count}</small>
    )
});

type PropsT = {
    setLocale: (string) => {},
    t: (string) => string,
};

class App extends React.PureComponent<PropsT> {

    setLanguage = (e: SyntheticInputEvent<HTMLButtonElement>) => {
        this.props.setLocale(e.target.value);
    };

    render() {
        const {t} = this.props;

        return (
            <React.Fragment>
                <Helmet defaultTitle="SzuniSOFT">
                    <link rel="shortcut icon" href={Favicon}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:image" content={DynamicLocation.build(DefaultOgImage.src)}/>
                </Helmet>
                <Header/>
                <StickyContainer className={extractClassNames('d-flex flex-grow-1 flex-column', appCss)}>
                    <TechnologyViewer>
                        <main>
                            {renderRoutes(Routes)}
                        </main>
                    </TechnologyViewer>
                </StickyContainer>
                <Footer/>
            </React.Fragment>
        );
    }
}

const mapDispatchToProps = {
    setLocale,
};

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(withNamespaces()(App))
);
