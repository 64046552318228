import React, {Component} from 'react';
import { DynamicLocation } from "../../../helpers/DynamicLocation";
import ScrollToTopOnMount from "../../../helpers/ScrollToTopOnMount";
import Heading from "../../../layout/Heading";
import {faMicrosoft} from "@fortawesome/free-brands-svg-icons";
import css from "./Microsoft.module.scss";
import appCss from "../../../App.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {extractClassNames} from "../../../helpers/ExtractClassNames";
import {Helmet} from "react-helmet";
import LoadGently from "../../../layout/LoadGently";
import serviceCss from "../Services.module.scss";
import LoaderParallax from "../../../layout/LoaderParallax";
import {Link} from "react-router-dom";
import ToggleAnimation from "../../../shared/ToggleAnimation";
import ContactForm from "../../../shared/ContactForm";

const parallaxStyle = {
    height: '40vw',
    minHeight: '200px',
    maxHeight: '400px'
};

const Section1Image = require('./assets/section-1.png');
const Section3Image = require('./assets/section-3.png');
const Section4Image = require('./assets/project-365.png');
const MicrosoftOgImage = require('../../../assets/parallax/mslogo.jpg');

class Microsoft extends Component {
    render() {
        return (
            <React.Fragment>

                <Helmet>
                    <title>Microsoft terméktámogatás | Szolgáltatás | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Microsoft,Termék,Office,Azure,Irodai,Szoftvercsomag,Megoldások,Használhatóság"/>
                    <meta name="description"
                          content="Több éves Microsoft partneri tapasztalattal ajánljuk a termékeiket a mindennapi és a szélsőséges szükségletekhez."/>
                    <meta property="og:title" content="Microsoft terméktámogatás | Szolgáltatás | SzuniSOFT"/>
                    <meta property="og:description"
                          content="Több éves Microsoft partneri tapasztalattal ajánljuk a termékeiket a mindennapi és a szélsőséges szükségletekhez."/>
                    <meta property="og:image" content={DynamicLocation.build(MicrosoftOgImage.src)}/>
                </Helmet>

                <LoadGently className={extractClassNames('d-flex flex-column flex-grow-1', appCss)}>
                    <ScrollToTopOnMount/>
                    <Heading heading="Microsoft terméktámogatás"
                             icon={faMicrosoft}
                             breadCrumb={[
                                 {text: 'Szolgáltatások', to: '/szolgaltatasok'},
                                 'Microsoft terméktámogatás'
                             ]}
                    />
                    <div className={css['software'] + ' ' + serviceCss['service']}>

                        <LoaderParallax src={Section1Image.src}
                                        srcSet={Section1Image.srcSet}
                                        parallaxStrength={500}
                                        loaderInverse
                                        parallaxBgImageStyle={{marginTop: '-2%'}}
                                        parallaxBgClassName={'fade in'}
                        >

                            <section className={extractClassNames('h-100 py-5', appCss)}
                                     style={{background: 'rgba(255,255,255,0.9)'}}>
                                <div className={appCss['container']}>
                                    <h2>Azure</h2>
                                    <p>A jelenleg a világ legnagyobb, legkomplexebb és folyamatosan fejlődő, növekvő
                                        publikus
                                        szolgáltatásokat nyújtó szerverrendszerét és szoftver csomagját a Microsoft az
                                        Azure
                                        névvel illeti. A felhő környezet nem jelent mást, mint egy igény szerint
                                        dinamikusan
                                        skálázható rendszert, melynek költsége a valós felhasználásokat tükrözi. Az
                                        Azure a
                                        lokális és távoli saját rendszerek, valamint az igény alapon bérelt idegen
                                        erőforrások,
                                        szolgáltatások és szoftverek hibrid koncepcióját kínálja a funkcionalitás
                                        végtelenségével.</p>
                                    <p>Az önmagában egyszerű elemek hatalmas tömege okozta komplexitása, valamint a
                                        technológia
                                        és a látásmód újszerűsége az, mely szükségessé teszi egy megfelelő szakértő
                                        bevonását.
                                        A technológiába vetett hitünk és elszántságunk oly mértékű tapasztalatokkal
                                        ruházott fel
                                        minket az évek során, mely garancia stabil, költséghatékony és megoldáscentrikus
                                        tervezésre, üzemeltetésre.</p>
                                </div>
                            </section>

                        </LoaderParallax>

                        <section className={extractClassNames('container py-5', appCss)}>
                            <h2>Office 365</h2>
                            <div className={extractClassNames('row d-flex', appCss)}>
                                <div className={extractClassNames('col-12 col-md-6 col-lg-8 order-2 order-lg-1', appCss)}>
                                    <p>Az irodai szoftvercsomagok világszerte a vállalati menedzsment és adminisztráció
                                        legalapvetőbb eszközei. Szövegszerkesztők, táblázatkezelők, kommunikációs szoftverek és
                                        egyéb speciális megoldások. A dobozból a felhőbe költözés hatására a nagyvállalati
                                        fegyvertár dinamikusan változtatható, havidíjas, felhasználónkénti számlázása a
                                        legkisebbek számára is lehetővé teszi, hogy bármilyen szükséges szoftvereszközt az
                                        igényei szerint, költséghatékonyan használjon. Emellett megoldást nyújt fájlszerver,
                                        applikációs webszerver, kollaborációs megoldások üzemeltetésére. Biztosítja távoli
                                        hozzáférést. Kiszolgálja a biztonsági mentések alapvető igényeit. Megoldást kínál
                                        jogosultságkezelésre adatbiztonság növelésére, adatszivárgás kijelzésére és
                                        titkosításra. A csomagban elérhető Exchange levelező és csoportmunka szerver jelenleg a
                                        piacon elérhető lejobb és legkomplexebb megoldás.
                                        Az Office365 az a megoldás, mely korlátlan fejlődést szavatol minimális költségek
                                        mellett. Lehetőségeket biztosít a tartalmak létrehozására és megosztására, bármilyen
                                        eszközről is van szó.</p>
                                    <p>Ügyfél- és projekttámogatási csoportunk széleskörű gyakorlattal rendelkezik üzleti,
                                        oktatási és otthoni problémákra adott költséghatékony megoldások terén. Tervezéstől a
                                        kivitelezésen át az üzemeltetésig biztosítjuk ügyfeleinknek a költséghatékonyságot és
                                        eredményességet.</p>
                                </div>
                                <div className={extractClassNames('col-12 col-md-6 col-lg-4 order-1 order-lg-2', appCss)}>
                                    <img {...require('./assets/office-365.png')} className={appCss['img-fluid']} alt="Office 365"/>
                                </div>
                            </div>

                            <div className={extractClassNames('text-center mt-5 button-perspective', appCss)}>
                                <Link to={"/szolgaltatasok/microsoft-termektamogatas/office-365"}
                                      className={extractClassNames('btn p-3', appCss) + ' ' + extractClassNames('btn', appCss)}>
                                    <span>O365 csomagok és árak</span>
                                    <FontAwesomeIcon icon={faChevronRight}/>
                                </Link>
                            </div>

                        </section>

                        <section className={extractClassNames('py-5', appCss) + ' ' + serviceCss['inverse']}
                                 style={{paddingTop: '0!important'}}>

                            <LoaderParallax src={Section3Image.src}
                                            srcSet={Section3Image.srcSet}
                                            parallaxStrength={500}
                                            loaderInverse
                                            style={parallaxStyle}
                                            parallaxBgClassName={'fade in'}
                            />

                            <div className={appCss['container']}>
                                <h2>Dynamics, Dynamic 365</h2>
                                <p>Az ERP világ csúcsszoftvereinek egyike a Microsoft Dynamics, mely a nagyvállalati
                                    eszközöket havidíjas megoldásban kínálja úgy lokális, mint akár teljesen online
                                    működéssel. A testreszabhatóság és hatékonyság igényeit magasan kiszolgáló szoftvert
                                    nem
                                    csupán az ERP szoftverek között is kiemelkedő képeségei teszik izgalmassá. Teljes
                                    körű
                                    Office365, Microsoft Exchange és Azure integrálhatósága révén jelentősen
                                    leegyszerűsíti
                                    a munkát úgy az irodai dolgozók, mint a technikai stáb (fejlesztők, üzemeltetők)
                                    részére
                                    is. A magyar pénzügyi törvényekhez illesztett lokalizációk révén képes az Ön üzleti
                                    igényeinek teljes spektrumát lefedni.</p>
                            </div>
                        </section>

                        <LoaderParallax src={Section4Image.src}
                                        srcSet={Section4Image.srcSet}
                                        parallaxStrength={500}
                                        loaderInverse
                                        style={parallaxStyle}
                                        parallaxBgClassName={'fade in'}
                        />

                        <section className={extractClassNames('py-5', appCss) + ' ' + serviceCss['primary']}>
                            <div className={appCss['container']}>
                                <h2>Microsoft Project 365</h2>
                                <p>A projektmenedzsment szoftverek egyik legjobbika, mely önmagában is egy kiváló eszköz.
                                    Felhőbe költöztetésével nem csupán a testre szabott árképzés vált elérhetővé (az egyes
                                    userek igényeihez illeszkedő, havi elszámolású díjcsomagok). Az O365 integráció révén
                                    lehetőség nyílik akár idegen vállalatok és tartományok közötti kollaborációra, mely
                                    különösen hasznos lehet fővállalkozó-alvállalkozó kapcsolatrendszerben vagy külsős
                                    projektmenedzserek bevonása esetén.
                                </p>
                            </div>
                        </section>

                        <section>
                            <div className={extractClassNames('pt-5 py-md-5', appCss)}>
                                <div className={appCss['container']}>
                                    <div>
                                        <h4 className={extractClassNames('my-5 text-center', appCss)}>A bevezetés és
                                            üzemeltetés mellett technikai oktatással is állunk ügyfeleink
                                            rendelkezésére!</h4>
                                    </div>
                                    <ToggleAnimation
                                        animationClassName={extractClassNames('slide-bottom row', appCss)}
                                        togglePercent={10}>
                                        <div
                                            className={extractClassNames('col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3', appCss)}>
                                            <div id="contactCard" className={extractClassNames('bg-white', appCss)}>
                                                <ContactForm/>
                                            </div>
                                        </div>
                                    </ToggleAnimation>
                                </div>
                            </div>
                        </section>

                    </div>
                </LoadGently>
            </React.Fragment>
        );
    }
}

Microsoft.propTypes = {};

export default Microsoft;
