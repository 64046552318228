import React from 'react';
import {Helmet} from "react-helmet";
import LoadGently from "../../../layout/LoadGently";
import ProductTheme from "../../Products/ProductTheme";
import css from "./PressWatcher.module.scss";
import ScrollToTopOnMount from "../../../helpers/ScrollToTopOnMount";
import ImageLoader from "../../../shared/ImageLoader";
import AnimatedLoader from "../../../shared/AnimatedLoader";
import Heading from "../../../layout/Heading";
import LoaderParallax from "../../../layout/LoaderParallax";
import {Section} from "../../Products/Product";
import appCss from "../../../App.module.scss";
import {extractClassNames} from "../../../helpers/ExtractClassNames";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TechnologyList from "../../../shared/Technologies/TechnologyList";
import {Technologies} from "../../Index/Technologies";
import {DynamicLocation} from "../../../helpers/DynamicLocation";

const Section1Image = require('./assets/section-1.jpg');
const Section2Image = require('./assets/section-2.jpg');
const Section3Image = require('./assets/section-3.jpg');
const Section4Image = require('./assets/section-4.jpg');

const ParallaxStyle = {
    height: '50vw',
    minHeight: '500px',
};

const PreloadImages = [
    Section1Image,
    Section2Image,
    Section3Image,
    Section4Image,
];

class PressWatcher extends React.PureComponent {
    render() {
        return (
            <React.Fragment>

                <Helmet>
                    <title>Média figyelés | Projektek | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Média,Figyelés,Projektünk,Kalkulációs,Szoftver,Import,Export,Hatékony,Megoldás,Web"/>
                    <meta name="description"
                          content="Médeia figyelési kalkulációs szoftverünk, megrendelőnek készített megoldásunk, amely segíti a mindennapi hatékony munkavégzésében."/>
                    <meta property="og:title" content="Média figyelés | Projektek | SzuniSOFT"/>
                    <meta property="og:description"
                          content="Médeia figyelési kalkulációs szoftverünk, megrendelőnek készített megoldásunk, amely segíti a mindennapi hatékony munkavégzésében."/>
                    <meta property="og:image" content={DynamicLocation.build(PreloadImages[1].src)}/>
                </Helmet>

                <LoadGently>

                    <ProductTheme productThemeId={css['project-press-watcher-product-theme']}
                                  siteThemeId={css['project-press-watcher-site-theme']}
                    >
                        <ScrollToTopOnMount/>

                        <ImageLoader multiple={PreloadImages}
                                     loading={<AnimatedLoader/>}
                                     loaded={
                                         <React.Fragment>

                                             <Heading heading="Média Figyelés"
                                                      breadCrumb={[
                                                          {text: 'Projektek', to: '/projektek'},
                                                          'Média Figyelés'
                                                      ]}
                                             />

                                             <LoaderParallax srcSet={Section1Image.srcSet} src={Section1Image.src}
                                                             parallaxStrength={500}>
                                                 <div style={ParallaxStyle}/>
                                             </LoaderParallax>

                                             <Section themeCss={css}>

                                                 <div>
                                                     <div className={appCss['container']}>
                                                         <div
                                                             className={extractClassNames('d-flex flex-column text-center text-lg-left flex-lg-row align-items-center', appCss) + ' ' + css['meta']}>
                                                             <div className={extractClassNames('flex-grow-1', appCss)}>
                                                                 <h5 className={css['theme']}>Az igény</h5>
                                                                 <ul>
                                                                     <li>Ügyfél: <b>Lounge Communication</b></li>
                                                                     <li>Év: <b>2017</b></li>
                                                                     <li>Időtartam: <b>2 hónap</b></li>
                                                                 </ul>
                                                             </div>

                                                             <div
                                                                 className={extractClassNames('flex-grow-0 flex-shrink-0 mt-4 mt-lg-0 mb-lg-0', appCss)}>
                                                                 <div className={css['icons']}>
                                                                     {[faGlobe].map((icon, i) =>
                                                                         <div
                                                                             className={css['icon']}
                                                                             key={i}>
                                                                             <div className={css['icon-wrapper']}>
                                                                                 <FontAwesomeIcon icon={icon}/>
                                                                             </div>
                                                                         </div>)}
                                                                 </div>
                                                             </div>

                                                         </div>

                                                     </div>

                                                     <div
                                                         className={extractClassNames('py-3', appCss) + ' ' + css['bg-theme']}>
                                                         <div className={appCss['container']}>
                                                             <div>
                                                                 <TechnologyList technologies={[
                                                                     Technologies.PHP,
                                                                     Technologies.Laravel,
                                                                     Technologies.Azure,
                                                                     Technologies.JavaScript
                                                                 ]}/>
                                                             </div>
                                                         </div>
                                                     </div>

                                                     <div className={appCss['container']}>

                                                         <p className={css['theme']}>Az ügyfelünk megkeresett minket,
                                                             olyan igénnyel kapcsolatosan, hogy hogyan tudná
                                                             hatékonyabban működteti jelenlegi Excel alapú megoldását.
                                                             Amely egye egészen bonyolult kalkulációt végez számukra.
                                                             Ezen számítások alapján készítik az ügyfél elszámolásokat.
                                                             Tehát ennek a megoldásnak nem csak a hatékonyságot kellet
                                                             szem elött tartania hanem fontos volt a hiba lehetőségek
                                                             minimalizálása.</p>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <LoaderParallax srcSet={Section2Image.srcSet} src={Section2Image.src}
                                                             parallaxStrength={500}>
                                                 <div style={ParallaxStyle}/>
                                             </LoaderParallax>

                                             <Section themeCss={css}>
                                                 <div>
                                                     <div className={appCss['container']}>
                                                         <h5 className={css['theme']}>Excel megoldás?</h5>
                                                         <p className={css['theme']}>
                                                             Az ügyfél első megkeresés során egy olyan Excel megoldást
                                                             szeretett volna rendelni, amely arra jelent megoldást a
                                                             kalkulációkkal kapcsolatosan, hogy miként fésüljön össze
                                                             több file több fülét. Azt ügyfél eredetileg Excel macroban
                                                             vagy Visual Basic megoldásban gondolkodott ezt átgondolva
                                                             ajánlottunk olyan hatékonyabb webes megoldást, amely bejövő
                                                             információk terén közvetlen xlsx importtal is rendelkezik
                                                             (jelentősen megkönnyítve az átállást).
                                                         </p>
                                                     </div>

                                                 </div>
                                             </Section>

                                             <LoaderParallax srcSet={Section3Image.srcSet} src={Section3Image.src}
                                                             parallaxStrength={500}>
                                                 <div style={ParallaxStyle}/>
                                             </LoaderParallax>

                                             <Section themeCss={css}>
                                                 <div>
                                                     <div className={appCss['container']}>
                                                         <h5 className={css['theme']}>Import, export</h5>
                                                         <p className={css['theme']}>A különböző Excel törzs adatok
                                                             beolvasása most már vizsgáltan történt így tovább redukálta
                                                             a hiba lehetőségeket. Viszont a törzsadatok feldolgozása és
                                                             a szükséges kalkulációk elvégzése után a program a
                                                             megfelelő formátumban és fejlécekkel ugyan csak Excel
                                                             állományt adott vissza.
                                                         </p>
                                                     </div>
                                                 </div>
                                             </Section>


                                             <LoaderParallax srcSet={Section4Image.srcSet} src={Section4Image.src}
                                                             parallaxStrength={500}>
                                                 <div style={ParallaxStyle}/>
                                             </LoaderParallax>

                                             <Section themeCss={css}>
                                                 <div>
                                                     <div className={appCss['container']}>
                                                         <h5 className={css['theme']}>Hatékony és biztonságos</h5>
                                                         <p className={css['theme']}>Fontos volt, hogy olyan felületeket
                                                             készítsünk amely rendkívül jól áttekinthető de még is nem
                                                             kell drasztikus át képzés a munkatársak részéről. Valamint
                                                             elengedhetetlen volt a bevitt információk vizsgálatát a
                                                             lehető legmélyebb szinten kezelni mivel a program fontos
                                                             pénzügyi elszámolási adatokat eredményez, amelytől függ az
                                                             ügyfelünk bevétele. De a sok vizsgálatnak köszönhetően egy
                                                             rendkívül biztonságos adatforrásnak válhatott az ügyfélnél
                                                             az elkészített szoftverünk.
                                                         </p>
                                                     </div>
                                                 </div>
                                             </Section>

                                         </React.Fragment>
                                     }
                        />

                    </ProductTheme>

                </LoadGently>
            </React.Fragment>
        );
    }
}

PressWatcher.propTypes = {};

export default PressWatcher;
