import React, {Component} from 'react';
import css from "./CateringManager.module.scss";
import LoadGently from "../../../layout/LoadGently";
import {Helmet} from "react-helmet";
import ScrollToTopOnMount from "../../../helpers/ScrollToTopOnMount";
import ImageLoader from "../../../shared/ImageLoader";
import AnimatedLoader from "../../../shared/AnimatedLoader";
import {extractClassNames} from "../../../helpers/ExtractClassNames";
import appCss from "../../../App.module.scss";
import Heading from "../../../layout/Heading";
import {Section} from "../Product";
import ToggleAnimation from "../../../shared/ToggleAnimation";
import {Parallax} from "react-parallax";
import ContactForm from "../../../shared/ContactForm";
import ProductTheme from "../ProductTheme";
import {DynamicLocation} from "../../../helpers/DynamicLocation";

const Section1Image = require('./assets/section-1.jpg?size=800');
const Section2Image = require('./assets/section-2.jpg?size=800');
const Section3Image = require('./assets/section-3.jpg?quality=90');
const Section4Image = require('./assets/section-4.jpg?quality=90');
const Section5Image = require('./assets/section-5.jpg?size=800');
const Section6Image = require('./assets/section-6.jpg?size=800');
const Section7Image = require('./assets/section-7.jpg');

const PreloadImages = [
    Section1Image,
    Section2Image,
    Section3Image,
    Section4Image,
    Section5Image,
    Section6Image,
    Section7Image,
];

const ParallaxStyle = {
    height: '50vw',
    minHeight: '500px',
    maxHeight: '750px'
};

class CateringManager extends Component {
    render() {
        return (
            <LoadGently>

                <Helmet>
                    <title>Közétkeztetés | Termékek | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Közétkeztetés,Étkezés,Szülő,Menza,Étlap,Rendelés,Díjfizetés,Befizetés,Bankkártyás,Fizetés"/>
                    <meta name="description"
                          content="Amennyiben egy hatékony iskolai, intézményi étel rendelési és fizetési rendszert keres, amely megkönnyíti a mindennapi tennivalókat, akkor megtalálta!"/>
                    <meta property="og:title" content="Közétkeztetés | Termékek | SzuniSOFT"/>
                    <meta property="og:description" content="Amennyiben egy hatékony iskolai, intézményi étel rendelési és fizetési rendszert keres, amely megkönnyíti a mindennapi tennivalókat, akkor megtalálta!"/>
                    <meta property="og:image" content={DynamicLocation.build(PreloadImages[0].src)}/>
                </Helmet>

                <ProductTheme productThemeId={css['catering-manager-product-theme']}
                               siteThemeId={css['catering-manager-site-theme']}
                >
                    <ScrollToTopOnMount/>

                    <ImageLoader multiple={PreloadImages}
                                 loading={
                                     <AnimatedLoader/>
                                 }
                                 loaded={
                                     <div className={extractClassNames("d-flex flex-grow-1 flex-column", appCss)}>
                                         <Heading heading="Catering Manager"
                                                  breadCrumb={[
                                                      {text: 'Termékek', to: '/termekek'},
                                                      'Közétkeztetés'
                                                  ]}
                                         />
                                         <div className={extractClassNames('container-fluid p-0 flex-grow-1', appCss)}>

                                             <Section themeCss={css}
                                                      layout={"first-left-last-right"}
                                                      screenHeightOnLarge
                                             >

                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-left w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: `url(${PreloadImages[0].src}) no-repeat center`,
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('d-flex align-items-center container', appCss)}>
                                                     <div>
                                                         <ToggleAnimation animationClassName="slide-right"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Közétkeztetés</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>Az
                                                                 informatika és technológia fejlődésének ellenére
                                                                 napjainkban is számos intézmény alkalmaz elavult
                                                                 megoldásokat a menza üzemeltetéséhez. Ezek az
                                                                 intézmények legtöbb esetben papír alapon vagy
                                                                 táblázatos szoftver alkalmazásával rögzítik a
                                                                 megrendelt ételeket.</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"first-right-last-left"}
                                                      screenHeightOnLarge
                                                      theme
                                             >

                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-right w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: `url(${PreloadImages[1].src}) no-repeat center`,
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('d-flex align-items-center container', appCss)}>
                                                     <div>
                                                         <ToggleAnimation animationClassName="slide-left"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Akinek
                                                                 munka</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>Ez
                                                                 főleg annak számára jelent sok munkát, aki felelős az
                                                                 adatok összegyűjtéséért és azoknak továbbításáért. Az
                                                                 ilyen esetekben sosem győzzük eléggé hangsúlyozni, hogy
                                                                 egy automatizálható rendszer csökkenti a munkához
                                                                 szükséges emberi beavatkozások számát, ezzel redukálva
                                                                 a humán hibafaktort és gyorsítva bármely
                                                                 folyamatot.</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"column"}
                                                      inverse
                                             >

                                                 <Parallax bgImage={PreloadImages[2].src}
                                                           bgImageSrcSet={PreloadImages[2].srcSet}
                                                           strength={500}
                                                 >
                                                     <div style={ParallaxStyle}/>
                                                 </Parallax>

                                                 <div
                                                     className={extractClassNames('d-flex flex-column mb-5', appCss)}>
                                                     <div>
                                                         <div className={appCss['container']}>
                                                             <ToggleAnimation animationClassName="slide-top"
                                                                              togglePercent={10}>
                                                                 <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Egyszerű
                                                                     szülői felület</h5>
                                                             </ToggleAnimation>

                                                             <ToggleAnimation animationClassName="slide-bottom"
                                                                              togglePercent={10}>
                                                                 <p className={css['theme']}>
                                                                     Külön hangsúlyt fektettünk a szülők és
                                                                     gondviselők által látható felületekre és
                                                                     funkciókra. Lehetőség van (amennyiben a
                                                                     felajánlott menük engedélyezik) választani
                                                                     akár egészségügyi, érzékenységi
                                                                     preferenciák szerint.
                                                                 </p>
                                                                 <p className={css['theme']}>
                                                                     Rendszerünk támogatja a többintézményes
                                                                     összeköttetést is. Ezzel lehetőség van
                                                                     például többgyermekes gondviselőknek arra,
                                                                     hogy gyermekei étkeztetését rendszerünkön
                                                                     keresztül intézhesse, amennyiben az
                                                                     intézmények részei hálózatunknak. Ezzel
                                                                     egyetlen egy helyen átlátható,
                                                                     visszatekinthető és nyomon követhető az
                                                                     étkeztetés folyamata.
                                                                 </p>
                                                             </ToggleAnimation>
                                                         </div>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"column"}
                                             >

                                                 <Parallax bgImage={PreloadImages[3].src}
                                                           bgImageSrcSet={PreloadImages[3].srcSet}
                                                           strength={500}
                                                 >
                                                     <div style={ParallaxStyle}/>
                                                 </Parallax>

                                                 <div
                                                     className={extractClassNames('d-flex flex-column mb-5', appCss)}>
                                                     <div className={appCss['bg-white']}>
                                                         <div className={appCss['container']}>
                                                             <ToggleAnimation animationClassName="slide-top"
                                                                              togglePercent={10}>
                                                                 <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Mindenki
                                                                     számára elérhető</h5>
                                                             </ToggleAnimation>

                                                             <ToggleAnimation animationClassName="slide-bottom"
                                                                              togglePercent={10}>
                                                                 <p className={css['theme']}>Intézményi dolgozók is
                                                                     részt vehetnek a rendszerben. Lehetőség van arra
                                                                     is, hogy intézményi dolgozók saját és gyermekük
                                                                     étkeztetését adminisztrálják egyetlen egy
                                                                     helyen.</p>
                                                             </ToggleAnimation>
                                                         </div>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"first-left-last-right"}
                                                      screenHeightOnLarge
                                                      theme
                                             >

                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-left w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: `url(${PreloadImages[4].src}) no-repeat center`,
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('d-flex align-items-center container', appCss)}>
                                                     <div>
                                                         <ToggleAnimation animationClassName="slide-right"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Egyszerű
                                                                 fizetés</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>Rendszerünk
                                                                 lehetőséget kínál arra is, hogy a megrendelt ételeket /
                                                                 menüket online bankkártyával lehessen kifizetni.</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"first-right-last-left"}
                                                      screenHeightOnLarge
                                             >

                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-right w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: `url(${PreloadImages[5].src}) no-repeat center`,
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('d-flex align-items-center container', appCss)}>
                                                     <div>
                                                         <ToggleAnimation animationClassName="slide-left"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Extra
                                                                 szolgáltatások</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>Amennyiben
                                                                 igény van rá QR kódos leolvasó rendszer telepítésére is
                                                                 készen állunk, amely arra is lehetőséget biztosít, hogy
                                                                 a szülő ellenőrizni tudja, hogy gyermeke tényleg
                                                                 elfogyasztotta a befizetett ételt, így megbizonyosodva arról, hogy
                                                                 zsebpénzét nem helytelen ételre költötte.
                                                             </p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css} layout={"column"} inverse>

                                                 <div className={appCss['container']}>
                                                     <ToggleAnimation animationClassName="slide-left"
                                                                      togglePercent={10}>
                                                         <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Hogyan
                                                             segítheti a rendszer terjedését?</h5>
                                                     </ToggleAnimation>
                                                     <ToggleAnimation animationClassName="slide-right"
                                                                      togglePercent={10}>
                                                         <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>Kérjük jelezze intézménye felé, hogy vezetője vegye fel velünk a kapcsolatot a rendszer mielőbbi bevezetéséért. Köszönjük!
                                                         </p>
                                                     </ToggleAnimation>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css} screenHeightOnLarge
                                                      className={extractClassNames('justify-content-center', appCss)}>

                                                 <Parallax bgImage={PreloadImages[6].src}
                                                           bgImageSrcSet={PreloadImages[6].srcSet}
                                                           strength={500}
                                                 >
                                                     <div style={ParallaxStyle}/>
                                                 </Parallax>

                                                 <div className={appCss['container']}>
                                                     <div>
                                                         <h4 className={extractClassNames('my-5 text-center', appCss)}>Az
                                                             egyszerű és hatékony menza egy karnyújtásnyira van!</h4>
                                                     </div>
                                                     <ToggleAnimation
                                                         animationClassName={extractClassNames('slide-bottom row', appCss)}
                                                         togglePercent={10}>
                                                         <div
                                                             className={extractClassNames('col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3', appCss)}>
                                                             <div id="contactCard" className={appCss['mb-5']}>
                                                                 <ContactForm/>
                                                             </div>
                                                         </div>
                                                     </ToggleAnimation>
                                                 </div>
                                             </Section>

                                         </div>
                                     </div>
                                 }
                    />


                </ProductTheme>

            </LoadGently>
        );
    }
}

CateringManager.propTypes = {};

export default CateringManager;
