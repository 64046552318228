import React from 'react';
import Heading from "../../../layout/Heading";
import {faWikipediaW} from "@fortawesome/free-brands-svg-icons";
import Quotation from "../../../layout/Quotation";
import css from "./CRM.module.scss";
import appCss from "../../../App.module.scss";
import ScrollToTopOnMount from "../../../helpers/ScrollToTopOnMount";
import ContactForm from "../../../shared/ContactForm";
import ToggleAnimation from "../../../shared/ToggleAnimation";
import AnimatedLoader from "../../../shared/AnimatedLoader";
import ProductTheme from "../ProductTheme";
import {Section} from "../Product";
import {extractClassNames} from "../../../helpers/ExtractClassNames";
import {Parallax} from "react-parallax";
import ImageLoader from "../../../shared/ImageLoader";
import LoadGently from "../../../layout/LoadGently";
import {Helmet} from "react-helmet";
import {DynamicLocation} from "../../../helpers/DynamicLocation";

const Section1Image = require('./assets/section-1.jpg?size=850');
const Section2Image = require('./assets/section-2.jpg');
const Section3Image = require('./assets/section-3.jpg?quality=90');
const Section4Image = require('./assets/section-4.jpg?quality=90,size=1024');
const Section5Image = require('./assets/section-5.jpg?quality=90,size=1024');
const Section6Image = require('./assets/section-6.jpg');
const Section7Image = require('./assets/section-7.jpg');
const Section8Image = require('./assets/section-8.jpg');
const Section9Image = require('./assets/section-9.jpg');
const Section10Image = require('./assets/section-10.jpg?quality=90,size=1200');
const Section11Image = require('./assets/section-11.jpg?quality=80,size=800');
const Section12Image = require('./assets/section-12.jpg?size=800');

const PreloadImages = [
    Section1Image,
    Section2Image,
    Section3Image,
    Section4Image,
    Section5Image,
    Section6Image,
    Section7Image,
    Section8Image,
    Section9Image,
    Section10Image,
    Section11Image,
    Section12Image,
];

const ParallaxStyle = {
    height: '50vw',
    minHeight: '500px',
};

class Crm extends React.PureComponent {

    render() {
        return (
            <LoadGently>

                <Helmet>
                    <title>CRM | Termékek | SzuniSOFT</title>
                    <meta name="keywords"
                          content="CRM,Ügyfél,Adatok,Kapcsolatkezelő,Üzleti,Folyamat,Adminisztráció,Határidők,Teendők,Elérhetőségek"/>
                    <meta name="description"
                          content="A CRM-ben egy helyen lehet minden ügyféllel kapcsolatos információt kezelni, ami megrövidíti a keresési, továbbá az adminisztrációs folyamatot is."/>
                    <meta property="og:title" content="CRM | Termékek | SzuniSOFT"/>
                    <meta property="og:description" content="A CRM-ben egy helyen lehet minden ügyféllel kapcsolatos információt kezelni, ami megrövidíti a keresési, továbbá az adminisztrációs folyamatot is."/>
                    <meta property="og:image" content={DynamicLocation.build(PreloadImages[0].src)}/>
                </Helmet>

                <ProductTheme productThemeId={css['crm-product-theme']}
                              siteThemeId={css['crm-site-theme']}
                >
                    <ScrollToTopOnMount/>

                    <ImageLoader multiple={PreloadImages}
                                 loading={
                                     <AnimatedLoader/>
                                 }
                                 loaded={
                                     <div className={extractClassNames("d-flex flex-grow-1 flex-column", appCss)}>
                                         <Heading heading="CRM"
                                                  breadCrumb={[
                                                      {text: 'Termékek', to : '/termekek'},
                                                      'Customer Relation Manager'
                                                  ]}
                                         />
                                         <div className={extractClassNames('container-fluid p-0 flex-grow-1', appCss)}>

                                             <Section themeCss={css}
                                                      layout={"column"}
                                                      screenHeightOnLarge>
                                                 <div className={appCss['container'] + ' ' + css['container']}>
                                                     <ToggleAnimation animationClassName="slide-top" togglePercent={10}>
                                                         <h5 className={appCss['flex-grow-0'] + ' ' + css['theme']}>Mi is a
                                                             CRM?</h5>
                                                     </ToggleAnimation>
                                                     <ToggleAnimation animationClassName="slide-bottom" togglePercent={10}>
                                                         <p className={appCss['flex-grow-0'] + ' ' + css['theme']}> Maga a
                                                             rövidítés az
                                                             angol <b><i>Customer
                                                                 Relationship
                                                                 Manager</i></b> szavakból
                                                             ered, bár
                                                             komplexebb
                                                             szoftverek esetében már
                                                             megfelelőbb a <b><i>Company Relationship
                                                                 Manager</i></b> kifejezés.</p>
                                                     </ToggleAnimation>
                                                 </div>
                                                 <div
                                                     className={extractClassNames('text-right flex-grow-1 align-items-end justify-content-end d-flex', appCss)}>
                                                     <ToggleAnimation animationClassName="fade" togglePercent={10}>
                                                         <img src={PreloadImages[0].src} alt="Section image"
                                                              className={appCss['img-fluid']}/>
                                                     </ToggleAnimation>
                                                 </div>
                                                 <ToggleAnimation togglePercent={10} animationClassName="fade">
                                                     <div className={css['quotation-wrapper']}>
                                                         <div className={appCss['container'] + ' ' + css['container']}>
                                                             <Quotation
                                                                 className={css['quotation']}
                                                                 header="Wikipédia meghatározása szerint.."
                                                                 icon={faWikipediaW}
                                                                 text={<h5 className={appCss['font-weight-light']}>Az
                                                                     ügyfélkapcsolat-kezelés
                                                                     fogalma
                                                                     egy
                                                                     cég
                                                                     saját partnerei
                                                                     felé
                                                                     irányuló folyamatainak leírására vonatkozik. Egy CRM
                                                                     szoftver célja,
                                                                     hogy
                                                                     ezeket
                                                                     a
                                                                     folyamatokat
                                                                     támogassa, illetve a jelenlegi és potenciális
                                                                     ügyfelekkel
                                                                     kapcsolatos
                                                                     információkat
                                                                     tárolja</h5>}/>
                                                         </div>
                                                     </div>
                                                 </ToggleAnimation>
                                             </Section>


                                             <Section themeCss={css}
                                                      screenHeightOnLarge
                                                      layout={"first-right-last-left"}>
                                                 <div
                                                     className={extractClassNames('d-flex align-items-end overflow-hidden order-last order-lg-first', appCss)}>
                                                     <ToggleAnimation togglePercent={10} animationClassName="slide-bottom">
                                                         <img src={PreloadImages[1].src} className={appCss['img-fluid']}
                                                              alt="Hand figure"/>
                                                     </ToggleAnimation>
                                                 </div>
                                                 <div
                                                     className={extractClassNames('container d-flex flex-column align-items-center justify-content-center order-first order-lg-last', appCss)}>
                                                     <div>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-right">
                                                             <h5 className={css['theme']}>Miért szükséges a CRM?</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-left">
                                                             <p className={css['theme']}>Amikor a levelező rendszerben már
                                                                 képtelenséggé
                                                                 válik
                                                                 követni
                                                                 az
                                                                 üzleti kapcsolatok építésének folyamatát, vagy mikor
                                                                 létszámban
                                                                 és szakterületben rendkívül szerteágazó egy
                                                                 cég, akkor
                                                                 rendkívül jól struktúrált alapot és kezelhetőséget adhat
                                                                 egy CRM. A
                                                                 CRM-ben
                                                                 egy helyen lehet minden
                                                                 ügyféllel
                                                                 kapcsolatos
                                                                 információt kezelni, ami megrövidíti a keresési, továbbá az
                                                                 adminisztrációs
                                                                 folyamatot is. Amennyiben esetleg egy
                                                                 kolléga
                                                                 távozik és új
                                                                 érkezik a helyébe, akkor egy helyről vett információkkal
                                                                 gyorsan fel tud
                                                                 zárkózni.</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}
                                                      inverse
                                                      className={extractClassNames('d-flex flex-column overflow-hidden', appCss)}
                                             >
                                                 <div
                                                     className={extractClassNames('container flex-grow-0 pb-3 pt-5', appCss)}>
                                                     <ToggleAnimation animationClassName="slide-top" togglePercent={10}>
                                                         <h5 className={css['theme']}>Milyen adatokat kell kezelni a
                                                             CRM-ben?</h5>
                                                     </ToggleAnimation>
                                                 </div>
                                                 <div className={extractClassNames('container flex-grow-1 pb-3', appCss)}>
                                                     <ToggleAnimation animationClassName="slide-right" togglePercent={10}>
                                                         <p className={css['theme']}>Alapvetően minden ügyféllel, vagy akár
                                                             bármely
                                                             együttműködővel
                                                             kapcsolatos
                                                             információkat,
                                                             például <b>határidőket</b>, <b>teendőket</b>, <b>elérhetőségeket</b>,
                                                             stb.
                                                             tárolhat.
                                                             Minden CRM rendszer esetében különösen fontos szempont az
                                                             általa tárolt
                                                             adatok típusai, mennyiségei illetve, hogy egyáltalán van-e
                                                             lehetőség
                                                             bizonyos adatok rögzítésére.
                                                         </p>
                                                     </ToggleAnimation>
                                                 </div>
                                                 <div className={extractClassNames('container flex-grow-0', appCss)}>
                                                     <ToggleAnimation togglePercent={10} animationClassName="slide-bottom">
                                                         <div style={{
                                                             minHeight: '450px',
                                                             background: 'url(' + PreloadImages[2].src + ') no-repeat top center',
                                                             backgroundSize: 'cover'
                                                         }}/>
                                                     </ToggleAnimation>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}
                                                      screenHeightOnLarge
                                                      layout={"first-left-last-right"}
                                             >
                                                 <ToggleAnimation
                                                     animationClassName={'slide-left ' + extractClassNames('w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: 'url(' + PreloadImages[3].src + ') no-repeat center',
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('container d-flex flex-column justify-content-center', appCss)}>
                                                     <ToggleAnimation togglePercent={10} animationClassName="slide-right">
                                                         <h5 className={css['theme']}>CRM haszna a mindennapokban?</h5>
                                                         <p className={css['theme']}>Érdemes néhány egyszerű hétköznapi
                                                             példát
                                                             végig gondolni..</p>
                                                         <ul className={css['theme']}>
                                                             <li className={appCss['py-2']}>
                                                                 Telefonon keresi egy, már meglévő ügyfele. A beszélgetés
                                                                 kezdetén
                                                                 rákeres a CRM-ben és úgy tudja elkezdeni és folytatni a
                                                                 beszélgetést az
                                                                 ügyféllel, hogy már minden hasznos
                                                                 információt tud róla.
                                                                 Nincs bizonytalanság és nem kell feltartani az ügyfelet.
                                                                 Minden időt az
                                                                 üzlet lebonyolítására és az aktuális probléma megoldására
                                                                 lehet
                                                                 fordítani.
                                                             </li>
                                                             <li className={appCss['py-2']}>
                                                                 Egyik kollegája emailben irányítja Önhöz az Ön számára
                                                                 ismeretlen
                                                                 ügyfelet.
                                                                 Az információkat önnállóan
                                                                 képes begyűjteni az ügyfélről. Teljes képet kaphat a
                                                                 vállalat és az
                                                                 ügyfél kapcsolatának előéletéről.
                                                             </li>
                                                             <li className={appCss['py-2']}>
                                                                 Valaki kiküldött egy árajánlatot. Nyomon követése annak,
                                                                 hogy ki küldte
                                                                 ki, hányadik
                                                                 verziót küldte ki. Ez
                                                                 lehetőséges biztosít a hosszabb-kifutású ajánlat szakaszok
                                                                 lekövetésére.
                                                             </li>
                                                             <li className={appCss['py-2']}>
                                                                 Amennyiben éppen üres járata van, a CRM lehetőséget
                                                                 biztosít olyan
                                                                 speciális keresésekre,
                                                                 melyekkel letudja szűrni, hogy melyik ügyféllel vette fel
                                                                 rég a
                                                                 kapcsolatot. Ezáltal
                                                                 egy, már kihűlt ügyfélkapcsolatot újra fel tud eleveníteni.
                                                                 Így újra visszaállíthatja az értékes ügyfélkapcsolatot, vagy
                                                                 akár termék
                                                                 bővítési
                                                                 ötletelést is támogathat.
                                                             </li>
                                                         </ul>
                                                     </ToggleAnimation>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}
                                                      screenHeightOnLarge
                                                      layout={"first-right-last-left"}
                                                      theme
                                             >
                                                 <ToggleAnimation
                                                     animationClassName={'slide-right ' + extractClassNames('w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: 'url(' + PreloadImages[4] + ') no-repeat center',
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div className={extractClassNames('container d-flex align-items-center', appCss)}>
                                                     <ToggleAnimation animationClassName="slide-left" togglePercent={10}>
                                                         <h5 className={css['theme']}>Ha többletmunka, akkor hol az
                                                             előnye? </h5>
                                                         <p className={css['theme']}>Tényszerűén, amíg az ember ismerkedik a
                                                             felületekkel és
                                                             zajlik a bevezetés folyamata <i>- viszi fel a kezdeti
                                                                 információkat -</i>,
                                                             több munkaórát igényel a rendszeres használat.
                                                             Hiszen olyan adatokat gyűjtünk, <b>melyekkel eddig nem is
                                                                 törődtünk</b>.
                                                             Azonban, amikor már csak az új ügyfeleket,
                                                             eseményeket szeretnénk rögzíteni, akkor már csupán elenyésző
                                                             többletről van
                                                             szó.
                                                             Későbbiekben viszont megkönnyíti a mindennapokat.
                                                             Hiszen kellő adatmennyiség után már megfelelő kimutatásokra tud
                                                             támaszkodni
                                                             döntései során.
                                                             Olyan reportokra, melyek előállításai eddig túl sok időt
                                                             igényeltek, vagy
                                                             a nem mentett adatok miatt nem volt lehetősége az előállításra,
                                                             így a
                                                             megfelelő döntés meghozatalára.</p>
                                                         <p className={css['theme']}>Találkoztunk már mi is
                                                             cégekkel,
                                                             akik
                                                             ugyan
                                                             megpróbálták mindezt például egy táblázatkezelő szoftverben
                                                             megoldani, mert
                                                             felismerték az
                                                             elemzések és kimutatások fontosságait.
                                                             A CRM-re történő átállást követően ezek a cégek hatékonyabban
                                                             és rövidebb
                                                             idő alatt oldották meg a feladatokat.</p>
                                                     </ToggleAnimation>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}
                                                      inverse
                                                      screenHeightOnLarge
                                                      layout={"first-left-last-right"}
                                             >
                                                 <ToggleAnimation
                                                     animationClassName={'slide-left ' + extractClassNames('w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: 'url(' + PreloadImages[5] + ') no-repeat bottom center',
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '850px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('container d-flex flex-column justify-content-center', appCss)}>
                                                     <ToggleAnimation animationClassName="slide-right" togglePercent={10}>
                                                         <h5 className={css['theme']}>Miért jó egy CRM-mel indítani a céges
                                                             életet?</h5>
                                                         <p className={css['theme']}>Egy induló cég - <i>jó esetben minél
                                                             korábban</i> -
                                                             sok partneri kapcsolatra tesz szert.
                                                             Amennyiben már az kezdetektől fogva egy CRM-ben tároljuk a
                                                             kapcsolati
                                                             adatokat,
                                                             nem okozhat gondot, mikor robbanás-szerű növekedésnek indul a
                                                             cég.
                                                             Induláskor helyesen alakítani a <i>know-how</i>-ot
                                                             minimális költség többlet. Utólag átalakítani pedig nem ritkán
                                                             lehetetlen. A
                                                             hibás <i>know-how</i> pedig könnyen visszavethet egy startupot.
                                                         </p>
                                                     </ToggleAnimation>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"column"}
                                             >
                                                 <Parallax bgImage={PreloadImages[6].src}
                                                           bgImageSrcSet={PreloadImages[6].srcSet}
                                                           strength={500}
                                                 >
                                                     <div style={ParallaxStyle}>
                                                         <h5 className={appCss['text-center'] + ' ' + css['theme'] + ' ' + appCss['bg-white']}>CRM
                                                             vagy ERP?</h5>
                                                     </div>
                                                 </Parallax>
                                                 <ToggleAnimation togglePercent={10} animationClassName="slide-bottom">
                                                     <div className={extractClassNames('container pt-3', appCss)}>
                                                         <p className={css['theme']}>Sokszor találkozunk a kérdéssel.
                                                             Igazság szerint ez egy
                                                             ritkán eldöntendő kérdés, hiszen általában a CRM vagy egy
                                                             önálló alkalmazás,
                                                             vagy az
                                                             ERP szoftver egy része. Bővítve számlázással, könyveléssel,
                                                             készletvezetéssel, stb.
                                                             Így sokszor a cég mérete, tevékenységi köre
                                                             hozza meg a döntést a kérdésben. Amennyiben kisebb cégről
                                                             beszélünk
                                                             kezdetnek
                                                             nagyon
                                                             hasznos egy
                                                             CRM használata akár önálló szoftverként, akár egy ERP rendszer
                                                             részeként.</p>
                                                     </div>
                                                 </ToggleAnimation>
                                             </Section>

                                             <Section themeCss={css}
                                                      screenHeightOnLarge
                                                      theme
                                                      layout={"first-right-last-left"}
                                             >
                                                 <ToggleAnimation togglePercent={10}
                                                                  animationClassName={extractClassNames('slide-right w-100 h-100', appCss)}>
                                                     <div style={{
                                                         background: 'url(' + PreloadImages[7].src + ') no-repeat bottom center',
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div className={extractClassNames('container d-flex align-items-center', appCss)}>
                                                     <ToggleAnimation togglePercent={10} animationClassName="slide-left">
                                                         <h5 className={css['theme']}>A CRM-en túl merre?</h5>
                                                         <p className={css['theme']}>Amennyiben az adatok már egy helyen,
                                                             jól kezelhetően
                                                             vannak tárolva fontos, hogy ezeknek
                                                             az adatoknak a felhasználása a cég más területein is hasznosan
                                                             zajlódjanak.
                                                             Ebben az esetben egy folyamatos bővülési folyamat lenne a
                                                             leghatékonyabb,
                                                             hogy a cég együtt tudjon
                                                             nőni az általa használt ügyviteli szoftverekkel.
                                                             Mi azt szoktuk javasolni kisebb ügyfeleink számára, hogy
                                                             kezdetben
                                                             induljanak egy kisebb szoftverrel, vagy szoftver csomaggal,
                                                             hogy idővel ezt
                                                             folyamatosan bővítve törjenek a csúcsra.
                                                             Nagyoknak is fájdalommentesebb lépésről-lépésre
                                                             (modulról-modulra) való
                                                             átállás.</p>
                                                     </ToggleAnimation>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"column"}
                                                      className={appCss['overflow-hidden']}
                                             >
                                                 <Parallax bgImage={PreloadImages[8].src}
                                                           bgImageSrcSet={PreloadImages[8].srcSet}
                                                           strength={500}
                                                 >
                                                     <div style={ParallaxStyle}>
                                                         <h5 className={css['theme'] + ' ' + appCss['text-center'] + ' ' + appCss['bg-white']}>Milyen
                                                             CRM-et
                                                             válasszunk?</h5>
                                                     </div>
                                                 </Parallax>

                                                 <div className={appCss['container']}>
                                                     <ToggleAnimation togglePercent={10} animationClassName="slide-right">
                                                         <p className={css['theme']}>A piacon jelenleg sok CRM érhető el, de
                                                             csak egy
                                                             portfóliós oldal alapján választani veszélyes.</p>
                                                         <p className={css['theme']}>
                                                             Nehezen mérhető fel, hogy minden kérdésre a megfelelő választ
                                                             adja-e az
                                                             adott CRM csupán egy portfólió alapján. Tesztelni minden CRM-et
                                                             a piacon
                                                             pedig gyakorlatilag lehetetlenség.
                                                             Ezért javasoljuk, hogy mindenképpen vegye fel a kapcsolatot a
                                                             CRM-et készítő
                                                             céggel.</p>
                                                         <p className={css['theme'] + ' ' + appCss['pb-5']}>
                                                             Egyrészről azért, hogy megbizonyosodjon róla, hogy a megfelelő
                                                             szakértelemmel állnak úgy a programozási, mint a
                                                             felhasználási kérdéskörök mögött. Másrészről pedig azért, hogy
                                                             a hatékony és
                                                             hosszútávú együttműködés lehetőségéről megbizonyosodjunk.
                                                             Tényszerűen CRM rendszereket nem váltanak túl gyakran a cégek,
                                                             mivel az
                                                             magában egy hosszabb <i>(általában több hónapos, esetenként
                                                             éves)</i> átállási folyamat.
                                                             Emellett az embereket át kell képezni az új szoftver
                                                             használatára és nem
                                                             ritkán az új szervezeti kultúra alkalmazására.
                                                         </p>
                                                     </ToggleAnimation>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"column"}
                                             >
                                                 <div className={extractClassNames('py-3 flex-grow-0', appCss)}>
                                                     <Parallax bgImage={PreloadImages[9].src}
                                                               bgImageSrcSet={PreloadImages[9].srcSet}
                                                               strength={500}
                                                     >
                                                         <div style={ParallaxStyle}>
                                                             <h5 className={css['theme'] + ' ' + extractClassNames('mb-0 text-center bg-white', appCss)}>Egyedi
                                                                 CRM?</h5>
                                                         </div>
                                                     </Parallax>

                                                     <ToggleAnimation togglePercent={10} animationClassName="slide-bottom">
                                                         <div className={extractClassNames('container pt-3', appCss)}>
                                                             <p className={css['theme']}>Amennyiben egy cég megfelelően
                                                                 specifikus területtel
                                                                 foglalkozik, vagy csak szimplán drasztikus egyedi
                                                                 igényekkel
                                                                 rendelkezik, célszerű megvizsgálni, hogy egy egyedi program
                                                                 támogatással milyen hatékonyság lenne elérhető.</p>
                                                             <p className={css['theme']}>A saját igényű szoftver sok
                                                                 potenciált hordoz
                                                                 magában.
                                                                 A cégnek lehetősége van kialakítani a saját adatkezelési
                                                                 módszerét. Az
                                                                 egyedi megoldások tartják mozgásban a technológiát, éppen
                                                                 ezért, sosem
                                                                 lehet tudni, hogy melyik cég fogja meghatározni az új
                                                                 standardokat a
                                                                 jövőre nézve.</p>
                                                         </div>
                                                     </ToggleAnimation>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}
                                                      screenHeightOnLarge
                                                      layout={"first-right-last-left"}
                                                      inverse>
                                                 <ToggleAnimation togglePercent={10}
                                                                  animationClassName={extractClassNames('slide-right w-100 h-100', appCss)}>
                                                     <div style={{
                                                         background: 'url(' + PreloadImages[10].src + ') no-repeat center',
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div className={extractClassNames('container d-flex align-items-center', appCss)}>
                                                     <div>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-left">
                                                             <h5 className={css['theme']}>CRM bevezetés. Miért van erre
                                                                 szükség? </h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-bottom">
                                                             <p className={css['theme']}>A CRM rendszerek alapvetően nem
                                                                 olyan egyszerűek.
                                                                 Maga
                                                                 a
                                                                 program feladatkörökre felosztottan bár egyszerű,
                                                                 használata szervezeti kultúrába való illesztése bonyolulttá
                                                                 teheti. A
                                                                 bonyolultságot
                                                                 inkább
                                                                 a
                                                                 hatékonyságban
                                                                 és a kihasználtságban lehetne mérni.
                                                                 Nagyon fontosnak tartjuk, hogy egy CRM használatba vétele
                                                                 előtt egy
                                                                 felméréssel
                                                                 kezdődjön az
                                                                 együttműködés, hogy
                                                                 a bevezetés során a megfelelő helyeken történjenek
                                                                 változások,
                                                                 amelyek maximálisan szolgálják az eredményességet.</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}
                                                      screenHeightOnLarge
                                                      layout={"first-left-last-right"}
                                             >
                                                 <ToggleAnimation togglePercent={10}
                                                                  animationClassName={extractClassNames('slide-left w-100 h-100', appCss)}>
                                                     <div style={{
                                                         background: 'url(' + PreloadImages[11].src + ') no-repeat bottom center',
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div className={extractClassNames('container d-flex align-items-center', appCss)}>
                                                     <div>
                                                         <ToggleAnimation animationClassName="slide-right"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme']}>Miért érdemes minket
                                                                 választani?</h5>
                                                         </ToggleAnimation>

                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme']}>Szakértő csapatunk segít önnek és
                                                                 cégének a
                                                                 megfelelő
                                                                 eszközt
                                                                 megtalálni a hatékony
                                                                 munkához és működéshez.
                                                                 Amennyiben egyedi igénye van, megkeressük a költségben és
                                                                 hatékonyságban
                                                                 a legjobb megoldást.
                                                                 Nálunk megtalálhatók a <b>15 éves</b> üzletépítési
                                                                 tapasztalattal
                                                                 rendelkező
                                                                 ügyfélkommunikációs, programok bevezetésben jártas kollégák
                                                                 és
                                                                 fejlesztői csapatok, szakértők.</p>
                                                         </ToggleAnimation>

                                                         <div className={appCss['container']}>
                                                             <ToggleAnimation animationClassName="slide-bottom"
                                                                              togglePercent={10}>
                                                                 <div className={appCss['row']}>
                                                                     <div
                                                                         className={extractClassNames('col-12 col-xl-8 offset-xl-2', appCss)}>
                                                                         <h3 className={extractClassNames('my-5 text-center', appCss)}>Forduljon
                                                                             hozzánk bizalommal!</h3>
                                                                         <section className="contact">
                                                                             <div id="contactCard">
                                                                                 <ContactForm/>
                                                                             </div>
                                                                         </section>
                                                                     </div>
                                                                 </div>
                                                             </ToggleAnimation>
                                                         </div>
                                                     </div>
                                                 </div>
                                             </Section>

                                         </div>
                                     </div>
                                 }
                    />



                </ProductTheme>

            </LoadGently>
        );
    }
}

Crm.propTypes = {};

export default Crm;
