import React, {Component} from 'react';
import PropTypes from 'prop-types';
import css from './SidePanel.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons'

class SidePanel extends Component {

    state = {
        isActive: false
    };

    componentDidMount() {
        this.setState({isActive: this.props.isActive});
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({isActive: nextProps.isActive});

        !!nextProps.isActive
            ? this.getRoot().classList.add('overflow-hidden')
            : this.getRoot().classList.remove('overflow-hidden');
    }

    getRoot() {
        return document.getElementsByTagName('html')[0];
    }

    close = () => {
        if (typeof this.props.onExit !== "undefined") {
            this.props.onExit();
        }
        this.setState({isActive: false})
    };

    render() {
        return (
            <div id={this.props.id || null}
                 className={(this.props.className || null) + " " + css["side-panel"] + " " + (this.state.isActive ? css.active : '')}>
                <div className={css["full-cover"]} onClick={this.close}/>
                <div className={css.panel + ' bg-theme'}>
                    <div className={css["title"]}>
                        <div className={css["title-wrapper"]}>
                            {this.props.title}
                        </div>
                        <div onClick={this.close} className={css["exit-btn"]}>
                            <FontAwesomeIcon icon={faTimesCircle} size={"3x"}/>
                        </div>
                    </div>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

SidePanel.propTypes = {
    isActive: PropTypes.bool,
    onExit: PropTypes.func,
    title: PropTypes.element,
    id: PropTypes.string,
    className: PropTypes.string
};

export default SidePanel;
