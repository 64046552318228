import Index from "./pages/Index";
import About from "./pages/About";
import Products from "./pages/Products/Products";
import Services from "./pages/Services";
import News from "./pages/News";
import Article from "./pages/Article";
import NotFound from "./navigation/NotFound";
import Software from "./pages/Services/Software";
import Servicing from "./pages/Services/Servicing";
import Network from "./pages/Services/Network";
import Microsoft from "./pages/Services/Microsoft";
import Crm from "./pages/Products/CRM";
import Ticketing from "./pages/Products/Ticketing";
import Web from "./pages/Services/Software/Web";
import Mobile from "./pages/Services/Software/Mobile";
import QuickInventory from "./pages/Products/QuickInventory";
import ADManager from "./pages/Products/ADManager";
import CateringManager from "./pages/Products/CateringManager";
import Desktop from "./pages/Services/Software/Desktop";
import DigiKIP from "./pages/Projects/DigiKIP";
import CSP from "./pages/Products/CSP";
//import AsyncComponent from "./helpers/AsyncComponent";
import React from "react";
import Projects from "./pages/Projects";
import ProjectManager from "./pages/Projects/ProjectManager";
import PressWatcher from "./pages/Projects/PressWatcher";
import O365Prices from "./pages/Services/Microsoft/O365Prices";

//const projects = () => import(/* WebpackChunkName: "projects" */ './pages/Projects/index');

export const Routes = [
    {
        path: '/projektek',
        exact: true,
        //component: () => <AsyncComponent moduleProvider={projects}/>
        component: Projects
    },
    {
        path: '/',
        exact: true,
        component: Index
    },
    {
        path: '/rolunk',
        exact: true,
        component: About
    },
    {
        path: '/termekek',
        exact: true,
        component: Products
    },
    {
        path: '/termekek/crm',
        exact: true,
        component: Crm
    },
    {
        path: '/termekek/csomopont',
        exact: true,
        component: CSP
    },
    {
        path: '/termekek/hibajegykezelo',
        exact: true,
        component: Ticketing
    },
    {
        path: '/termekek/gyorsleltar',
        exact: true,
        component: QuickInventory
    },
    {
        path: '/termekek/active-directory-menedzser',
        exact: true,
        component: ADManager
    },
    {
        path: '/termekek/kozetkeztetes',
        exact: true,
        component: CateringManager
    },
    {
        path: '/szolgaltatasok',
        exact: true,
        component: Services
    },
    {
        path: '/szolgaltatasok/szoftverfejlesztes',
        exact: true,
        component: Software
    },
    {
        path: '/szolgaltatasok/szoftverfejlesztes/web',
        exact: true,
        component: Web
    },
    {
        path: '/szolgaltatasok/szoftverfejlestes/mobil',
        exact: true,
        component: Mobile
    },
    {
        path: '/szolgaltatasok/szoftverfejlestes/asztali',
        exact: true,
        component: Desktop
    },
    {
        path: '/szolgaltatasok/rendszeruzemeltetes',
        exact: true,
        component: Servicing
    },
    {
        path: '/szolgaltatasok/halozatfejlesztes',
        exact: true,
        component: Network
    },
    {
        path: '/szolgaltatasok/microsoft-termektamogatas',
        exact: true,
        component: Microsoft
    },
    {
        path: '/szolgaltatasok/microsoft-termektamogatas/office-365',
        exact: true,
        component: O365Prices
    },
    {
        path: '/hirek/:page?',
        exact: true,
        component: News,
    },
    {
        path: '/hirek/cikk/:id/:title',
        exact: true,
        component: Article
    },
    {
        path: '/projektek/digi-kip',
        exact: true,
        component: DigiKIP
    },
    {
        path: '/projektek/projekt-menedzser',
        exact: true,
        component: ProjectManager
    },
    {
        path: '/projektek/media-figyeles',
        exact: true,
        component: PressWatcher
    },
    {
        component: NotFound
    }
];
