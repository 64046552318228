import React, {Component} from 'react';
import PropTypes from 'prop-types';
import css from "./Quotation.module.scss";
import appCss from "../../App.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {extractClassNames} from "../../helpers/ExtractClassNames";

class Quotation extends Component {
    render() {
        return (
            <blockquote className={css['quotation'] + ' ' + (this.props.className || '')}>
                <div className={appCss['d-flex'] + ' ' + css['header']}>
                    <h2 className={appCss['flex-grow-1']}>{this.props.header}</h2>
                    {this.props.icon ? <FontAwesomeIcon
                        className={extractClassNames('flex-grow-0 flex-shrink-0', appCss) + ' ' + css['icon']}
                        size={"2x"} icon={this.props.icon}/> : null}
                </div>
                <div>
                    {this.props.text}
                </div>
                <div>{this.props.children}</div>
            </blockquote>
        );
    }
}

Quotation.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.any,
    header: PropTypes.string,
    text: PropTypes.any
};

export default Quotation;
