import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookSquare, faInstagram, faLinkedin, faTwitterSquare} from "@fortawesome/free-brands-svg-icons";
import css from "./Footer.module.scss";
import appCss from "../../App.module.scss"
import {NavLink} from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <footer>

                <div className={appCss["container"]}>

                    <div className={css.top}>
                        <h5 className={css.brand}>SzuniSOFT</h5>
                        <div className={css.social}>
                            <FontAwesomeIcon icon={faFacebookSquare} size={"2x"} className={appCss["mx-2"]}/>
                            <FontAwesomeIcon icon={faTwitterSquare} size={"2x"} className={appCss["mx-2"]}/>
                            <FontAwesomeIcon icon={faLinkedin} size={"2x"} className={appCss["mx-2"]}/>
                            <FontAwesomeIcon icon={faInstagram} size={"2x"} className={appCss["ml-2"]}/>
                        </div>
                    </div>

                    <div className={css["menu"]}>
                        <ul>
                            <li>
                                <NavLink to="/hirek" activeClassName={css.active} className={css["menu-item"]}>hírek</NavLink>
                            </li>
                            <li>
                                <NavLink to="/szolgaltatasok" activeClassName={css.active} className={css["menu-item"]}>szolgáltatások</NavLink>
                            </li>
                            <li>
                                <NavLink to="/termekek" activeClassName={css.active} className={css["menu-item"]}>termékek</NavLink>
                            </li>
                            <li>
                                <NavLink to="/projektek" activeClassName={css.active} className={css["menu-item"]}>projektek</NavLink>
                            </li>
                            <li>
                                <NavLink to="/rolunk" activeClassName={css.active} className={css["menu-item"]}>rólunk</NavLink>
                            </li>
                        </ul>
                    </div>

                    <div className={css.copy}>
                        <small>&copy; 2014-{(new Date()).getFullYear()} - SzuniSOFT BT. Minden jog fenntartva.</small>
                    </div>
                </div>

            </footer>
        );
    }
}

Footer.propTypes = {};

export default Footer;
