import type {AppActionT, AppT} from './types';
import {AppActionTypes} from './actions';

export const initialState: AppT = Object.freeze({
    locale: 'en-US',
    sendContactState: null
});

export default (state: AppT = initialState, action: AppActionT): AppT => {
    const {type, payload} = action;

    switch (type) {
        case AppActionTypes.SET_LOCALE: {
            return {
                ...state,
                locale: payload,
            };
        }
        case AppActionTypes.SET_CONTACT_STATE: {
            return {
                ...state,
                sendContactState: payload
            }
        }
    }

    return state;
};
