import React, {Component} from 'react';
import LoadGently from "../../../layout/LoadGently";
import {Helmet} from "react-helmet";
import ProductTheme from "../ProductTheme";
import css from "./ADManager.module.scss";
import ScrollToTopOnMount from "../../../helpers/ScrollToTopOnMount";
import ImageLoader from "../../../shared/ImageLoader";
import AnimatedLoader from "../../../shared/AnimatedLoader";
import {extractClassNames} from "../../../helpers/ExtractClassNames";
import appCss from "../../../App.module.scss";
import Heading from "../../../layout/Heading";
import {Section} from "../Product";
import ToggleAnimation from "../../../shared/ToggleAnimation";
import {Parallax} from "react-parallax";
import ContactForm from "../../../shared/ContactForm";
import {DynamicLocation} from "../../../helpers/DynamicLocation";

const Section1Image = require('./assets/section-1.jpg?quality=90');
const Section2Image = require('./assets/section-2.jpg?quality=90,size=1200');
const Section3Image = require('./assets/section-3.jpg?quality=90,size=1200');
const Section4Image = require('./assets/section-4.jpg?quality=90');
const Section5Image = require('./assets/section-5.jpg?quality=90,size=1200');

const PreloadImages = [
    Section1Image,
    Section2Image,
    Section3Image,
    Section4Image,
    Section5Image,
];

const ParallaxStyle = {
    height: '50vw',
    minHeight: '500px',
};

class ADManager extends Component {

    render() {
        return (
            <LoadGently>

                <Helmet>
                    <title>AD Menedzser | Termékek | SzuniSOFT</title>
                    <meta name="keywords"
                          content="AD,Active,Directory,Manager,Windows,Server,Web,Felhasználó,Kezelés,Felügyelet"/>
                    <meta name="description"
                          content="Windows szerver felhasználóinak kezelése gyorsan, egyszerűen, biztonságosan mobil optimalizált webes környezetben. Rendszergazdai tapasztalat nélkül"/>
                    <meta property="og:title" content="AD Menedzser | Termékek | SzuniSOFT"/>
                    <meta property="og:description" content="Windows szerver felhasználóinak kezelése gyorsan, egyszerűen, biztonságosan mobil optimalizált webes környezetben. Rendszergazdai tapasztalat nélkül"/>
                    <meta property="og:image" content={DynamicLocation.build(PreloadImages[0].src)}/>
                </Helmet>

                <ProductTheme siteThemeId={css['ad-manager-site-theme']}
                              productThemeId={css['ad-manager-product-theme']}
                >
                    <ScrollToTopOnMount/>

                    <ImageLoader multiple={PreloadImages}
                                 loading={
                                     <AnimatedLoader/>
                                 }
                                 loaded={
                                     <div className={extractClassNames('d-flex flex-grow-1 flex-column', appCss)}>
                                         <Heading heading="Active Directory Menedzser"
                                                  breadCrumb={[
                                                      {text: 'Termékek', to: '/termekek'},
                                                      'AD Menedzser'
                                                  ]}
                                         />
                                         <div className={extractClassNames('container-fluid p-0 flex-grow-1', appCss)}>

                                             <Section themeCss={css}
                                                      layout={"column"}
                                             >

                                                 <Parallax bgImage={PreloadImages[0].src}
                                                           bgImageSrcSet={PreloadImages[0].srcSet}
                                                           strength={500}
                                                 >
                                                     <div style={{
                                                         height: '100%',
                                                         minHeight: 'calc(100vh - 121px - 80px)',
                                                         paddingBottom:  '40vh'
                                                     }}>

                                                         <div
                                                             className={extractClassNames('d-flex flex-column mb-5', appCss)}>
                                                             <div className={appCss['bg-white']}>
                                                                 <div className={appCss['container']}>
                                                                     <ToggleAnimation animationClassName="slide-top"
                                                                                      togglePercent={10}>
                                                                         <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Mi
                                                                             az AD
                                                                             Menedzser?</h5>
                                                                     </ToggleAnimation>

                                                                     <ToggleAnimation animationClassName="slide-bottom"
                                                                                      togglePercent={10}>
                                                                         <p className={css['theme']}>
                                                                             Az AD Manager egy web alapú szoftver, mely
                                                                             célja szerint
                                                                             Active
                                                                             Directory környezetek egyszerű
                                                                             felhasználókezelését
                                                                             valósítja meg
                                                                             biztonságos módon. Segítségével nem
                                                                             informatikus,
                                                                             rendszergazdai
                                                                             ismeretekkel nem rendelkező dolgozókra is
                                                                             kioszthatjuk a
                                                                             sablon
                                                                             alapú felhasználó-létrehozás, a user
                                                                             lekapcsolás, a jelszó
                                                                             visszaállítás és még néhány alapművelet
                                                                             végrehajtását.
                                                                         </p>
                                                                     </ToggleAnimation>
                                                                 </div>
                                                             </div>
                                                         </div>

                                                     </div>
                                                 </Parallax>

                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"first-right-last-left"}
                                                      screenHeightOnLarge
                                                      theme
                                             >

                                                 <ToggleAnimation
                                                     className={extractClassNames('w-100 h-100 order-last order-lg-first', appCss)}
                                                     animationClassName={extractClassNames('slide-right', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: `url(${PreloadImages[1].src}) no-repeat left center`,
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>

                                                 <div
                                                     className={extractClassNames('d-flex order-first order-lg-last align-items-center', appCss)}>
                                                     <div className={appCss['container']}>
                                                         <ToggleAnimation animationClassName="slide-left"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Könnyű
                                                                 használhatóság és költséghatékonyság</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>Az
                                                                 egyszerű webes felület biztosítja, hogy a nem
                                                                 informatikus kollégák könnyen, magabiztosan oldják meg
                                                                 a felhasználókezelések leggyakoribb műveleteit. Ezzel
                                                                 tehermentesítve a rendszergazdákat csökkenthető a
                                                                 kényszerműveletek által okozott, nem produktív
                                                                 termelések költsége.
                                                             </p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"first-left-last-right"}
                                                      screenHeightOnLarge>

                                                 <ToggleAnimation
                                                     className={extractClassNames('w-100 h-100 order-last order-lg-first', appCss)}
                                                     animationClassName={extractClassNames('slide-left', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: `url(${PreloadImages[2].src}) no-repeat center`,
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>

                                                 <div
                                                     className={extractClassNames('d-flex order-first order-lg-last align-items-center', appCss)}>
                                                     <div className={appCss['container']}>
                                                         <ToggleAnimation animationClassName="slide-right"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Felhasználási
                                                                 területek</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>Rengeteg
                                                                 területen fordul elő, hogy a hálózatot használók
                                                                 személye gyakran változik. Iskolák, oktatási központok,
                                                                 coworking centerek, stb. E változásokat alapvetően a
                                                                 recepció, titkárság vagy HR osztály vezeti be, de a
                                                                 felhasználók létrehozására vagy kikapcsolására
                                                                 rendszergazdai segítségre van szükség. Az AD Manager
                                                                 lehetővé teszi, hogy az előre definiált profilok
                                                                 alapján a nem informatikai osztályok dolgozói könnyen,
                                                                 biztonságosan, rendszergazdai ismeretek nélkül
                                                                 kezelhessék a felhasználókat.
                                                             </p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css} inverse layout={"column"}>
                                                 <Parallax bgImage={PreloadImages[3].src}
                                                           bgImageSrcSet={PreloadImages[3].srcSet}
                                                           strength={500}
                                                 >
                                                     <div style={ParallaxStyle}>
                                                         <h5 className={css['theme'] + ' ' + appCss['text-center']}>Biztonság</h5>
                                                     </div>
                                                 </Parallax>

                                                 <ToggleAnimation togglePercent={10} animationClassName="slide-bottom">
                                                     <div className={extractClassNames('container pt-3', appCss)}>
                                                         <p className={css['theme']}>A helyi Active Directoryval
                                                             együttműködő rendszer a Domain Controllerrel azonos zónába
                                                             telepíthető, minimalizálva ezzel a csomagokban közlekedő
                                                             jelszavak kockázatát. A weboldal természetesen titkosított
                                                             protokollokkal kommunikál a DC-vel, így a távoli szerverre
                                                             történő telepítése sem okoz jelentősebb kockázatot.</p>
                                                     </div>
                                                 </ToggleAnimation>
                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"first-right-last-left"}
                                                      theme
                                                      screenHeightOnLarge>

                                                 <ToggleAnimation
                                                     className={extractClassNames('w-100 h-100 order-last order-lg-first', appCss)}
                                                     animationClassName={extractClassNames('slide-right', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: `url(${PreloadImages[4].src}) no-repeat left center`,
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>

                                                 <div
                                                     className={extractClassNames('d-flex order-first order-lg-last align-items-center', appCss)}>
                                                     <div className={appCss['container']}>
                                                         <ToggleAnimation animationClassName="slide-left"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Bevezetés
                                                                 és használatba vétel</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>A
                                                                 szoftver a legteljesebb mértékben az ügyfél egyedi
                                                                 igényeire szabható. A bevezetés során szakértői
                                                                 támogatást nyújtunk a szükséges szerver oldali
                                                                 megoldások telepítésében, és a funkciók beállításában,
                                                                 testre szabásában.
                                                             </p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <div className={appCss['container']}>
                                                 <div>
                                                     <h4 className={extractClassNames('my-5 text-center', appCss)}>Kezelje
                                                         felhasználóit már holnap egyszerűen!</h4>
                                                 </div>
                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-bottom row', appCss)}
                                                     togglePercent={10}>
                                                     <div
                                                         className={extractClassNames('col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3', appCss)}>
                                                         <div id="contactCard" className={appCss['mb-5']}>
                                                             <ContactForm/>
                                                         </div>
                                                     </div>
                                                 </ToggleAnimation>
                                             </div>

                                         </div>
                                     </div>
                                 }
                    />
                </ProductTheme>
            </LoadGently>
        );
    }

}

ADManager.propTypes = {};

export default ADManager;
