class Validator {
    priority = 1;
    validate = (v) => {
    }
}

class Email extends Validator {
    priority = 1;
    validate = (v) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(v).toLowerCase())) {
            return "Hibás e-email cím!";
        }
    };
}

class Required extends Validator {
    priority = 2;
    validate = (v) => {
        if (!v.trim().length > 0) {
            return "Kötelező mező!";
        }
    };
}

class MaxLength extends Validator {

    priority = 2;
    max = null;

    constructor(max) {
        super();
        this.max = max;
    }

    validate = (v) => {
        if (v.trim().length > this.max) {
            return "Legfeljebb " + this.max + " karakter hosszú lehet!";
        }
    };

}

class MinLength extends Validator {

    priority = 2;
    min = null;

    constructor(min) {
        super();
        this.min = min;
    }

    validate = (v) => {
        if (v.trim().length < this.min) {
            return "Legalább " + this.min + " karakter hosszú kell legyen!";
        }
    };

}

export {Email, Required, MinLength, MaxLength}
export default Validator
