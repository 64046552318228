import type {ArticleListItemT, ArticleT, LinksT, LoadNewsParams, MetaT} from "./types";
import axios, {CancelTokenSource} from "axios";
import {addPreloader} from "../server/actions";

export const NewsActionTypes = {
    SET_ARTICLES: 'news/set-articles',
    SET_LINKS: 'news/set-links',
    SET_META: 'news/set-meta',
    SET_NEWS: 'news/set-news',
    SET_ARTICLE: 'news/set-article',
    SET_NEWS_CANCELLATION_SOURCE: 'news/set-news-cancel-token',
    CANCEL_NEWS_SOURCE: 'news/cancel-news-loading',
    ADD_ARTICLE_CANCELLATION_SOURCE: 'news/add-article-cancellation-source',
    CANCEL_ARTICLE_SOURCE: 'news/cancel-article-source'
};

export const loadNews = (params: LoadNewsParams) => (dispatch) => {

    // Cancel any previous related request.
    dispatch(cancelNewsSource());

    // Create cancellation token
    const source = axios.CancelToken.source();

    dispatch(setNewsCancellationSource(source));

    params = {
        page: 1,
        count: 10,
        order: 'desc',
        ...params
    };

    const request = axios.get(`${API_HOST}/api/article`, {params, cancelToken: source.token});

    request
        .then(response => {
            dispatch(setArticles(response.data.data));
            dispatch(setLinks(response.data.links));
            dispatch(setMeta(response.data.meta));
            dispatch(cancelNewsSource());
        })
        .catch(e => {

            // Clear only when no next upcoming request is running currently.
            if (!axios.isCancel(e)) {
                dispatch(cancelNewsSource())
            }
        });

    dispatch(addPreloader(request));
};

export const loadArticle = (articleId: number) => (dispatch) => {

    // Create cancellation token
    const source = axios.CancelToken.source();

    dispatch(addArticleCancellationSource(articleId, source));

    const request = axios.get(`${API_HOST}/api/article/${articleId}`, {
        cancelToken: source.token
    });

    request
    // Store returned data.
        .then(response => dispatch(setArticle(response.data.data)))
        // Mark article non existent
        .catch(e => dispatch(setArticle(false)))
        // Clear cancellation source.
        .finally(() => dispatch(cancelArticleSource(articleId)));

    dispatch(addPreloader(request));
};

export const setArticles = (articles: ArticleListItemT) => ({
    type: NewsActionTypes.SET_ARTICLES,
    payload: articles
});

export const setLinks = (links: LinksT) => ({
    type: NewsActionTypes.SET_LINKS,
    payload: links
});

export const setMeta = (meta: MetaT) => ({
    type: NewsActionTypes.SET_META,
    payload: meta
});

export const setArticle = (article: ArticleT) => ({
    type: NewsActionTypes.SET_ARTICLE,
    payload: article
});

export const setNewsCancellationSource = (source: CancelTokenSource) => ({
    type: NewsActionTypes.SET_NEWS_CANCELLATION_SOURCE,
    payload: source
});

export const cancelNewsSource = () => ({
    type: NewsActionTypes.CANCEL_NEWS_SOURCE
});

export const addArticleCancellationSource = (id: number, source: CancelTokenSource) => ({
    type: NewsActionTypes.ADD_ARTICLE_CANCELLATION_SOURCE,
    payload: {id, source}
});

export const cancelArticleSource = (id: number) => ({
    type: NewsActionTypes.CANCEL_ARTICLE_SOURCE,
    payload: id
});
