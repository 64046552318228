import React, {Component} from 'react';
import {Link} from "react-router-dom";
import css from "./Products.module.scss";
import appCss from "../../App.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faCode, faDesktop, faGlobe, faMobile} from "@fortawesome/free-solid-svg-icons";
import Heading from "../../layout/Heading";
import ScrollToTopOnMount from "../../helpers/ScrollToTopOnMount";
import Ticketing from "./Ticketing";
import {extractClassNames} from "../../helpers/ExtractClassNames";
import LoaderParallax from "../../layout/LoaderParallax";
import LoadGently from "../../layout/LoadGently";
import {Helmet} from "react-helmet";
import {DynamicLocation} from "../../helpers/DynamicLocation";

const List = [

    {
        name: "CRM",
        uri: "crm",
        className: 'crm',
        subTitle: "Customer Relation Manager",
        intro: (
            <React.Fragment>
                Maga a rövidítés az angol <b><i>Customer Relationship Manager</i></b> szavakból ered, bár komplexebb
                szoftverek esetében már
                megfelelőbb a <b><i>Company Relationship Manager</i></b> kifejezés.
                Wikipédia meghatározása szerint: "Az ügyfélkapcsolat-kezelés fogalma egy cég saját partnerei felé
                irányuló folyamatainak leírására vonatkozik. Egy CRM szoftver célja, hogy ezeket a folyamatokat
                támogassa, illetve a jelenlegi és potenciális ügyfelekkel kapcsolatos információkat tárolja"
            </React.Fragment>
        ),
        tags: ["web", "mobil"],
        thumbnail: require('./assets/crm.jpg')
    },
    {
        name: "CSP",
        uri: "csomopont",
        className: 'csp',
        subTitle: "Csomópont",
        intro: (
            <React.Fragment>
                Beszállító termékeinek belistázása, termékhez beszállító kiválasztása, beszállítói ár- vagy
                készletváltozások követése, cikktörzs karbantartás, árképzés. Egy kereskedői termékmenedzser mindennapi
                feladatai. Időrabló, unalmas, végtelen robotmunka. Elkészítettük hát azt a könnyen bővíthető, testre
                szabható, bármilyen környezetek közé beilleszthető szoftvert, mely valós időben képes kommunikálni a
                saját kereskedelmi rendszerek (webshop, ERP) és a beszállítói terméktörzsek, készletadatbázisok között.
                Végrehajtja a szükséges szinkronizációkat, ellenőrzéseket, elkészíti a szükséges riportokat, gondoskodik
                az emberi beavatkozást igénylő esetekben az azonnali értesítéséről.
            </React.Fragment>
        ),
        tags: ["web"],
        thumbnail: require('./CSP/assets/section-1.jpg')
    },
    {
        name: "Quick Inventory",
        uri: "gyorsleltar",
        className: 'quick-inventory',
        subTitle: "Gyorsleltár",
        intro: (
            <React.Fragment>
                A nagy raktárakkal és
                termékszámokkal rendelkező cégeknél természetes, hogy viszonylag sokat áldoznak
                komplex raktárkezelő és leltározó szoftverekre, valamint ipari kézi
                olvasókra. A Gyorsleltár mobilalkalmazás segítségével nem szükséges drága kézi olvasókat és komplex
                szoftvereket vásárolnunk. A zsebünkben lévő telefon kamerájának segítségével szupergyorssá tehető a
                leltárazás folyamata!
            </React.Fragment>
        ),
        tags: ["mobil"],
        thumbnail: require('./assets/quick-inventory.jpg')
    },
    {
        name: "Ticketing",
        uri: "hibajegykezelo",
        className: 'ticketing',
        subTitle: "Hibajegykezelő",
        intro: (
            <React.Fragment>
                Olyan kommunikációs platform, amely a cégek működésének fontos és szerves része. Mind külső és belső
                működésbeli kommunikációs csatorna, mely képes feladatok kezelésére, ütemezésére és nyomon követésére.
                Segítségével nem csak egyszerűbb a feladatok felosztása, de visszatekintés szempontjából is fontos
                funkciókat kínál.
            </React.Fragment>
        ),
        tags: ["web", "mobil"],
        thumbnail: require('./Ticketing/assets/section-6.jpg')
    },
    {
        name: "A.D. Manager",
        uri: "active-directory-menedzser",
        className: 'ad-manager',
        subTitle: "Active Directory menedzser",
        intro: (
            <React.Fragment>
                Az AD Manager egy web alapú szoftver, mely célja szerint <b>Active Directory</b> környezetek egyszerű
                felhasználókezelését valósítja meg biztonságos módon. Segítségével nem informatikus, rendszergazdai
                ismeretekkel nem rendelkező dolgozókra is kioszthatjuk a sablon alapú felhasználó-létrehozás, a user
                lekapcsolás, a jelszó visszaállítás és még néhány alapművelet végrehajtását.
            </React.Fragment>
        ),
        tags: ["web", "asztali"],
        thumbnail: require('./ADManager/assets/section-1.jpg')
    },
    {
        name: "Catering Manager",
        uri: "kozetkeztetes",
        className: 'catering-manager',
        subTitle: "Közétkeztetés",
        intro: (
            <React.Fragment>
                Egy termék amely az iskolai vagy intézményi étkeztetésre ad egy remek megoldást. Leegyszerűsíti az
                elérhető ételeket feltöltő és az ételeket rendelő dolgát is. Csökkenti az adminisztrációs időt és a
                humán hibafaktort. Lehetőséget kínál bankkártyás fizetésre és komplex menü összeállításra is.
            </React.Fragment>
        ),
        tags: ["web"],
        thumbnail: require('./assets/catering-manager.jpg')
    }
];

const parallaxStyle = {
    height: '40vw',
    minHeight: '400px'
};

class Products extends Component {

    render() {
        return (
            <React.Fragment>

                <Helmet>
                    <title>Termékek | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Termék,Egyedi,Szoftver,Megoldás,Web,Mobil,Költséghatékony,Vásárlás,Ajánlatkérés"/>
                    <meta name="description"
                          content="Nézze végig termékeinket, amelyek, valós piaci igényekre adnak megoldást. Így kiszolgálva a különféle felületeket és területeket."/>
                    <meta property="og:title" content="Termékek | SzuniSOFT"/>
                    <meta property="og:description" content="Nézze végig termékeinket, amelyek, valós piaci igényekre adnak megoldást. Így kiszolgálva a különféle felületeket és területeket."/>
                    <meta property="og:image" content={DynamicLocation.build(require('../../assets/logo/Powered by Logo-100.jpg?size=600').src)}/>
                </Helmet>

                <div id={css['products']} className={extractClassNames('d-flex flex-grow-1', appCss)}>
                    <ScrollToTopOnMount/>
                    <LoadGently>
                        <Heading heading="Termékek" icon={faCode}/>
                        <div className={appCss['row']}>
                            {
                                List.map(product =>
                                    <div key={product.name}
                                         className={extractClassNames('col-12', appCss) + ' ' + css['product-wrapper']}>
                                        <div className={css['product'] + ' ' + css[product.className]}>

                                            <LoaderParallax src={product.thumbnail.src}
                                                            srcSet={product.thumbnail.srcSet}
                                                            style={parallaxStyle}
                                                            parallaxStrength={500}
                                                            parallaxBgClassName={"fade in"}
                                                            loaderClassName={appCss['bg-dark']}
                                            >
                                                <div className={css['heading-wrapper']} style={parallaxStyle}>
                                                    <div className={css['heading']}>
                                                        <div className={extractClassNames('container py-3', appCss)}>
                                                            <h1 className={css['name']}>{product.name}</h1>
                                                            <h2 className={extractClassNames('font-italic font-weight-light', appCss)}>{product.subTitle}</h2>
                                                        </div>
                                                    </div>

                                                    <div className={css['platforms'] + ' ' + appCss['flex-grow-1']}>

                                                        {
                                                            product.tags.map(t => {
                                                                switch (t) {
                                                                    case 'asztali':
                                                                        return <FontAwesomeIcon icon={faDesktop}
                                                                                                size={"4x"}/>;
                                                                    case 'web':
                                                                        return <FontAwesomeIcon icon={faGlobe}
                                                                                                size={"4x"}/>;
                                                                    case 'mobil':
                                                                        return <FontAwesomeIcon icon={faMobile}
                                                                                                size={"4x"}/>;
                                                                }
                                                            })
                                                                .map((t, i) => <div key={i}
                                                                                    className={css['platform']}>{t}</div>)
                                                        }

                                                    </div>
                                                </div>

                                            </LoaderParallax>

                                            <div
                                                className={extractClassNames('d-flex pb-5 flex-column', appCss) + ' ' + css['content-wrapper']}>
                                                <div className={extractClassNames('flex-grow-0 container', appCss)}>
                                                    <div className={css['tags']}>{
                                                        product.tags.map(t => <small key={t}>{t}</small>)
                                                    }</div>
                                                </div>

                                                <div
                                                    className={extractClassNames('flex-grow-0 container pt-3 flex-grow-1 d-flex align-items-center justify-content-center flex-column', appCss)}>

                                                    <p className={css['intro']}>
                                                        {product.intro}
                                                    </p>

                                                </div>

                                                <div
                                                    className={extractClassNames('py-3 flex-grow-1 d-flex align-items-center justify-content-center button-perspective', appCss)}>
                                                    <Link to={`/termekek/${product.uri}`}
                                                          className={extractClassNames('btn p-3 m-0', appCss) + ' ' + css['link']}
                                                    >
                                                        <span>bővebben</span>
                                                        <FontAwesomeIcon icon={faChevronRight}/>
                                                    </Link>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                )
                            }
                        </div>
                    </LoadGently>
                </div>
            </React.Fragment>
        );
    }
}

Products.propTypes = {};

export default Products;
