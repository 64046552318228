import {IPromise} from "q";

export const ServerActionTypes = {
    SET_REQUEST: 'server/set-request',
    ADD_PRELOADER: 'server/add-preloader',
};

export const setRequest = (req: Object) => ({
    type: ServerActionTypes.SET_REQUEST,
    payload: req
});

export const addPreloader = (preloader: IPromise) => ({
    type: ServerActionTypes.ADD_PRELOADER,
    payload: preloader
});
