import React from 'react';
import css from "./QuickInventory.module.scss";
import appCss from "../../../App.module.scss";
import ProductTheme from "../ProductTheme";
import ScrollToTopOnMount from "../../../helpers/ScrollToTopOnMount";
import {extractClassNames} from "../../../helpers/ExtractClassNames";
import Heading from "../../../layout/Heading";
import ToggleAnimation from "../../../shared/ToggleAnimation";
import {Section} from "../Product";
import ImageLoader from "../../../shared/ImageLoader";
import AnimatedLoader from "../../../shared/AnimatedLoader";
import LoadGently from "../../../layout/LoadGently";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAppStoreIos, faGooglePlay} from "@fortawesome/free-brands-svg-icons";
import {faClock} from "@fortawesome/free-regular-svg-icons";
import {Helmet} from "react-helmet";
import {Parallax} from "react-parallax";
import {DynamicLocation} from "../../../helpers/DynamicLocation";

const Section1Image = require('./assets/figure.jpg?size=800');
const Section2Image = require('./assets/in-app-1.jpg');
const Section3Image = require('./assets/in-app-2.jpg');
const Section4Image = require('./assets/in-app-3.jpg');
const Section5Image = require('./assets/logo.png?size=550');

const PreloadImages = [
    Section1Image,
    Section2Image,
    Section3Image,
    Section4Image,
    Section5Image,
];

const Platforms = () => (
    <div
        className={extractClassNames('container d-flex', appCss) + ' ' + css['platforms']}>

        <div>
            <a href="https://play.google.com/store/apps/details?id=hu.szunisoft.quickinventory" target="_blank"
               className={appCss['text-android']}>
                <h5>Google Play</h5>
                <div>
                    <FontAwesomeIcon icon={faGooglePlay} size={"4x"}/>
                </div>
            </a>
        </div>

        <div>
            <a href="#"
               className={appCss['text-apple-app-store'] + ' ' + css['soon']}>
                <h5>
                    App Store
                    <FontAwesomeIcon icon={faClock} className={appCss['ml-2']}/>
                </h5>
                <div>
                    <FontAwesomeIcon icon={faAppStoreIos} size={"4x"}/>
                </div>
            </a>
        </div>

    </div>
);

class QuickInventory extends React.PureComponent {

    render() {

        return (
            <LoadGently>

                <Helmet>
                    <title>Gyorsleltár | Termékek | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Gyorsleltár,QuickInvetory,Leltár,Raktár,Mobil,Applikáció,Kamera,Vonalkód,QR,Küldés"/>
                    <meta name="description"
                          content="Mobil alkalmazás amely segíti a kézi leltárazást, gyors átlátható módon megoldani a végén pedig elküldheti a kiválasztott email címre."/>
                    <meta property="og:title" content="Gyorsleltár | Termékek | SzuniSOFT"/>
                    <meta property="og:description" content="Mobil alkalmazás amely segíti a kézi leltárazást, gyors átlátható módon megoldani a végén pedig elküldheti a kiválasztott email címre."/>
                    <meta property="og:image" content={DynamicLocation.build(Section5Image.src)}/>
                </Helmet>

                <ProductTheme productThemeId={css['quick-inventory-product-theme']}
                              siteThemeId={css['quick-inventory-site-theme']}>
                    <ScrollToTopOnMount/>

                    <ImageLoader multiple={PreloadImages}
                                 loading={<AnimatedLoader/>}
                                 loaded={
                                     <div className={extractClassNames("d-flex flex-grow-1 flex-column", appCss)}>
                                         <Heading heading="Quick Inventory"
                                                  breadCrumb={[
                                                      {text: 'Termékek', to : '/termekek'},
                                                      'Gyorsleltár'
                                                  ]}
                                         />
                                         <div className={extractClassNames('container-fluid p-0 flex-grow-1', appCss)}>

                                             <Section themeCss={css}
                                                      screenHeightOnLarge>

                                                 <Parallax bgImage={require('../assets/quick-inventory.jpg').src}
                                                           bgImageSrcSet={require('../assets/quick-inventory.jpg').srcSet}
                                                           strength={500}
                                                 >
                                                     <div id={css['firstParallax']} style={{minHeight: '100vh'}} className={extractClassNames('d-flex flex-grow', appCss)}>
                                                         <div className={appCss['container'] + ' ' + extractClassNames('container flex-grow-1 d-flex justify-content-center flex-column', appCss)}>
                                                             <div className={appCss['flex-grow-1']}/>

                                                             <ToggleAnimation animationClassName="slide-top" togglePercent={10}>
                                                                 <h5 className={appCss['flex-grow-0'] + ' ' + css['theme']}>Kit
                                                                     céloz a
                                                                     szoftver?</h5>
                                                             </ToggleAnimation>
                                                             <ToggleAnimation animationClassName="slide-bottom"
                                                                              togglePercent={10}>
                                                                 <p className={appCss['flex-grow-0'] + ' ' + css['theme']}> A
                                                                     nagy raktárakkal és
                                                                     termékszámokkal rendelkező cégeknél természetes, hogy
                                                                     viszonylag sokat áldoznak
                                                                     komplex raktárkezelő és leltározó szoftverekre valamint
                                                                     ipari kézi
                                                                     olvasókra.</p>
                                                             </ToggleAnimation>

                                                             <Platforms/>

                                                             <div className={appCss['flex-grow-1']}/>
                                                         </div>
                                                     </div>
                                                 </Parallax>

                                             </Section>

                                             <Section themeCss={css}
                                                      screenHeightOnLarge
                                                      layout={"first-right-last-left"}>
                                                 <div
                                                     className={extractClassNames('d-flex align-items-center justify-content-center', appCss)}>
                                                     <ToggleAnimation
                                                         animationClassName={extractClassNames('slide-right pb-5 pb-lg-0', appCss) + ' ' + css['card-image']}
                                                         togglePercent={10}>
                                                         <img src={PreloadImages[1].src}
                                                              srcSet={PreloadImages[1].srcSet} alt="Illustration #1"/>
                                                     </ToggleAnimation>
                                                 </div>
                                                 <div
                                                     className={extractClassNames('d-flex flex-column align-items-center justify-content-center order-first order-lg-last', appCss)}>
                                                     <div className={appCss['container']}>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-left">
                                                             <h5 className={css['theme']}>De milyen lehetőségei maradnak
                                                                 egy nem termékekkel
                                                                 foglalkozó cégnek a leltározásra?</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-bottom">
                                                             <p className={css['theme']}>Kézi leltározás, amely abban
                                                                 merül ki, hogy akár
                                                                 több ember, a cég méretétől függően akár sok-sok órán
                                                                 keresztül végig járja
                                                                 az irodai helyiségeket, és papír alapon vagy
                                                                 táblázatkezelő szoftver
                                                                 segítségével felír minden egyes vonalkódot, vagy egyéb
                                                                 azonosítót. </p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}
                                                      screenHeightOnLarge
                                                      layout={"first-left-last-right"}>
                                                 <div
                                                     className={extractClassNames('order-2 order-lg-1 d-flex align-items-center justify-content-center', appCss)}>
                                                     <ToggleAnimation
                                                         animationClassName={extractClassNames('slide-left', appCss)}
                                                         togglePercent={10}>
                                                         <div
                                                             className={css['card-image'] + ' ' + extractClassNames('py-5 py-lg-0', appCss)}>
                                                             <img src={PreloadImages[2].src}
                                                                  srcSet={PreloadImages[2].srcSet}
                                                                  alt="Illustration #2"/>
                                                         </div>
                                                     </ToggleAnimation>
                                                 </div>
                                                 <div
                                                     className={extractClassNames('order-1 order-lg-2 d-flex flex-column align-items-center justify-content-center', appCss)}>
                                                     <div className={appCss['container']}>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-right">
                                                             <h5 className={css['theme']}>A megoldás ott lapul már
                                                                 mindannyiunk zsebében,
                                                                 csak a
                                                                 megfelelő app kell hozzá!</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-bottom">
                                                             <p className={css['theme']}>Miért ne használnánk az
                                                                 okostelefonunk kameráját
                                                                 vonal,
                                                                 illetve QR kód olvasónak, amihez egy drága szoftver
                                                                 helyett csak egy
                                                                 alkalmazás
                                                                 is elég?</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}
                                                      screenHeightOnLarge
                                                      inverse
                                                      className={extractClassNames('d-flex flex-column overflow-hidden', appCss)}
                                             >
                                                 <div className={extractClassNames('flex-grow-1', appCss)}/>
                                                 <div
                                                     className={extractClassNames('container flex-grow-1 pb-3 pt-5', appCss)}>
                                                     <ToggleAnimation animationClassName="slide-top" togglePercent={10}>
                                                         <h5 className={css['theme'] + ' ' + appCss['text-center']}>A mi
                                                             megoldásunk pontosan
                                                             egy ilyen app...</h5>
                                                     </ToggleAnimation>
                                                 </div>
                                                 <div
                                                     className={extractClassNames('container text-center flex-grow-1 pb-3 d-flex align-items-center justify-content-center flex-column', appCss)}>
                                                     <ToggleAnimation animationClassName="scale-center"
                                                                      togglePercent={25}>
                                                         <h1 className={css['theme']}>Gyorsleltár</h1>
                                                         <img src={PreloadImages[4].src}
                                                              srcSet={PreloadImages[4].srcSet} alt="Section image"
                                                              className={appCss['img-fluid'] + ' ' + css['logo']}/>
                                                     </ToggleAnimation>
                                                 </div>
                                                 <div className={extractClassNames('flex-grow-1', appCss)}/>
                                                 <div className={appCss['container']}>
                                                     <ToggleAnimation animationClassName="slide-bottom"
                                                                      togglePercent={15}>
                                                         <p className={css['theme']}>A Gyorsleltár elsősorban egy
                                                             eszközkészlet felmérő és
                                                             csoportosító mobil
                                                             alkalmazás, amelyhez olyan cégek és intézmények adták az
                                                             ihletet, akik nem
                                                             termékekkel foglalkoznak, így nem szeretnének beruházni
                                                             drága szoftverekre vagy
                                                             vonalkódolvasókra, de szeretnének valamilyen informatikai
                                                             segítséget
                                                             leltározáskor. Ilyen típusú intézményre remek példa az
                                                             iskola, ahol már
                                                             bizonyított a szoftver és a visszajelzés is pozitív
                                                             volt.</p>
                                                     </ToggleAnimation>
                                                 </div>
                                                 <div className={extractClassNames('flex-grow-1', appCss)}/>
                                             </Section>

                                             <Section themeCss={css}
                                                      theme
                                                      screenHeightOnLarge
                                                      layout={"first-right-last-left"}>
                                                 <div
                                                     className={extractClassNames('d-flex align-items-center justify-content-center', appCss)}>
                                                     <ToggleAnimation
                                                         animationClassName={extractClassNames('slide-right pb-5 pb-lg-0', appCss) + ' ' + css['card-image']}
                                                         togglePercent={10}>
                                                         <img src={PreloadImages[3].src}
                                                              srcSet={PreloadImages[3].srcSet}
                                                              alt="Illustration #3"
                                                              className={css.inverted}
                                                         />
                                                     </ToggleAnimation>
                                                 </div>
                                                 <div
                                                     className={extractClassNames('d-flex flex-column align-items-center justify-content-center order-first order-lg-last', appCss)}>
                                                     <div className={appCss['container']}>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-left">
                                                             <h5 className={css['theme']}>Egyszerűség!</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-bottom">
                                                             <p className={css['theme']}>Mivel nem a komplex
                                                                 raktárkezelő szoftverek piacára
                                                                 készült, így a felhasználói felülete egyszerű
                                                                 maradhatott. Első használatkor
                                                                 vezeti a felhasználót a navigálásban és a leltározás
                                                                 menetében.</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}
                                                      screenHeightOnLarge
                                                      layout={"first-left-last-right"}>
                                                 <div
                                                     className={extractClassNames('d-flex align-items-center justify-content-center', appCss)}>
                                                     <ToggleAnimation
                                                         animationClassName={extractClassNames('slide-left pb-5 pb-lg-0', appCss) + ' ' + css['card-image']}
                                                         togglePercent={10}>
                                                         <img src={require('./assets/in-app-4.jpg')}
                                                              alt="Illustration #3"/>
                                                     </ToggleAnimation>
                                                 </div>
                                                 <div
                                                     className={extractClassNames('d-flex flex-column align-items-center justify-content-center order-first order-lg-last', appCss)}>
                                                     <div className={appCss['container']}>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-right">
                                                             <h5 className={css['theme']}>Univerzálisan felhasználható
                                                                 és könnyen kiküldhető
                                                                 kimenet!</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-bottom">
                                                             <p className={css['theme']}>Az elkészült leltárt e-mail
                                                                 formájában kiküldi
                                                                 táblázatos formában, valamit csatol egy Excel programba
                                                                 is betölthető xml
                                                                 állományt.</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"column"}
                                                      className={css['bg-theme-dark']}
                                                      inverse
                                             >
                                                 <div
                                                     className={css['bg-theme-dark'] + ' ' + extractClassNames('text-center overflow-hidden', appCss)}>
                                                     <ToggleAnimation togglePercent={10}
                                                                      animationClassName="slide-bottom">
                                                         <img src={PreloadImages[0].src}
                                                              srcSet={PreloadImages[0].srcSet} alt="Figure"
                                                              style={{width: '50%', maxWidth: '800px'}}/>
                                                     </ToggleAnimation>
                                                 </div>
                                                 <div
                                                     className={extractClassNames('d-flex py-5 flex-grow-1 flex-column align-items-center justify-content-center', appCss)}>
                                                     <div className={appCss['container']}>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-right">
                                                             <h5 className={css['theme']}>Költséghatékony!</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-bottom">
                                                             <p className={css['theme']}>Elég csak egyszer megvenni az
                                                                 alkalmazás áruházból,
                                                                 és ezzel minden költséget le is tudtunk.</p>
                                                         </ToggleAnimation>

                                                         <div className={appCss['mb-5']}>
                                                             <Platforms/>
                                                         </div>

                                                     </div>
                                                 </div>
                                             </Section>


                                         </div>
                                     </div>
                                 }
                    />

                </ProductTheme>

            </LoadGently>
        );
    }
}

QuickInventory.propTypes = {};

export default QuickInventory;
