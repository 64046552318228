import { faDesktop } from "@fortawesome/free-solid-svg-icons";
import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import appCss from "../../../../App.module.scss";
import { DynamicLocation } from "../../../../helpers/DynamicLocation";
import { extractClassNames } from "../../../../helpers/ExtractClassNames";
import ScrollToTopOnMount from "../../../../helpers/ScrollToTopOnMount";
import Heading from "../../../../layout/Heading";
import LoaderParallax from "../../../../layout/LoaderParallax";
import LoadGently from "../../../../layout/LoadGently";
import ContactForm from "../../../../shared/ContactForm";
import ToggleAnimation from "../../../../shared/ToggleAnimation";
import serviceCss from "../../Services.module.scss";
import { Platforms } from "../index";
import softwareCss from "../Software.module.scss";
import css from "../Web/Web.module.scss";

const parallaxStyle = {
    height: '30vw',
    minHeight: '400px'
};

const DekstopOgImage = require('./assets/section-1.jpg');

class Desktop extends Component {
    render() {
        return (
            <div>
                <React.Fragment>

                    <Helmet>
                        <title>Asztali fejlesztés | Szoftverfejlesztés | SzuniSOFT</title>
                        <meta name="keywords"
                              content="Asztali,Alkalmazás,Teljesítmény,Individuális,Hatékony,Felhasználóbarát,Offline,Windows,Gyors,Célirányos"/>
                        <meta name="description"
                              content="Kifejezetten célirányos és indokolt projektek esetében javasoljuk az asztali alkalmazásokat. Jellemzően akkor lehet szükség, mikor kénytelenek vagyunk kilépni a böngészők korlátaiból."/>
                        <meta property="og:title" content="Asztali fejlesztés | Szoftverfejlesztés | SzuniSOFT"/>
                        <meta property="og:description"
                              content="Kifejezetten célirányos és indokolt projektek esetében javasoljuk az asztali alkalmazásokat. Jellemzően akkor lehet szükség, mikor kénytelenek vagyunk kilépni a böngészők korlátaiból."/>
                        <meta property="og:image" content={DynamicLocation.build(DekstopOgImage.src)}/>
                    </Helmet>

                    <LoadGently className={appCss['flex-grow-1']}>
                        <ScrollToTopOnMount/>
                        <Heading icon={faDesktop}
                                 heading="Asztali alkalmazás"
                                 breadCrumb={[
                                     {text: 'Szolgáltatások', to: '/szolgaltatasok'},
                                     {text: 'Szoftverfejlesztés', to: '/szolgaltatasok/szoftverfejlesztes'},
                                     'Asztali alkalmazás'
                                 ]}
                        />
                        <div
                            className={extractClassNames("d-flex flex-column flex-grow-1", appCss) + ' ' + css['web'] + ' ' + softwareCss['software'] + ' ' + serviceCss['service']}>

                            <div>

                                <LoaderParallax {...require("./assets/section-1.jpg")}
                                                style={parallaxStyle}
                                                loaderInverse
                                                parallaxStrength={500}
                                                parallaxBgClassName={"fade in"}
                                                parallaxBgImageStyle={{marginTop: '-10%'}}
                                />

                                <section className={extractClassNames('container py-5', appCss)}>
                                    <h2>Bevezető</h2>
                                    <p className={serviceCss['subheading']}>Legyen közelebb a felhasználóhoz!</p>

                                    <p>Asztali alkalmazásokra jellemzően egyre ritkábban van szükség. Ennek oka az egyre
                                        gyorsabb internetelérés, melyből egyenesen következett a webes platform
                                        robbanásszerű fejlődése. Ebből adódóan háttérbe szorultak az asztali
                                        alkalmazások, de továbbra is egy fontos megvalósulási forma maradt.
                                        Mindenekelőtt javasoljuk az ügyfél számára, hogy fontolja meg a webes
                                        technológia adta
                                        lehetőségeket. Csak kifejezetten célirányos és indokolt projektek esetében
                                        javasoljuk az asztali alkalmazások megvalósítását.</p>
                                </section>

                                <LoaderParallax {...require("./assets/section-2.jpg")}
                                                style={parallaxStyle}
                                                loaderInverse
                                                parallaxStrength={500}
                                                parallaxBgClassName={"fade in"}
                                />

                                <section className={extractClassNames('container py-5', appCss)}>
                                    <h2>Mikor érdemes asztali alkalmazást készíteni?</h2>
                                    <p className={serviceCss['subheading']}>Próbálta már asztalon?</p>

                                    <p>Jellemzően akkor lehet szükség ilyen alkalmazásra, mikor kénytelenek vagyunk
                                        kilépni a böngészők korlátaiból. Továbbá egy asztali alkalmazást - természetétől
                                        függően - nehezebb frissíteni minden kliensnél. Ilyen lehet például egy
                                        szoftver, ami vizsgálja a mindennapos felhasználói tevékenységeket és rögzíti
                                        azokat.</p>
                                </section>

                                <LoaderParallax {...require("./assets/section-3.jpg")}
                                                style={parallaxStyle}
                                                loaderInverse
                                                parallaxStrength={500}
                                                parallaxBgClassName={"fade in"}
                                />

                                <section className={extractClassNames('container py-5', appCss)}>
                                    <h2>Hatékonyság</h2>
                                    <p className={serviceCss['subheading']}>Amikor a helyi teljesítmény az első!</p>

                                    <p>Előfordulhat, hogy a megrendelőnek olyan üzleti igénye van, melyhez szükséges a
                                        minél gyorsabb számítási teljesítmény. Ennek elérése érdekében jobb megoldás
                                        lehet egy helyi asztali alkalmazás. Előfordulhat, hogy az adatokat és a
                                        számításokat nem szeretné az ügyfél egy szerveren tárolni vagy ott
                                        elvégezni.</p>
                                </section>

                                <LoaderParallax {...require("./assets/section-4.jpg")}
                                                style={parallaxStyle}
                                                loaderInverse
                                                parallaxStrength={500}
                                                parallaxBgClassName={"fade in"}
                                />

                                <section className={extractClassNames('container py-5', appCss)}>
                                    <h2>Individuális alkalmazás</h2>
                                    <p className={serviceCss['subheading']}>Offline is lehet hatékony!</p>

                                    <p>Bizonyos esetekben nincs igény vagy lehetőség a megfelelő hálózati elérésre.
                                        Ekkor megfontolandó, hogy egy asztali alkalmazás végezze el a szükséges
                                        folyamatokat. Ilyen alkalmazás lehet például egy gyártást segítő szoftver, amely
                                        a gyártósor mellett elhelyezett gépen segíti a pontos és hatékony
                                        munkavégzést.</p>
                                </section>

                                <Platforms active="desktop"/>

                                <section>
                                    <div className={extractClassNames('pt-5 py-md-5', appCss)}>
                                        <div className={appCss['container']}>
                                            <div>
                                                <h4 className={extractClassNames('my-5 text-center', appCss)}>Vegye fel
                                                    velünk a kapcsolatot
                                                    bátran!</h4>
                                            </div>
                                            <ToggleAnimation
                                                animationClassName={extractClassNames('slide-bottom row', appCss)}
                                                togglePercent={10}>
                                                <div
                                                    className={extractClassNames('col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3', appCss)}>
                                                    <div id="contactCard"
                                                         className={extractClassNames('bg-white', appCss)}>
                                                        <ContactForm/>
                                                    </div>
                                                </div>
                                            </ToggleAnimation>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </LoadGently>
                </React.Fragment>
            </div>
        );
    }
}

Desktop.propTypes = {};

export default Desktop;
