import {Link} from "react-router-dom";
import React from "react";
import PropTypes from 'prop-types';
import * as queryString from '../../helpers/QueryString';

export default class SearchLink extends React.PureComponent {

    buildSearchQuery() {

        const query = {};
        const keywords = (
            Array.isArray(this.props.keywords)
                ? this.props.keywords
                : [this.props.keywords]
        ).join(" ");

        if (!!keywords) {
            query.keywords = keywords;
        }

        if (this.props.author) {
            query.author = this.props.author;
        }

        return queryString.stringify(query)
    }

    render(): React.ReactNode {
        return (
            <Link
                to={{
                    pathname: '/hirek/1',
                    search: `?${this.buildSearchQuery()}`
                }}
                className={this.props.className}
            >
                {this.props.children}
            </Link>
        )
    }
}

SearchLink.propTypes = {
    className: PropTypes.string,
    keywords: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    author: PropTypes.string
};
