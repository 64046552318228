import React from 'react';
import AnimatedLoader from "../../../shared/AnimatedLoader";
import ProductTheme from "../ProductTheme";
import ScrollToTopOnMount from "../../../helpers/ScrollToTopOnMount";
import Heading from "../../../layout/Heading";
import css from "./Ticketing.module.scss";
import appCss from "../../../App.module.scss";
import {Section} from "../Product";
import ToggleAnimation from "../../../shared/ToggleAnimation";
import {Link} from "react-router-dom";
import ContactForm from "../../../shared/ContactForm";
import {extractClassNames} from "../../../helpers/ExtractClassNames";
import {Helmet} from "react-helmet";
import LoadGently from "../../../layout/LoadGently";
import ImageLoader from "../../../shared/ImageLoader";
import {Parallax} from "react-parallax";
import {DynamicLocation} from "../../../helpers/DynamicLocation";

const Section1Image = require('./assets/section-1.jpg?size=800');
const Section2Image = require('./assets/section-2.jpg?size=800');
const Section3Image = require('./assets/section-3.jpg');
const Section4Image = require('./assets/section-4.jpg?size=800');
const Section5Image = require('./assets/section-5.jpg?size=800');
const Section6Image = require('./assets/section-6.jpg');
const Section7Image = require('./assets/section-7.jpg?size=800');
const Section8Image = require('./assets/section-8.jpg');
const Section9Image = require('./assets/section-9.jpg?size=800');

const PreloadImages = [
    Section1Image,
    Section2Image,
    Section3Image,
    Section4Image,
    Section5Image,
    Section6Image,
    Section7Image,
    Section8Image,
    Section9Image,
];

const ParallaxStyle = {
    height: '50vw',
    minHeight: '500px',
};

class Ticketing extends React.PureComponent {

    render() {
        return (
            <LoadGently>

                <Helmet>
                    <title>Hibajegykezelő | Termékek | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Hibajegykezelő,Hibajegy,Ticketing,Ügyfél,Kommunikáció,Bejelentés,Folyamat,Kategorizálás,Prioritás,Kapcsolatfelvétel"/>
                    <meta name="description"
                          content="Ha a kommunikáció az Ön cégének is fontos, szerves része és egy hatékony hibajegykezelőt keres, megtalálta!"/>
                    <meta property="og:title" content="Hibajegykezelő | Termékek | SzuniSOFT"/>
                    <meta property="og:description" content="Ha a kommunikáció az Ön cégének is fontos, szerves része és egy hatékony hibajegykezelőt keres, megtalálta!"/>
                    <meta property="og:image" content={DynamicLocation.build(require('./assets/section-6.jpg?size=800').src)}/>
                </Helmet>

                <ProductTheme siteThemeId={css['ticketing-site-theme']}
                              productThemeId={css['ticketing-product-theme']}
                >
                    <ScrollToTopOnMount/>

                    <ImageLoader multiple={PreloadImages}
                                 loading={
                                     <AnimatedLoader/>
                                 }
                                 loaded={
                                     <div className={extractClassNames('d-flex flex-grow-1 flex-column', appCss)}>
                                         <Heading heading="Ticketing"
                                                  breadCrumb={[
                                                      {text: 'Termékek', to : '/termekek'},
                                                      'Hibajegy kezelő'
                                                  ]}
                                         />
                                         <div className={extractClassNames('container-fluid p-0 flex-grow-1', appCss)}>

                                             <Section themeCss={css} layout={"first-left-last-right"}
                                                      screenHeightOnLarge>

                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-left w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: `url(${PreloadImages[0].src}) no-repeat center`,
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('container d-flex align-items-center', appCss)}>
                                                     <div>
                                                         <ToggleAnimation animationClassName="slide-top"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Mi
                                                                 a hibajegy
                                                                 kezelő rendszer?</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>Olyan
                                                                 kommunikációs platform, amely a cégek
                                                                 működésének fontos és szerves
                                                                 része.
                                                                 Mind külső és belső működésbeli kommunikációs csatorna,
                                                                 mely képes
                                                                 feladatok
                                                                 kezelésére, ütemezésére és nyomon követésére.
                                                                 Segítségével nem csak
                                                                 egyszerűbb a
                                                                 feladatok felosztása, de visszatekintés
                                                                 szempontjából is
                                                                 fontos
                                                                 funkciókat
                                                                 kínál.</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css} theme layout={"first-right-last-left"}
                                                      screenHeightOnLarge>

                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-right w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: `url(${PreloadImages[1].src}) no-repeat center`,
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('container d-flex align-items-center', appCss)}>
                                                     <div>
                                                         <ToggleAnimation animationClassName="slide-top"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Azoknak,
                                                                 akik
                                                                 levelezőt használnak..</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>Amennyiben
                                                                 az
                                                                 Ön
                                                                 cége levelezés szinten
                                                                 bonyolítja az ügyfelek problémáinak kezelését, addig
                                                                 követhető jól,
                                                                 amíg
                                                                 valaki egyidejűleg egy személlyel levelezik. Azonban,
                                                                 amint kellően
                                                                 nagyra nő egy hibajegy, idővel követhetetlenné,
                                                                 kezelhetetlenné és
                                                                 nem
                                                                 utolsó sorban pedig nehezen átláthatóvá válik az
                                                                 aktuális probléma
                                                                 jelenlegi helyzete, valamint annak előzményei.</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css} inverse layout={"column"}>
                                                 <Parallax bgImage={PreloadImages[2].src}
                                                           bgImageSrcSet={PreloadImages[2].srcSet}
                                                           strength={500}
                                                 >
                                                     <div style={ParallaxStyle}>
                                                         <h5 className={css['theme'] + ' ' + appCss['text-center']}>A
                                                             telefonos
                                                             hibarögzítés veszélyei</h5>
                                                     </div>
                                                 </Parallax>

                                                 <ToggleAnimation togglePercent={10} animationClassName="slide-bottom">
                                                     <div className={extractClassNames('container pt-3', appCss)}>
                                                         <h4 className={css['theme']}>Túlterhelt hálózat</h4>
                                                         <p className={css['theme']}>Telefonon történő hibajegy kezelés
                                                             akkor is
                                                             nehézséget
                                                             okozhat, mikor egy túlzsúfolt időszakban a kollégái nem
                                                             tudnak annyi
                                                             hívást
                                                             fogadni, amennyit az ügyfelek igényelnének, így
                                                             elégedetlenséget okozva
                                                             számukra. Ha az Ön ügyfelei nem érik el a megjelölt
                                                             kapcsolattartót, az
                                                             akár
                                                             rossz fényt is vethet a közös együttműködésre.</p>
                                                         <h4 className={css['theme']}>Nem megbízható kollégák</h4>
                                                         <p className={css['theme']}>Egy call-center rendszerben minden
                                                             esély megvan
                                                             rá,
                                                             hogy
                                                             egy kollégának rossz napja van. Ezért nem feltétlenül
                                                             kezeli megfelelően
                                                             az
                                                             ügyféllel történő kommunikációt. </p>

                                                     </div>
                                                 </ToggleAnimation>
                                             </Section>

                                             <Section themeCss={css} screenHeightOnLarge
                                                      layout={"first-right-last-left"}>
                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-top w-100 h-100', appCss)}
                                                     togglePercent={25}>
                                                     <div style={{
                                                         background: `url(${PreloadImages[3].src}) no-repeat 30% center`,
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('container d-flex align-items-center', appCss)}>
                                                     <div>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-left">
                                                             <h5 className={css['theme']}>Ezzel szemben..</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation togglePercent={10}
                                                                          animationClassName="slide-bottom">
                                                             <p className={css['theme']}>
                                                                 Az online ticketing írásos formája miatt lehetőséget ad
                                                                 mindenki
                                                                 számára, hogy akár sokadszorra is átnézzük a közölni
                                                                 kívánt üzenet
                                                                 tartalmát.
                                                                 Lehetséges, hogy Önök túl terheltek és nem tudnak
                                                                 azonnal reagálni,
                                                                 de
                                                                 már az
                                                                 első pillanatban tudnak súlyozni, hogy melyik feladat
                                                                 melyiket kell,
                                                                 hogy
                                                                 kövesse. Az ügyfeleknek pedig már egy plusz pont, hogy
                                                                 volt egy
                                                                 felület,
                                                                 ahol el
                                                                 tudta kezdeni a kapcsolatfelvételt és nem kellett hosszú
                                                                 zene
                                                                 bejátszásokat végig
                                                                 hallgatni, nem kellett több embernek elmagyarázni a
                                                                 problémáját, mert
                                                                 mindig más
                                                                 mellékre kapcsolják mert nem tudják kategorizálni az
                                                                 esetet.
                                                             </p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css} inverse layout={"first-right-last-left"}
                                                      screenHeightOnLarge>

                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-right w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: 'url(' + PreloadImages[4].src + ') no-repeat center 45%',
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('container d-flex align-items-center', appCss)}>
                                                     <div>
                                                         <ToggleAnimation animationClassName="slide-left"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme']}>Munkatársak megkímélése,
                                                                 "megfigyelése"</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme']}>Tapasztalataink alapján egy
                                                                 telefonos
                                                                 bejelentés
                                                                 esetén a bejelentő összeszedettsége megkérdőjelezhető.
                                                                 Lehet, hogy
                                                                 nincs
                                                                 nála az összes szükséges információ, vagy pedig
                                                                 futtában szeretne
                                                                 túlesni a bejelentésen, de megesik, hogy nem járatos a
                                                                 témában.
                                                                 Ezzel szemben egy online felület el tudja kérni a
                                                                 szükséges
                                                                 információkat.</p>
                                                             <p className={css['theme']}>Mivel minden cégben vannak
                                                                 vezetők, így
                                                                 nekik
                                                                 fontos
                                                                 lehet, hogy átlássák az ügyfélkommunikációs
                                                                 állapotokat. Erre a
                                                                 legmegfelelőbb eszköz a hibajegykezelő, mivel minden
                                                                 dolgozó
                                                                 ügyféllel
                                                                 való kommunikációját vissza lehet tekinteni és akár
                                                                 felül is lehet
                                                                 vizsgálni.</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css} layout={"column"} screenHeightOnLarge>
                                                 <Parallax bgImage={PreloadImages[5].src}
                                                           bgImageSrcSet={PreloadImages[5].srcSet}
                                                           strength={500}
                                                 >
                                                     <div style={ParallaxStyle}>
                                                         <h5 className={css['theme'] + ' ' + appCss['text-center'] + ' ' + appCss['bg-white']}>Bárhonnan
                                                             bármikor
                                                             elérhető felület</h5>
                                                     </div>
                                                 </Parallax>
                                                 <ToggleAnimation animationClassName="slide-bottom" togglePercent={10}>
                                                     <div className={extractClassNames('container pt-3', appCss)}>
                                                         <p className={css['theme']}>Sokszor problémát jelenthet
                                                             speciális, régebbi
                                                             megoldásoknál, hogy helyhez kötik az embert. Ez jellemzően
                                                             igaz a vastag
                                                             terminálos, valamint a call-centeres megoldásokra. Ezzel
                                                             szemben egy
                                                             online felület bármikor elérhető.</p>
                                                     </div>
                                                 </ToggleAnimation>
                                             </Section>

                                             <Section themeCss={css} inverse layout={"first-right-last-left"}
                                                      screenHeightOnLarge>

                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-right w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: 'url(' + PreloadImages[6].src + ') no-repeat 35% center',
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('container d-flex align-items-center', appCss)}>
                                                     <div>
                                                         <ToggleAnimation animationClassName="slide-left"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme']}>Mobilapplikáció</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme']}>Hibajegykezelő rendszerünket
                                                                 megtámogattuk
                                                                 mobilalkalmazással, amely a hiba bejelentésre szolgál.
                                                                 Így Androidos
                                                                 és
                                                                 IOS eszközökön is könnyedén kezelhetők az adatok. A
                                                                 mobilalkalmazás
                                                                 megalkotásának fő szempontja a minél gyorsabban történő
                                                                 hibajegyek
                                                                 feladása, valamint azoknak teljeskörű menedzsmentje
                                                                 volt. Ezzel
                                                                 növelve
                                                                 a partneri elégedettséget.</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css} layout={"column"} screenHeightOnLarge>

                                                 <Parallax bgImage={PreloadImages[7].src}
                                                           bgImageSrcSet={PreloadImages[7].srcSet}
                                                           strength={500}
                                                 >
                                                     <div style={ParallaxStyle}>
                                                         <h5 className={css['theme'] + ' ' + appCss['text-center'] + ' ' + appCss['bg-white']}>Mindig
                                                             nyitva
                                                             áll:
                                                             nincs szünet, mindig lehet
                                                             feladni hibajegyet</h5>
                                                     </div>
                                                 </Parallax>

                                                 <ToggleAnimation animationClassName="slide-bottom" togglePercent={10}>
                                                     <div className={extractClassNames('container pt-3', appCss)}>
                                                         <p className={css['theme']}>Amikor megpróbálunk munkaidőn kívül
                                                             kapcsolatot
                                                             létesíteni
                                                             értékesítéssel, vagy üzemeltetéssel foglalkozó cégekkel,
                                                             akkor könnyedén
                                                             előfordulhat, hogy nem kapunk választ, nem veszi fel senki
                                                             a telefont.
                                                             Ezzel szemben egy online ticketing rendszer 7/24 es
                                                             elérhetőséget tesz
                                                             lehetővé. Ha az elérni kívánt cég éppen zárva is tart. A
                                                             nyitás után
                                                             pedig
                                                             rögtön tudnak reagálni a beérkezett esetekre.</p>
                                                     </div>
                                                 </ToggleAnimation>
                                             </Section>

                                             <Section themeCss={css} layout={"first-left-last-right"}
                                                      screenHeightOnLarge>

                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-left w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: 'url(' + PreloadImages[8].src + ') no-repeat center',
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('container d-flex align-items-center', appCss)}>
                                                     <div>
                                                         <ToggleAnimation animationClassName="slide-top"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Mit
                                                                 kínál a
                                                                 mi
                                                                 rendszerünk?</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>Több
                                                                 éves
                                                                 üzemeltetési tapasztalaton
                                                                 alapuló rendszert építettünk a saját, valamint
                                                                 partnereink korábbi
                                                                 igényei alapján. Így nem egy olyan szoftvert van
                                                                 lehetősége
                                                                 használni,
                                                                 melyet csupán a bevétel kedvéért fejlesztettek le,
                                                                 hanem egy olyan
                                                                 programmal találkozhat, amelynek működése valós piaci
                                                                 igényeken
                                                                 alapszik
                                                                 és mai napig általunk is használatban van.</p>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>
                                                                 A hibajegy kezelő alap köve a CRM ahol tárolva vannak
                                                                 jelenlegi
                                                                 ügyfeleinek és partnereinek adatai <Link
                                                                 to="/termekek/crm">(olvassa
                                                                 el
                                                                 CRM termékünket)</Link>.
                                                             </p>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>
                                                                 Az ön által felvett személyt egy kattintással feltudja
                                                                 jogosítani
                                                                 hibajegy feladásra. Ezáltal meghívást kap az ön
                                                                 rendszerébe és egy
                                                                 gyors
                                                                 jelszó készítést követően már fel is tudja önnel venni
                                                                 a
                                                                 kapcsolatot. A
                                                                 felületen meg tudja jelölni mennyire sürgős a probléma,
                                                                 valamint meg
                                                                 tudja határozni, hogy milyen témakörben szeretné
                                                                 felvenni a
                                                                 kapcsolatot.
                                                                 Ezeket a témaköröket Ön szabadon szerkesztheti,
                                                                 valamint olyan
                                                                 megkötéseket is tud tenni, hogy melyik partnere milyen
                                                                 témakörben
                                                                 keresheti Önt. Így szabályozva, hogy csak a megfelelő
                                                                 esetekkel
                                                                 forduljanak Önhöz. Csökkentve ezzel a nem Önre tartozó
                                                                 kérdéseket.
                                                             </p>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <h4 className={css['theme']}>Többlépcsős jogosultság
                                                                 kiosztási
                                                                 lehetőség</h4>
                                                             <ul className={css['theme'] + ' ' + appCss['mt-3']}>
                                                                 <li>
                                                                     Külsős hibajegy adminisztrátor:
                                                                     <p className={css['theme']}>Az a személy, aki a
                                                                         saját cégében
                                                                         szereplő
                                                                         hibajegyeket feladni képes felhasználók fölött
                                                                         rendelkezik.
                                                                         Ezen
                                                                         túl képes minden, a cégben lévő ticketet
                                                                         visszatekinteni,
                                                                         így
                                                                         rálátása van a cégek közötti teljeskörű
                                                                         kommunikációra.</p>
                                                                 </li>
                                                                 <li>
                                                                     IP korlátozott, egyszerűsített felület:
                                                                     <p className={css['theme']}>Lehetőség van olyan
                                                                         helységek
                                                                         felvételére,
                                                                         amely területen nem szükséges az egyedi
                                                                         azonosítás. Egy
                                                                         egyszerűsített felületről tudnak felhasználók
                                                                         hibajegyet
                                                                         feladni.</p>
                                                                 </li>
                                                             </ul>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>
                                                                 A gyors feladat kiosztás lehetővé teszi, hogy egy munka
                                                                 és / vagy
                                                                 projekt felelős gyorsan és hatékonyan tudja a megfelelő
                                                                 személyeknek
                                                                 kiosztani a rájuk tartozó feladatokat.
                                                             </p>
                                                             <p className={css['theme']}>
                                                                 Hibajegy "megragadás" funkció lehetőséget ad arra, hogy
                                                                 kollégái
                                                                 maguk
                                                                 választhassák ki, hogy melyik beérkezett hibajeggyel
                                                                 szeretnétek
                                                                 foglalkozni. Így gyorsan kerülhetnek feldolgozásra a
                                                                 beérkezett
                                                                 hibajegyek.
                                                             </p>
                                                             <p className={css['theme']}>A két módszer vegyesen is
                                                                 alkalmazható.</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css} screenHeightOnLarge
                                                      className={extractClassNames('mt-5 justify-content-center', appCss)}>
                                                 <div className={appCss['container']}>
                                                     <ToggleAnimation
                                                         animationClassName={extractClassNames('slide-bottom row', appCss)}
                                                         togglePercent={10}>
                                                         <div
                                                             className={extractClassNames('col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3', appCss)}>
                                                             <h4 className={extractClassNames('my-5 text-center', appCss)}>Amennyiben
                                                                 cégének egy hatékony
                                                                 hibajegykezelőt keres, megtalálta!</h4>
                                                             <div id="contactCard" className={appCss['mb-5']}>
                                                                 <ContactForm/>
                                                             </div>
                                                         </div>
                                                     </ToggleAnimation>
                                                 </div>
                                             </Section>

                                         </div>
                                     </div>
                                 }
                    />
                </ProductTheme>
            </LoadGently>
        );
    }
}

Ticketing.propTypes = {};

export default Ticketing;
