import AbstractValidator from "./Validators";

class Validator {

    static validate = async (rules, value, limitErrors) => {

        let results = Validator.getOrderedValidators(rules)
            .map(async (validator) => {

                let result;

                if (typeof validator === "function") {
                    result = validator(value);
                } else if (validator instanceof AbstractValidator) {
                    result = validator.validate(value);
                } else {
                    throw new Error("The given validator is invalid! " + validator);
                }

                // In case of async operation
                if (result instanceof Promise) {
                    result = await result;
                }

                return result;
            });

        let errors = await Promise.all(results);

        // Filter errors by success values
        errors = errors.filter(v => !!v);

        if (limitErrors) {
            errors = errors.slice(0, limitErrors);
        }

        return errors.length ? errors : [];
    };

    static getOrderedValidators(rules) {
        if (typeof rules === "undefined") {
            console.warn("No validator passed");
            return [];
        } else {
            return rules.sort(v => v.priority);
        }
    }
}

export default Validator;
