import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import appCss from "../../App.module.scss";
import {extractClassNames} from "../../helpers/ExtractClassNames";
import {Link} from "react-router-dom";
import css from "./Heading.module.scss";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {Sticky} from "react-sticky";

class Heading extends Component {

    static defaultProps = {
        breadCrumb: [],
        breadCrumbLimit: 3,
        className: ''
    };

    render() {

        const breadCrumb = [];

        if (this.props.breadCrumb.length) {
            this.props.breadCrumb
                .slice(-this.props.breadCrumbLimit)
                .map(bc => typeof bc === "string"
                    ? <span>{bc}</span>
                    : <Link to={bc.to}>{bc.text}</Link>)
                .forEach((bc, i) => {
                    if (i < this.props.breadCrumb.length && i > 0) {
                        breadCrumb.push(<span className={css['separator'] + ' breadcrumb-theme-separator'}>
                                                <FontAwesomeIcon icon={faChevronRight} size={"1x"}/>
                                            </span>);
                    }
                    breadCrumb.push(bc);
                });
        }

        return (
            <React.Fragment>
                <div className={extractClassNames('bg-dark py-3 text-white', appCss) + " " + this.props.className}>
                    <div className={appCss['container']}>
                        <div className={appCss['d-flex']}>
                            {
                                this.props.icon
                                    ? <h1><FontAwesomeIcon icon={this.props.icon}
                                                           className={extractClassNames('mr-3 slide-top in flex-grow-0 flex-shrink-0', appCss)}/>
                                    </h1>
                                    : null
                            }
                            <h1 className={extractClassNames('mb-0 slide-right in flex-grow-1', appCss)}>{this.props.heading}</h1>
                        </div>
                        {this.props.subHeading ? <h3 className="slide-bottom in">{this.props.subHeading}</h3> : null}
                    </div>
                </div>

                {
                    breadCrumb.length
                        ? <Sticky topOffset={18}
                        >{({style, isSticky}) => (<div style={{...style, top: '64px'}}
                                                       className={css['breadcrumbs-wrapper'] + ' breadcrumb-theme' + ' ' + (isSticky ? css['shadowed'] : '')}>
                            <div
                                className={extractClassNames('container text-truncate', appCss) + ' ' + css['breadcrumbs'] + ' ' + extractClassNames('slide-top in', appCss)}>{
                                breadCrumb.map((bc, i) => React.cloneElement(bc, {key: i}))
                            }</div>
                        </div>)}</Sticky>
                        : null
                }

            </React.Fragment>
        );
    }
}

Heading.propTypes = {
    heading: PropTypes.any,
    subHeading: PropTypes.any,
    icon: PropTypes.object,
    className: PropTypes.string,
    breadCrumb: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                text: PropTypes.string.isRequired,
                to: PropTypes.any
            })
        ])
    ),
    breadCrumbLimit: PropTypes.number
};

export default Heading;
