import React, {Component} from 'react';
import css from "./Countdown.module.scss";
import PropTypes from 'prop-types';
import LoadGently from "../LoadGently";

class Countdown extends Component {

    static defaultProps = {
        until: 0,
        speed: 1000,
        className: '',
        style: {},
        onComplete: () => {
        }
    };

    state = {
        current: null
    };

    componentDidMount(): void {
        this.setState({current: this.props.start});
        this.count();
    }

    componentWillUnmount(): void {

        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    timer = null;

    count = () => {
        this.timer = setTimeout(() => {

            this.setState(state => ({current: state.current - 1}));

            // Finally exit.
            if (this.state.current <= this.props.until) {
                this.props.onComplete();
                return;
            }

            // Call recursively.
            this.count();

        }, this.props.speed);
    };

    render() {

        return (
            <LoadGently>
                <div className={css.countdown + ' ' + this.props.className} style={this.props.style}>
                    <div className={css.content + ' ' + (this.state.current > 0 ? css.playing : '')}>
                        {this.state.current}
                    </div>
                </div>
            </LoadGently>
        );
    }
}

Countdown.propTypes = {
    start: PropTypes.number.isRequired,
    until: PropTypes.number,
    speed: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object,
    onComplete: PropTypes.func
};

export default Countdown;
