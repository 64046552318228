import React, {Component} from 'react';
import PropTypes from 'prop-types';
import appCss from "../../App.module.scss";
import css from "./Examples.module.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {extractClassNames} from "../../helpers/ExtractClassNames";

class Examples extends Component {

    static defaultProps = {
        listOnly: false,
        headingComponent: 'h5',
        className: '',
        listClassName: '',
        headingClassName: ''
    };

    list() {
        return (
            <ul className={(this.props.listClassName || '') + ' ' + extractClassNames('p-0', appCss) + ' ' + css['list']}>
                {this.props.items.map((item, i) => <li key={i} className={(this.props.itemClassName || '')}>
                    {
                        this.props.itemIcon
                            ? <FontAwesomeIcon icon={this.props.itemIcon} className={appCss['mr-2']}/>
                            : null
                    }
                    {item}
                </li>)}
            </ul>
        );
    }

    heading() {

        if (!!this.props.heading) {
            return React.createElement(this.props.headingComponent, {
                children: this.props.heading,
                className: (this.props.headingClassName || '') + ' ' + css['heading']
            });
        }

    }

    render() {
        return (
            <div className={css['examples'] + ' ' + this.props.className}>
                {
                    this.props.listOnly === false
                        ?
                        <div
                            className={extractClassNames('flex-shrink-0', appCss)}>
                            {this.heading()}
                        </div>
                        : null
                }
                <div>
                    {this.list()}
                </div>
            </div>
        );
    }
}

Examples.propTypes = {
    heading: PropTypes.any,
    headingComponent: PropTypes.string,
    headingClassName: PropTypes.string,
    listOnly: PropTypes.bool,
    className: PropTypes.string,
    listClassName: PropTypes.string,
    itemClassName: PropTypes.string,
    itemIcon: PropTypes.any,
    items: PropTypes.array.isRequired,
};

export default Examples;
