import React, {Component} from 'react';
import PropTypes from 'prop-types';
import appCss from '../../App.module.scss';
import css from './TechnologyList.module.scss';
import {extractClassNames} from "../../helpers/ExtractClassNames";

class TechnologyList extends Component {

    static contextTypes = {
        setTechnology: PropTypes.func,
        subscribeTechnologyViewer: PropTypes.func,
        unsubscribeTechnologyViewer: PropTypes.func
    };

    state = {
        technology: null,
        technologyDirection: null
    };

    componentDidMount(): void {
        if (!this.context.setTechnology) {
            throw new TypeError("Expected TechnologyList to be mounted within TechnologyViewer!");
        }

        this.context.subscribeTechnologyViewer(this.nextTechnology, this.prevTechnology)
    }

    componentWillUnmount(): void {
        this.context.setTechnology(null);
        this.context.unsubscribeTechnologyViewer(this.nextTechnology, this.prevTechnology);
    }

    setTechnology = (technology, direction) => {
        this.setState({technology});
        this.context.setTechnology(technology, direction);
    };

    nextTechnology = () => {

        if (!!this.state.technology === false) {
            return;
        }

        let i = this.props.technologies.indexOf(this.state.technology);
        if (i === this.props.technologies.length - 1) {
            i = 0;
        } else {
            i++;
        }

        const direction = 'right';
        this.setState({technologyDirection: direction, technologyIndex: (this.state.technologyIndex === 1 ? 2 : 1)});
        this.setTechnology(this.props.technologies[i], direction);
    };

    prevTechnology = () => {
        if (!!this.state.technology === false) {
            return;
        }

        let i = this.props.technologies.indexOf(this.state.technology);
        if (i === 0) {
            i = this.props.technologies.length - 1;
        } else {
            i--;
        }

        const direction = 'left';
        this.setState({technologyDirection: direction, technologyIndex: (this.state.technologyIndex === 1 ? 2 : 1)});
        this.setTechnology(this.props.technologies[i], direction);
    };


    render() {

        return (
            <div className={extractClassNames('row d-flex justify-content-center', appCss)}>
                {this.props.technologies.map(technology => (
                    <div className={css["technologies-col-item"]} key={technology.name}>
                        <div className={css["wrapper"] + " " + appCss["d-flex"]}
                             onClick={this.setTechnology.bind(this, technology)}>
                            <span className={appCss["font-weight-light"]}>{technology.name}</span>
                            <img src={technology.img} alt={technology.name}/>
                            {/* For SEO purpose only */}
                            <div className={extractClassNames('invisible d-none', appCss)}>{technology.content}</div>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

TechnologyList.propTypes = {
    technologies: PropTypes.array.isRequired
};

export default TechnologyList;
