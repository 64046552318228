import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ArticleContent extends Component {

    state = {
        i: 0
    };

    async componentDidMount(): void {

        [].slice.call(document.getElementsByTagName('video')).forEach(async (video : HTMLVideoElement) => {

            video.muted = true;
            video.autoplay = true;

            try {
                await video.play();
            } catch (e) {
                video.controls = true;
            }

        });

    }

    render() {
        return (
            <div key={this.state.i} className={this.props.className}
                 dangerouslySetInnerHTML={{__html: this.props.content}}/>
        );
    }
}

ArticleContent.propTypes = {
    className: PropTypes.string,
    content: PropTypes.string
};

export default ArticleContent;
