import type {ServerActionT, ServerT} from "./types";
import {ServerActionTypes} from "./actions";

export const initialState: ServerT = Object.freeze({
    preloaders: []
});

export default (state: ServerT = initialState, action: ServerActionT): ServerT => {

    /* Make reducer server exclusive */
    if (__SERVER__ === false) {
        return state;
    }

    const {type, payload} = action;

    switch (type) {
        case ServerActionTypes.ADD_PRELOADER: {
            return {
                ...state,
                preloaders: [
                    ...state.preloaders,
                    payload
                ]
            }
        }
    }

    return state;
}
