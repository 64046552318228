import React, {Component} from 'react';
import { DynamicLocation } from "../../../helpers/DynamicLocation";
import ScrollToTopOnMount from "../../../helpers/ScrollToTopOnMount";
import Heading from "../../../layout/Heading";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import appCss from "../../../App.module.scss";
import {extractClassNames} from "../../../helpers/ExtractClassNames";
import {Helmet} from "react-helmet";
import LoadGently from "../../../layout/LoadGently";
import serviceCss from "../Services.module.scss";
import LoaderParallax from "../../../layout/LoaderParallax";
import ToggleAnimation from "../../../shared/ToggleAnimation";
import ContactForm from "../../../shared/ContactForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-regular-svg-icons";

const parallaxStyle = {
    height: '30vw',
    minHeight: '400px'
};
const ServicingOgImage = require('./assets/section-1.jpg');
class Servicing extends Component {
    render() {
        return (
            <React.Fragment>

                <Helmet>
                    <title>Üzemeltetés | Szolgáltatás | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Számítógépek,Szerverek,Szoftver,Üzemeltetés,Szerverterem,Kamera rendszerCloud,Azure,Karbantartás,Felügyelet"/>
                    <meta name="description"
                          content="Legyen szó számítógépről, szerverről, hálózatról, szoftverről, mindegyik elemnek szüksége van a folyamatos karbantartásra, felügyeletre tehát üzemeltetésre."/>
                    <meta property="og:title" content="Üzemeltetés | Szolgáltatás | SzuniSOFT"/>
                    <meta property="og:description" content="Legyen szó számítógépről, szerverről, hálózatról, szoftverről, mindegyik elemnek szüksége van a folyamatos karbantartásra, felügyeletre tehát üzemeltetésre."/>
                    <meta property="og:image" content={DynamicLocation.build(ServicingOgImage.src)}/>
                </Helmet>

                <LoadGently>
                    <ScrollToTopOnMount/>
                    <Heading heading="Üzemeltetés"
                             icon={faCog}
                             breadCrumb={[
                                 {text: 'Szolgáltatások', to: '/szolgaltatasok'},
                                 'Üzemeltetés'
                             ]}
                    />
                    <div
                        className={extractClassNames('d-flex flex-column flex-grow-1', appCss) + ' ' + serviceCss['service']}>

                        <LoaderParallax {...require("./assets/section-1.jpg")}
                                        style={parallaxStyle}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                        />

                        <section>
                            <div className={extractClassNames('container py-5', appCss)}>
                                <h2>Bevezető</h2>
                                <p className={serviceCss['subheading']}>Az igények Önnél a megoldás nálunk!</p>
                                <p>Amennyiben egy hatékony vállalkozást szeretnék manapság elengedhetetlen az
                                    informatikai kötődés. Ehhez szükség van megfelelő hardverek és szoftverek meglétére
                                    és azok rendszeres karbantartására.</p>
                            </div>
                        </section>

                        <LoaderParallax {...require("./assets/section-2.jpg")}
                                        style={parallaxStyle}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                        />

                        <section className={serviceCss['inverse']}>
                            <div className={extractClassNames('container py-5', appCss)}>
                                <h2>Rendszerüzemeltetés</h2>
                                <p className={serviceCss['subheading']}>Biztonság és megbízhatóság!</p>
                                <p>Rendszer alatt egy teljes céges ökoszisztémát lehet érteni. A szerverektől egészen a
                                    kliens gépekig mindenre kiterjed az üzemeltetés fogalma. Egy ilyen rendszer
                                    üzemeléséhez minden szükséges komponens rendszeres karbantartása meggátolhatja az
                                    egyszeri nagyobb kiadásokat és a váratlan leállásokat.
                                </p>
                                <p>Ide tartoznak továbbá a számítógépek fölött álló céges szerverek, amelyek külön
                                    megkövetelhetnek egy teljes rendszergazdát vagy egy üzemeltető céget. Utóbbi egy
                                    rendszergazda fizetésének töredékért egy több főből álló, nagyobb szaktudással
                                    rendelkező gárdával gondoskodik a hosszú távú stabil működésről.
                                </p>
                            </div>
                        </section>

                        <LoaderParallax {...require("./assets/section-3.jpg")}
                                        style={parallaxStyle}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                        />

                        <section className={serviceCss['primary']}>
                            <div className={extractClassNames('container py-5', appCss)}>
                                <h2>Hálózatüzemeltetés</h2>
                                <p className={serviceCss['subheading']}>Maradjon mindig kapcsolatban!</p>
                                <p>Egy stabil és biztonságos internetelérésnek különböző feltételei vannak. Bármilyen
                                    belső hálózatról is legyen szó, annak jellemzően rendelkeznie kell belépési ponttal
                                    az internet felé. A biztonságos üzleti internetelérés nem csupán a megfelelő tűzfal
                                    és eszközbeállításoktól válik megfelelővé. Ezen túl általános igény van a teljeskörű
                                    elérésre, mely alatt azt kell érteni, hogy több internetszolgáltató által bekötött
                                    vonal kombinációjából létrehozható egy 100%-os üzemidejű hálózat.</p>
                            </div>
                        </section>

                        <LoaderParallax {...require("./assets/section-4.jpg")}
                                        style={parallaxStyle}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                        />

                        <section className={serviceCss['secondary']}>
                            <div className={extractClassNames('container py-5', appCss)}>
                                <h2>Kamera rendszerüzemeltetés</h2>
                                <p className={serviceCss['subheading']}>
                                    <FontAwesomeIcon icon={faEye} className={appCss['mr-2']}/>
                                    Lásson velünk!</p>
                                <p>A kamera rendszerekről sokszor csak a biztonság miatt esik szó, de ezen felül
                                    rendkívül sok potenciált hordoz magával, mert mozgás gyakoriságot és interaktivitást
                                    is lehet mérni. Továbbá a régi felvevőegységes megoldásoknál már sokkal korszerűbb
                                    megvalósulások léteznek, amelyekkel már nincs feltétlen szükség plusz felvevőegység
                                    vásárlására, hanem egy szerveren vagy webes tárhelyen is tárolhatók a felvételek. A
                                    kamerák pedig a belső LAN hálózatot használják. Ilyenkor a kamera rendszer, valamint
                                    a hálózatüzemeltetés kéz a kézben járnak, mivel fontos, hogy a kamera ne lassítsa a
                                    hálózatot oly módon, hogy az befolyásoljon más eszközöket vagy emberek munkáját.</p>
                            </div>
                        </section>

                        <LoaderParallax {...require("./assets/section-5.jpg")}
                                        style={parallaxStyle}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                        />

                        <section className={serviceCss['inverse']}>
                            <div className={extractClassNames('container py-5', appCss)}>
                                <h2>Szoftverüzemeltetés</h2>
                                <p className={serviceCss['subheading']}>Kicsiktől a nagyokig!</p>
                                <p>Akár új vagy meglévő szoftver fejlesztéséről legyen szó, mi biztonságos üzemeltetési
                                    konstrukciókat ajánlunk mindenki számára. Általunk ellenőrzött partneri platformokon
                                    valósítjuk meg a rendszeres üzemeltetést legyen szó bármilyen típusú szoftverről. Az
                                    általunk biztosított üzemeltetési konstrukciók magukba vonják az automatizált
                                    frissítési megoldásokat - ezzel csökkentve vagy bizonyos esetekben pedig
                                    megszüntetve a rendszerkiesést. Komplex felhőalapú, helyileg kihelyezett vagy hibrid
                                    megoldásokat kínálunk az egyszerű weboldalak üzemeltetésétől az összetett
                                    infrastruktúrájú alkalmazáscsomagokig.</p>
                            </div>
                        </section>

                        <LoaderParallax {...require("./assets/section-6.jpg")}
                                        style={parallaxStyle}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                        />

                        <section>
                            <div className={extractClassNames('container py-5', appCss)}>
                                <h2>Árazás</h2>
                                <p className={serviceCss['subheading']}>Minél több maradjon a perselyben!</p>
                                <p>Egy folyamatos karbantartás, felügyelet, üzemeltetés árszabása nagyban függ az
                                    eszközök és igények típusától és mértékétől. Ebből adódóan nem lehet egy szabványos
                                    ártáblázatból kiindulni. Tényszerűen meg kell vizsgálnunk a szükséges technológiákat
                                    és, hogy milyen irányba szeretne fejlődni a cég. A mi célunk az, hogy a mindennapok
                                    hatékonyságát tudjuk fokozni. Forduljon hozzánk bizalommal!</p>
                            </div>
                        </section>

                        <section className={serviceCss['inverse']}>
                            <div className={extractClassNames('bg-dark pt-5 py-md-5 text-white', appCss)}>
                                <div className={appCss['container']}>
                                    <div>
                                        <h4 className={extractClassNames('my-5 text-center', appCss)}>Vegye fel velünk a kapcsolatot
                                            bátran!</h4>
                                    </div>
                                    <ToggleAnimation
                                        animationClassName={extractClassNames('slide-bottom row', appCss)}
                                        togglePercent={10}>
                                        <div
                                            className={extractClassNames('col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3', appCss)}>
                                            <div id="contactCard" className={extractClassNames('bg-white', appCss)}>
                                                <ContactForm/>
                                            </div>
                                        </div>
                                    </ToggleAnimation>
                                </div>
                            </div>
                        </section>
                    </div>
                </LoadGently>

            </React.Fragment>
        );
    }
}

Servicing.propTypes = {};

export default Servicing;
