import React, {Component} from 'react';
import { DynamicLocation } from "../../../../helpers/DynamicLocation";
import ScrollToTopOnMount from "../../../../helpers/ScrollToTopOnMount";
import Heading from "../../../../layout/Heading";
import {faMobileAlt} from "@fortawesome/free-solid-svg-icons";
import {extractClassNames} from "../../../../helpers/ExtractClassNames";
import appCss from "../../../../App.module.scss";
import serviceCss from "../../Services.module.scss";
import softwareCss from "../Software.module.scss";
import {Background, Parallax} from "react-parallax";
import LoadGently from "../../../../layout/LoadGently";
import LoaderParallax from "../../../../layout/LoaderParallax";
import {Helmet} from "react-helmet";
import {Platforms} from "../index";
import ToggleAnimation from "../../../../shared/ToggleAnimation";
import ContactForm from "../../../../shared/ContactForm";

const parallaxStyle = {
    height: '30vw',
    minHeight: '400px'
};
const MobileOgImage = require('./assets/mobile-2.jpg');
class Mobile extends Component {
    render() {
        return (
            <React.Fragment>

                <Helmet>
                    <title>Mobilfejlesztés | Szoftverfejlesztés | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Mobil,Alkalmazás,App,Android,IOS,Egyedi,Teljeskörű,Fejlesztés,Cross,Platform"/>
                    <meta name="description"
                          content="Manapság a mobil kiemelkedő szerepet tölt be a gazdaság minden területén. Tegye hatékonyabbá cégét egyedi mobil fejlesztéssel, Így kiaknázva lehetőségeket."/>
                    <meta property="og:title" content="Mobilfejlesztés | Szoftverfejlesztés | SzuniSOFT"/>
                    <meta property="og:description" content="Manapság a mobil kiemelkedő szerepet tölt be a gazdaság minden területén. Tegye hatékonyabbá cégét egyedi mobil fejlesztéssel, Így kiaknázva lehetőségeket."/>
                    <meta property="og:image" content={DynamicLocation.build(MobileOgImage.src)}/>
                </Helmet>

                <LoadGently>
                    <ScrollToTopOnMount/>
                    <Heading icon={faMobileAlt}
                             heading="Mobilfejlesztés"
                             breadCrumb={[
                                 {text: 'Szolgáltatások', to: '/szolgaltatasok'},
                                 {text: 'Szoftverfejlesztés', to : '/szolgaltatasok/szoftverfejlesztes'},
                                 'Mobilfejlesztés'
                             ]}
                    />
                    <div
                        className={extractClassNames("d-flex flex-column flex-grow-1", appCss) + ' ' + softwareCss['software'] + ' ' + serviceCss['service']}>

                        <LoaderParallax {...require('./assets/mobile-2.jpg')}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                                        style={parallaxStyle}
                        />

                        <div className={extractClassNames('container py-5', appCss)}>
                            <h2>Egyedi fejlesztések</h2>
                            <p className={serviceCss['subheading']}><i>Te hozod az ötletet, mi adjuk a megoldást!</i>
                            </p>
                            <p>Csapatunk az agilis módszertan elvek mentén fejleszt, amely során igyekszünk minél jobban
                                bevonni a megrendelőt is. A folyamatos visszajelzésekkel tudjuk garantálni, hogy végül
                                azt adjuk át amire a kliens is gondolt.
                            </p>

                        </div>

                        <LoaderParallax {...require('./assets/mobile-1.jpg')}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                                        style={{height: '40vw', minHeight: '400px'}}
                        />

                        <div className={extractClassNames('container py-5', appCss)}>
                            <h2>Teljeskörű fejlesztés</h2>
                            <p className={serviceCss['subheading']}><i>A szoftver elkészítésével még korántsem ér véget
                                a munka!</i></p>
                            <p>A piacra lépés során is segítséget nyújtunk. Megfelelően beállítjuk az alkalmazás boltok
                                oldalait, valamint kiépítünk automatizált frissítési folyamatokat is.
                                Ezek után se hagyjuk, hogy vakon repülj. Tudjuk milyen fontos látni, hogy a kiadott
                                alkalmazás hogyan teljesít, ezért különböző analitikai és monitorozó technológiákat is
                                integrálunk megoldásunkba.
                            </p>
                        </div>

                        <LoaderParallax {...require('./assets/mobile-3.jpg')}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                                        style={{height: '40vw', minHeight: '400px'}}
                        />

                        <section className={serviceCss['inverse']}>
                            <div className={extractClassNames('container py-5', appCss)}>
                                <h2>Céges ökoszisztéma megtámogatása</h2>
                                <p className={serviceCss['subheading']}><i>Ne hagyja, hogy versenytársa egy mobil alkalmazás
                                    miatt legyen az első!</i></p>
                                <p className={appCss['pb-5']}>Manapság a mobil kiemelkedő szerepet tölt be a gazdaság minden
                                    területén, legyen az B2C
                                    vagy B2B kapcsolat.
                                    Aki nem akar lemaradni ezen a gyorsan fejlődő piacon, annak szüksége lesz egy olyan
                                    mobil szoftverre, melynek minősége és megjelenése egyben tükrözi magát a márkát is. </p>
                            </div>
                        </section>

                        <Platforms active="mobile"/>

                        <section>
                            <div className={extractClassNames('pt-5 py-md-5', appCss)}>
                                <div className={appCss['container']}>
                                    <div>
                                        <h4 className={extractClassNames('my-5 text-center', appCss)}>Vegye fel velünk a kapcsolatot
                                            bátran!</h4>
                                    </div>
                                    <ToggleAnimation
                                        animationClassName={extractClassNames('slide-bottom row', appCss)}
                                        togglePercent={10}>
                                        <div
                                            className={extractClassNames('col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3', appCss)}>
                                            <div id="contactCard" className={extractClassNames('bg-white', appCss)}>
                                                <ContactForm/>
                                            </div>
                                        </div>
                                    </ToggleAnimation>
                                </div>
                            </div>
                        </section>
                    </div>
                </LoadGently>
            </React.Fragment>
        );
    }
}

Mobile.propTypes = {};

export default Mobile;
