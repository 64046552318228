import React from 'react';
import PropTypes from 'prop-types';
import Status from "../Status";
import appCss from "../../App.module.scss";
import {extractClassNames} from "../../helpers/ExtractClassNames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAlignLeft, faGlobe, faMapSigns} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import css from "./NotFound.module.scss";
import svg from "../../assets/figures/logo-background.svg";
import ReactSVG from "react-svg";
import LoadGently from "../../layout/LoadGently";

class NotFound extends React.PureComponent {
    render() {
        return (
            <Status code={404}>
                {this.props.content || (
                    <LoadGently id={css['notFound']}
                        className={extractClassNames('d-flex flex-grow-1 flex-column align-content-center justify-content-center text-center text-white', appCss)}>

                        <div className={css.figure}>
                            <ReactSVG src={svg}/>
                        </div>

                        <div className={css.content}>
                            <h1 className={appCss['my-3']}>&lt;404&gt;</h1>
                            <div className={extractClassNames('py-3 my-3 bg-white text-dark', appCss)}>
                                <p className={appCss['py-3']}>Hupsz. Ez nincs meg!</p>
                                <div className={extractClassNames('d-flex justify-content-center', appCss)}>
                                    <div className={css.navigation}>
                                        <Link to="/">
                                            <div>
                                                <FontAwesomeIcon icon={faGlobe} size={"2x"}/>
                                            </div>
                                            <div>
                                                főoldal
                                            </div>
                                        </Link>
                                        <FontAwesomeIcon icon={faMapSigns} size={"6x"}/>
                                        <Link to="/hirek">
                                            <div>
                                                <FontAwesomeIcon icon={faAlignLeft} size={"2x"}/>
                                            </div>
                                            <div>
                                                hírek
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <h1 className={appCss['my-3']}>&lt;/404&gt;</h1>
                        </div>
                    </LoadGently>
                )}
            </Status>
        );
    }
}

NotFound.propTypes = {
    content: PropTypes.element
};

export default NotFound
