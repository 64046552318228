import React, {Component} from 'react';
import PropTypes from 'prop-types';
import css from "./About.module.scss";
import appCss from "../../App.module.scss";
import ScrollToTopOnMount from "../../helpers/ScrollToTopOnMount";
import ReactSVG from "react-svg";
import Logo from "../../assets/icon-logo.svg";
import ScrollThresholdTrigger from "../../shared/ScrollThresholdTrigger";
import {faUsers} from "@fortawesome/free-solid-svg-icons";
import Heading from "../../layout/Heading";
import {Helmet} from "react-helmet";
import {extractClassNames} from "../../helpers/ExtractClassNames";
import ToggleAnimation from "../../shared/ToggleAnimation";
import ContactForm from "../../shared/ContactForm";

const People = [
    {
        avatar: require('./assets/avatars/peter.jpg'),
        qrcode: require('./assets/qr-codes/peter.png'),
        name: "Szunomár Péter",
        email: "peter@szunisoft.hu",
        title: "Ügyvezető",
        subTitle: "Üzemeltetés, Felhő, Hálózat, Adatbázis, Tanácsadás",
        description: (
            <React.Fragment>
                Hálózat és felhő specialista, vezető rendszergazda.
                Felelős az üzemeltetési rétegek kialakításáért, működéséért és biztonságáért.
                Feladata a korszerű és költséghatékony technológiák, eszközök felkutatása és rendszerbe illesztése,
                valamint az ügyfélkommunikációs csatornák felügyelete.
            </React.Fragment>
        )
    },
    {
        avatar: require('./assets/avatars/daniel.jpg'),
        qrcode: require('./assets/qr-codes/daniel.png'),
        name: "Szunomár Dániel",
        email: "szunodan@szunisoft.hu",
        title: "Vezető fejlesztő",
        subTitle: "Frontend, Google Ads, Analytics, Menedzsment",
        description: "Fejlesztések tervezésért és kivitelezésért felelős. Fejlesztő ismeretei és kiemelkedő kommunikációs készségeiből adódóan megszünteti a kommunikációs nehézségeket a két oldal között"
    },
    {
        avatar: require('./assets/avatars/akos.jpg'),
        qrcode: require('./assets/qr-codes/akos.png'),
        name: "Gajdátsy Ákos",
        email: "akos.gajdatsy@szunisoft.hu",
        title: "Mobil fejlesztési vezető",
        subTitle: "Android, iOS, DevOps, Architect",
        description: "Felelőssége a legjobb mobil felhasználói élmény nyújtása friss és letisztult dizájn elvek mentén. Folyamatosan követi és alkalmazza a legújabb trendeket egyaránt a kinézetben és a felhasznált technológiákban is."
    },
    {
        avatar: require('./assets/avatars/bence.jpg'),
        qrcode: require('./assets/qr-codes/bence.png'),
        name: "Forgács Bence",
        email: "bence.forgacs@szunisoft.hu",
        title: "Üzleti intelligencia",
        subTitle: "Backend, UI, UX, Grafika, DevOps, Architect",
        description: "Üzleti intelligencia specialista, vezető backend fejlesztő, architektúra konzultáns, UI és UX támogató. Mindig a leghatékonyabb és hosszútávon legjobb megoldásra törekszik. Időben megelőzi a problémákat így meggátolva az időbeni csúszásokat."
    }
];

class Person extends Component {

    stateToClassName = (isIn, isOut) => {
        return isIn ? 'in' : isOut ? 'out' : '';
    };

    render() {
        return (
            <React.Fragment>
                <ScrollThresholdTrigger triggerPercent={15}>
                    {({isIn, isOut}) => <div className={css['person']}>
                        <div className={appCss['container']}>
                            <div className={appCss['row']}>
                                <div
                                    className={extractClassNames('col-12 py-5 col-lg-6 text-center d-flex align-items-center flex-column justify-content-center', appCss)}
                                >
                                    <div className={css['pulled-right']}>
                                        <div
                                            className={css['avatar-wrapper']}>
                                            <div

                                                className={css['avatar'] + ' ' + extractClassNames('flex-shrink-0', appCss) + ' ' + extractClassNames('scale-bottom-right', appCss) + " " + this.stateToClassName(isIn, isOut)}>
                                                <img src={this.props.person.avatar} alt={this.props.person.name}/>
                                            </div>
                                            <div className={css['logo-wrapper']}>
                                                <ReactSVG src={Logo}
                                                          svgClassName={css['logo'] + ' ' + extractClassNames('slide-left', appCss) + " " + this.stateToClassName(isIn, isOut)}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={"slide-bottom " + this.stateToClassName(isIn, isOut) + ' ' + extractClassNames('pt-5', appCss)}>
                                        <h3 className={css['name']}>{this.props.person.name}</h3>
                                        <h5 className={css['title']}>{this.props.person.title}</h5>
                                        <h6 className={css['title']}>{this.props.person.subTitle}</h6>
                                    </div>
                                </div>
                                <div className={extractClassNames('col-12 d-none d-lg-block py-5 col-lg-6', appCss)}>
                                    <div
                                        className={css['qr'] + ' ' + extractClassNames('p-4 d-flex text-center text-lg-left slide-right', appCss) + " " + this.stateToClassName(isIn, isOut)}>
                                        <p className={extractClassNames('flex-grow-1 m-0 text-justify pr-4', appCss) + ' ' + extractClassNames('description', css)}>
                                            {this.props.person.description}
                                        </p>
                                        <div className={extractClassNames('flex-grow-1', appCss)}>
                                            <img src={this.props.person.qrcode} alt="QR kód"
                                                 className={extractClassNames('d-inline-block', appCss)}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={appCss['container']}>
                                <div className={appCss['row']}>
                                    <div
                                        className={extractClassNames('d-block d-lg-none col-12 col-lg-6 py-3', appCss) + ' ' + css['description-wrapper']}>
                                        <div className={"slide-right " + this.stateToClassName(isIn, isOut)}>
                                            <p className={extractClassNames('text-justify m-0', appCss) + ' ' + css['description']}>{this.props.person.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </ScrollThresholdTrigger>
            </React.Fragment>
        );
    }

}

Person.propTypes = {
    person: PropTypes.object
};

class About extends React.PureComponent {

    componentDidMount() {

    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>Rólunk | SzuniSOFT</title>
                    <meta name="description"
                          content="Fontosnak tartjuk ügyfeleinkkel való jó kapcsolatot. Ehhez elengedhetetlen, hogy megismerjük egymást. Kezdő lépésnek szánuk a rólunk szóló oldalt."/>
                    <meta name="keywords"
                          content="Történetünk,Cég,Eredet,Tulajdonosok,Szerepkörök,Arcképek,Névjegykártya,Elérhetőség"/>
                    <meta property="og:title" content="Rólunk | SzuniSOFT"/>
                    <meta property="og:description"
                          content="Fontosnak tartjuk ügyfeleinkkel való jó kapcsolatot. Ehhez elengedhetetlen, hogy megismerjük egymást. Kezdő lépésnek szánuk a rólunk szóló oldalt."/>
                </Helmet>
                <ScrollToTopOnMount/>
                <Heading heading="Rólunk" icon={faUsers}/>
                <div id={css['about']}>
                    <div className={extractClassNames('py-5 bg-primary text-white overflow-hidden', appCss)}>
                        <div className={appCss['container'] + ' ' + css['text']}>
                            <div className={extractClassNames('slide-bottom in text-justify fs-1-2', appCss)}>
                                <p>A 2008 óta folyó drasztikus gazdasági átalakulások nyomán a mikro-, kis- és
                                    középvállalkozásoknak is merőben új kihívásokkal kell szembenézniük. A beszűkülő
                                    piaci
                                    lehetőségek hatására a nagyvállalatok olyan szegmensekben is megjelennek, melyek
                                    addig
                                    érdektelenek voltak számukra. A többnyire intuitív módon vezetett magyar KKV-k
                                    életét
                                    nem csupán a külső források megszűnése nehezíti. Versenytársaik eszköztárában
                                    rendkívüli
                                    pontosságú elemző-, döntéstámogató szoftverek, marketing technológiák segítik a piac
                                    hatékony bekebelezését.</p>
                                <p>Cégünk azzal a határozott céllal jött létre, hogy a nagyvállalatok fegyvertárát tegye
                                    elérhetővé akár a legkisebbek számára is. Munkatársaink több éves hazai és
                                    nemzetközi
                                    tapasztalattal, gondosan válogatott technológiákkal segítik hozzá az Ön
                                    vállalkozását a
                                    növekedéshez. Legyen szó átfogó üzleti stratégiáról, válságmenedzselésről vagy
                                    csupán
                                    egy rövid kommunikációs kampányról, mi Önre szabjuk az optimális megoldást.</p>
                                <p>Ám a stratégia nem minden esetben elegendő. Sokszor a legjobb ötlet, a
                                    legaprólékosabban
                                    kidolgozott terv is kevés, ha a megvalósítás nem elég szakszerű és alapos. Kiváló
                                    szakembereink akár támogatóként, akár végrehajtóként is szavatolhatják a projektek
                                    sikerét. Így az Ön vállalkozására szabott számítástechnikai rendszer, kommunikációs
                                    kampány, reklámeszköz, vállalatirányítási szoftver vagy kontrolling metódus a
                                    projekt
                                    kivitelezési és fenntartási fázisában is megfelelő szakmai támogatásban
                                    részesülhet.</p>
                            </div>
                        </div>
                    </div>
                    <div className={css['people']}>
                        <div className={appCss['row']}>
                            {People.map(p =>
                                <div className={appCss['col-12'] + ' ' + css['person-wrapper']} key={p.name}><Person
                                    person={p}/></div>
                            )}
                        </div>
                    </div>

                    <div className={extractClassNames('bg-dark pt-5 py-md-5 text-white', appCss)}>
                        <div className={appCss['container']}>
                            <div>
                                <h4 className={extractClassNames('my-5 text-center', appCss)}>Vegye fel velünk a
                                    kapcsolatot
                                    bátran!</h4>
                            </div>
                            <ToggleAnimation
                                animationClassName={extractClassNames('slide-bottom row', appCss)}
                                togglePercent={10}>
                                <div
                                    className={extractClassNames('col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3', appCss)}>
                                    <div id="contactCard" className={extractClassNames('bg-white', appCss)}>
                                        <ContactForm/>
                                    </div>
                                </div>
                            </ToggleAnimation>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

About.propTypes = {};

export default About;
