import React, {Component} from 'react';
import PropTypes from 'prop-types';
import css from '../../App.module.scss';

class LoadGently extends Component {

    state = {
        mounted: false
    };

    componentDidMount(): void {
        this.setState({mounted: true});
    }

    render() {
        return (
            <div id={this.props.id} className={(this.state.mounted ? css['visible'] : css['invisible']) + ' ' +  (this.props.className || '')}>
                {this.props.children}
            </div>
        );
    }
}

LoadGently.propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
};

export default LoadGently;
