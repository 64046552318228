import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import createHistory from './history';
import app from './app/reducer';
import news from './news/reducer';
import server from './server/reducer';

const history = createHistory();

const rootReducer = combineReducers({
    app,
    news,
    server,
    router: connectRouter(history),
});

export default rootReducer;
