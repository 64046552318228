import React, {Component} from 'react';
import PropTypes from 'prop-types';
import css from './TechnologyViewer.module.scss';
import appCss from '../../App.module.scss';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Swipeable from "react-swipeable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import SidePanel from "../SidePanel";

class TechnologyViewer extends Component {

    static stateTypes = {
        technology: PropTypes.object
    };

    static childContextTypes = {
        setTechnology: PropTypes.func,
        subscribeTechnologyViewer: PropTypes.func,
        unsubscribeTechnologyViewer: PropTypes.func
    };

    state = {
        technology: null,
        technologyDirection: null,
    };

    setTechnology = (technology, technologyDirection) => {
        this.setState({technology, technologyDirection});
    };

    prevTechnologySubscriber = null;
    nextTechnologySubscriber = null;

    subscribe = (nextTechnologyHandler, prevTechnologyHandler) => {
        this.nextTechnologySubscriber = nextTechnologyHandler;
        this.prevTechnologySubscriber = prevTechnologyHandler;
        //this.subscribers = this.subscribers.concat(handlers)
    };

    unsubscribe = (nextTechnologyHandler, prevTechnologyHandler) => {
        this.nextTechnologySubscriber = null;
        this.prevTechnologySubscriber = null;
        //this.subscribers = this.subscribers.filter(currentHandlers => currentHandlers.prevTechnology === handlers.prevTechnology || currentHandlers.nextTechnology === handlers.nextTechnology);
    };

    getChildContext() {
        return {
            setTechnology: this.setTechnology,
            subscribeTechnologyViewer: this.subscribe,
            unsubscribeTechnologyViewer: this.unsubscribe
        };
    }

    nextTechnology = () => {
        if (this.nextTechnologySubscriber) {
            this.nextTechnologySubscriber();
        }
        //this.subscribers.forEach(handlers => handlers.nextTechnology())
    };

    prevTechnology = () => {
        if (this.prevTechnologySubscriber) {
            this.prevTechnologySubscriber();
        }
        //this.subscribers.forEach(handlers => handlers.prevTechnology())
    };

    render() {
        return (
            <React.Fragment>
                <SidePanel isActive={this.state.technology != null}
                           id="technologyViewer"
                           className={css["technology-panel"]}
                           onExit={this.setState.bind(this, {technology: null})}
                           title={(
                               <ReactCSSTransitionGroup
                                   className={appCss["position-relative"] + " " + css["technology-wrapper"] + " " + css["full-size"]}
                                   component="div"
                                   transitionName={{
                                       enter: (this.state.technologyDirection === 'left' ? css["slide-left-e"] : css["slide-right-e"]),
                                       enterActive: css.in,
                                       leave: (this.state.technologyDirection === 'right' ? css["slide-left-e"] : css["slide-right-e"]),
                                       leaveActive: css.out
                                   }}
                                   transitionEnterTimeout={500}
                                   transitionLeaveTimeout={500}>
                                   <h3 className={appCss["mb-0"] + " " + appCss["text-nowrap"] + " " + css["technology-title"]}
                                       key={(this.state.technology ? this.state.technology.name : null)}>
                                       {this.state.technology ? this.state.technology.name : null}
                                       {(this.state.technology && this.state.technology.subTitle) ? (<small
                                           className={css["technology-sub-title"]}>{this.state.technology.subTitle}</small>) : null}
                                   </h3>
                               </ReactCSSTransitionGroup>
                           )}>
                    <div className={css["technology-content"]}>

                        <Swipeable onSwipedLeft={this.nextTechnology} onSwipedRight={this.prevTechnology}
                                   className={css["technology-selection-wrapper"]}>
                            <div className={css.left + ' color-bg-theme'} onClick={this.prevTechnology}>
                                <FontAwesomeIcon icon={faChevronLeft} size={"2x"}/>
                            </div>

                            <div className={css["technology-wrapper"] + " " + css["icon-wrapper"]}>
                                <ReactCSSTransitionGroup
                                    className={css.icon}
                                    component="div"
                                    transitionName={{
                                        enter: (this.state.technologyDirection === 'left' ? css["slide-left-e"] : css["slide-right-e"]),
                                        enterActive: css.in,
                                        leave: (this.state.technologyDirection === 'right' ? css["slide-left-e"] : css["slide-right-e"]),
                                        leaveActive: css.out,
                                    }}
                                    transitionEnterTimeout={500}
                                    transitionLeaveTimeout={500}>

                                    {(this.state.technology ? (
                                        <div className={css["image-container"]} key={this.state.technology.name}>
                                            <img src={(this.state.technology ? this.state.technology.img : '')}
                                                 alt={this.state.technology.name}/>
                                        </div>
                                    ) : null)}

                                </ReactCSSTransitionGroup>
                            </div>

                            <div className={css.right + ' color-bg-theme'} onClick={this.nextTechnology}>
                                <FontAwesomeIcon icon={faChevronRight} size={"2x"}/>
                            </div>
                        </Swipeable>

                        <div
                            className={css["technology-wrapper"] + " " + appCss["flex-column"] + " " + appCss["d-flex"] + " " + appCss["flex-grow-1"]}>
                            <ReactCSSTransitionGroup
                                component="div"
                                className={appCss["text-justify"] + " " + appCss["d-flex"] + " " + appCss["flex-grow-1"] + " " + appCss["flex-shrink-0"]}
                                transitionName={{
                                    enter: (this.state.technologyDirection === 'left' ? css["slide-left-e"] : css["slide-right-e"]),
                                    enterActive: css.in,
                                    leave: (this.state.technologyDirection === 'right' ? css["slide-left-e"] : css["slide-right-e"]),
                                    leaveActive: css.out,
                                }}
                                transitionEnterTimeout={500}
                                transitionLeaveTimeout={500}>
                                <div
                                    className={appCss["px-4"] + " " + appCss["pb-4"] + (this.state.technology ? this.state.technology.name : null)}
                                    key={(this.state.technology ? this.state.technology.name : null)}>{(this.state.technology ? this.state.technology.content : '')}</div>
                            </ReactCSSTransitionGroup>
                        </div>
                    </div>
                </SidePanel>
                {this.props.children}
            </React.Fragment>
        );
    }
}

TechnologyViewer.propTypes = {};

export default TechnologyViewer;
