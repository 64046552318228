import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Parallax} from "react-parallax";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ScrollThresholdTrigger from "../../shared/ScrollThresholdTrigger";
import css from "./Index.module.scss";
import appCss from "../../App.module.scss";

const ParallaxStyle = {
    minHeight: '250px',
    height: '40vw',
    maxHeight: '475px'
};

class Topic extends Component {

    state = {
        topic: false
    };

    setTopicIcon = (to) => {
        this.setState({topic: to});
    };

    render() {
        return (
            <section className={css.topic + " " + this.props.className}>

                <div className={css["parallax-parent"]}>
                    <div className={css["parallax-parent-container"]}>
                        <Parallax
                            strength={500}
                            bgImage={this.props.parallaxImage.src}
                            bgImageSrcSet={this.props.parallaxImage.srcSet}
                            bgImageAlt={"Parallax background image"}
                        >
                            <div style={ParallaxStyle}/>
                        </Parallax>
                    </div>
                </div>

                <ScrollThresholdTrigger triggerPercent={20}
                                        onIn={this.setTopicIcon.bind(this, true)}
                                        onOut={this.setTopicIcon.bind(this, false)}>
                    <div className={css["topic-icon"]}>
                        <div className={css["bar"] + " collapse-vertical " + (this.state.topic ? 'in' : 'out')}/>
                        <div className={appCss["container"] + " " + appCss["position-relative"]}>
                            <div className={css["icon-positioning"]}>
                                <div
                                    className={css["icon-wrapper"] + " scale-center " + (this.state.topic ? "in" : "out")}>
                                    <FontAwesomeIcon icon={this.props.icon}
                                                     size={"3x"}
                                                     className={"rotate-center" + " " + (this.state.topic ? 'in' : 'out')}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </ScrollThresholdTrigger>

                <div className={appCss["overflow-hidden"]}>
                    <div className={css["content"] + " slide-top " + (this.state.topic ? 'in' : 'out')}>
                        <div className={appCss["container"] + " " + css["topic-container"]}>
                            <h5 className={css["heading"] + " " + appCss["text-uppercase"]}>{this.props.heading}</h5>
                            <div>
                                {this.props.text}
                            </div>
                        </div>
                    </div>
                </div>

                {this.props.children}

            </section>
        );
    }
}

Topic.propTypes = {
    parallaxImage: PropTypes.any.isRequired,
    icon: PropTypes.any.isRequired,
    heading: PropTypes.string.isRequired,
    text: PropTypes.element.isRequired,
    className: PropTypes.string
};

export default Topic;
