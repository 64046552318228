import React, {Component} from 'react'
import css from "./Index.module.scss"
import appCss from "../../App.module.scss";
import ReactSVG from 'react-svg';
import CodeSuggester from "../../shared/CodeSuggester";
import {Background, Parallax} from "react-parallax";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faAt,
    faChevronDown,
    faChevronRight,
    faCode,
    faCog,
    faDesktop,
    faMobileAlt,
    faNetworkWired,
    faPhone,
    faWindowMaximize
} from '@fortawesome/free-solid-svg-icons'
import Topic from "./Topic";
import SoftwareFigure from "../../assets/figures/figure-1.svg";
import ServicingFigure from "../../assets/figures/figure-2.svg";
import NetworkingFigure from "../../assets/figures/figure-3.svg";
import ToggleAnimation from "../../shared/ToggleAnimation";
import ContactForm from "../../shared/ContactForm";
import {TechnologiesList} from "./Technologies";
import ScrollToTopOnMount from "../../helpers/ScrollToTopOnMount";
import AnimatedLogo from "./AnimatedLogo";
import {extractClassNames} from "../../helpers/ExtractClassNames";
import {Helmet} from "react-helmet";
import LoadGently from "../../layout/LoadGently";
import {Link} from "react-router-dom";
import Countdown from "../../layout/Countdown";
import TechnologyList from "../../shared/Technologies/TechnologyList";

const Codes = [
    {
        code: "businessIntelligence()",
        typedCount: 8,
        suggestAtCount: 8
    },
    {
        code: "softwareDevelopment()",
        typedCount: 8,
        suggestAtCount: 8
    },
    {
        code: "networkBuilding()",
        typedCount: 7,
        suggestAtCount: 7
    },
    {
        code: "cloudSolutions()",
        typedCount: 5,
        suggestAtCount: 5
    },
    {
        code: "microServices()",
        typedCount: 5,
        suggestAtCount: 5
    }
];

const CodeImage = require('../../assets/parallax/code.jpg?quality=85');
const AnalyticsImage = require('../../assets/parallax/analytics.jpg?quality=85');
const NetworksImage = require('../../assets/parallax/network.jpg?quality=85');

class Index extends Component {

    countdownTimer = null;
    scrollInterval = null;

    scrollBehavior;

    componentDidMount(): void {
        this.setState({loaded: true});

        if (typeof window !== "undefined" &&
            this.countdownTimer === null &&
            this.state.autoScroll === false) {
            this.countdownTimer = setTimeout(() => this.setState({showCountdown: true}), 10000);

            window.addEventListener('mousedown', this.clearAutoScroll);
            window.addEventListener('scroll', this.clearAutoScroll);
            window.addEventListener('touchstart', this.clearAutoScroll);
        }
    }

    componentWillUnmount(): void {
        window.removeEventListener('mousedown', this.clearAutoScroll);
        window.removeEventListener('touchstart', this.clearAutoScroll);
        window.removeEventListener('scroll', this.clearAutoScroll);
    }

    state = {
        selectedPlatform: null,
        technology: null,
        technologyIndex: 1,
        technologyDirection: null,
        loaded: false,
        showCountdown: false,
        autoScroll: false,
        scrollDirection: 'down'
    };

    setPlatform = (to) => {
        if (to === this.state.selectedPlatform) {
            to = null;
        }
        this.setState({selectedPlatform: to});
    };

    hideCountdown = () => {
        this.setState({showCountdown: false});
    };

    clearAutoScroll = () => {

        window.removeEventListener('mousedown', this.clearAutoScroll);
        window.removeEventListener('touchstart', this.clearAutoScroll);
        window.removeEventListener('scroll', this.clearAutoScroll);

        if (this.countdownTimer) {
            clearTimeout(this.countdownTimer);
            this.countdownTimer = null;
        }

        if (this.scrollInterval) {
            clearInterval(this.scrollInterval);
            this.scrollInterval = null;
        }

        this.setState({autoScroll: false});
        this.hideCountdown();
    };

    autoScroll = () => {
        this.hideCountdown();
        this.setState({autoScroll: true});
        window.removeEventListener('scroll', this.clearAutoScroll);

        this.scrollInterval = setInterval(() => {

            if (window.scrollY >= document.body.scrollHeight - window.screen.availHeight) {
                this.setState({scrollDirection: 'up'});
            } else if (window.scrollY === 0) {
                this.setState({scrollDirection: 'down'});
            }

            if (this.state.scrollDirection === 'up') {
                window.scrollTo({top: window.scrollY - 1, behavior: "auto"})
            } else {
                window.scrollTo({top: window.scrollY + 1, behavior: "auto"})
            }

        }, 1);
    };

    render() {

        let defaultPlatformContent = !!this.state.selectedPlatform
            ? ''
            : (
                <div className={css["default-platform"] + ' ' + appCss['py-5']}>
                    <div className={css["default-platform-container"]}>
                        <h4 className={css["default-platform-code-suggester"]}>
                            <span>&nbsp;</span>
                            <CodeSuggester codes={[
                                {code: "Válasszon platformot!"}
                            ]}
                                           defaultClass={appCss["text-grey-400"]}
                                           highlightedClass={appCss["text-grey"]}
                                           avgTypingSpeed={250}
                                           postSuggestionDelay={750}
                                           delayBetweenCodes={1500}
                                           initialDelay={1000}/>
                        </h4>
                    </div>
                </div>
            );

        return (
            <LoadGently>

                <Helmet>
                    <title>SzuniSOFT</title>
                    <meta name="description"
                          content="Folyamatosan frissülő tartalommal szoftverfejlesztés és rendszer üzemeltetés témakörökben! Tekintse meg kész termékeinket, vagy kérjen egyedi ajánlatot!"/>
                    <meta name="keywords"
                          content="Szoftverfejlesztés,Webfejlesztés,Mobilfejlesztés,Üzemeltetés,Hálózat,Felhő,Mobil,App,Egyedi,Intelligencia"/>
                    <meta property="og:title" content="SzuniSOFT"/>
                    <meta property="og:description"
                          content="Folyamatosan frissülő tartalommal szoftverfejlesztés és rendszer üzemeltetés témakörökben! Tekintse meg kész termékeinket, vagy kérjen egyedi ajánlatot!"/>
                </Helmet>

                <div className={appCss["flex-grow-1"]}>
                    <ScrollToTopOnMount/>
                    <div id="siteCover"/>

                    {
                        this.state.showCountdown
                            ? <div
                                className={css["countdown-wrapper"] + ' fade in'}>
                                <div
                                    className={extractClassNames('scale-center in d-flex flex-column align-items-center', appCss)}>
                                    <div>
                                        <Countdown start={3}
                                                   className={css['countdown']}
                                                   onComplete={this.autoScroll}/>
                                    </div>
                                    <p className={extractClassNames('py-3 text-white', appCss)}>Az automatikus tekerés
                                        hamarosan elindul..</p>
                                </div>
                            </div>
                            : null
                    }

                    {
                        this.state.autoScroll === true
                            ?
                            <div className={css["scrolling-toast"] + ' ' + extractClassNames('slide-right in', appCss)}>
                                <div>
                                    <small>automatikus mozgás</small>
                                </div>
                                <div
                                    className={extractClassNames('btn btn-block', appCss) + ' ' + css['btn']}>kattintson
                                </div>
                                <div>
                                    <small>a leállásért!</small>
                                </div>
                            </div>
                            : null
                    }

                    {/* WELCOME CONTENT */}

                    <div className={css.welcome}>

                        <div className={css["welcome-wrapper"]}>
                            <AnimatedLogo>
                                <div className={css["welcome-code-suggestion-wrapper"]}>
                                    <div className={css["welcome-code-suggestion"] + " " + css["roboto"]}>
                                        <span className={appCss["text-primary"]}>SzuniSOFT</span>
                                        <span className={appCss["text-grey"]}>.</span>
                                        <CodeSuggester
                                            defaultClass={appCss["text-grey-400"]}
                                            highlightedClass={appCss["text-grey"]}
                                            avgTypingSpeed={250}
                                            postSuggestionDelay={750}
                                            delayBetweenCodes={3000}
                                            initialDelay={5300}
                                            codes={Codes}
                                        />
                                    </div>
                                </div>
                            </AnimatedLogo>
                        </div>

                        <div className={css["continue-mark-wrapper"]}>
                            <div className={css["continue-mark-in-wrapper"]}>
                                <FontAwesomeIcon className={css["continue-mark"]}
                                                 icon={faChevronDown}
                                                 size={"6x"}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Software Development Section */}
                    <Topic parallaxImage={CodeImage}
                           icon={faCode}
                           heading={"szoftverfejlesztés"}
                           className={css["software-development"]}
                           text={(
                               <React.Fragment>
                                   <p className={appCss['text-justify']}>
                                       A kifejezés önmagában rendkívül szerteágazó, ettől függetlenül manapság nagyon
                                       felkapott és sokszor emlegetett. Mindig fontos tisztázni milyen platformra milyen
                                       nyelven milyen technikákkal kerül megvalósításra egy szoftver. Ezek a faktorok
                                       mind időre,
                                       hatékonyságra és nem utolsó sorban az árra is hatással vannak.
                                   </p>
                                   <p className={appCss['text-justify']}>
                                       Bármilyen projektről is legyen szó, az esetek többségében a megvalósításuk
                                       számtalan formában elképzelhető. Mi segítünk szakértelmünkkel meghatározni a
                                       megvalósulási lehetőségeket és azok közül is kiválasztani a legmegfelelőbbet,
                                       legyen szó mobil, web vagy asztali alkalmazásról vagy ezek komplex
                                       összességeiről!
                                   </p>

                                   <div className={extractClassNames('text-center mt-5 button-perspective', appCss)}>
                                       <Link to="/szolgaltatasok/szoftverfejlesztes"
                                             className={extractClassNames('btn p-3', appCss) + ' ' + extractClassNames('btn software-btn', css)}>
                                           <span>bővebben</span>
                                           <FontAwesomeIcon icon={faChevronRight}/>
                                       </Link>
                                   </div>
                               </React.Fragment>
                           )}
                    >

                        <ToggleAnimation
                            className={css["platform-selector"] + " " + (this.state.selectedPlatform !== null ? css["active"] : '')}
                            animationClassName={"slide-bottom"}
                            togglePercent={10}
                        >
                            <div className={css["platform-selector-menu"]}>
                                <div className={appCss["container"] + " " + appCss["flex-grow-0"]}>
                                    <div className={appCss["row"]}>
                                        <div
                                            className={css["platform-selector-menu-item"] + " " + (this.state.selectedPlatform === 'mobile' ? css["active"] : '')}
                                            onClick={this.setPlatform.bind(this, 'mobile')}>
                                            <div className={css.circle}>
                                                <FontAwesomeIcon icon={faMobileAlt} size={"3x"}/>
                                            </div>
                                            <span className={css["platform-selector-menu-item-text"]}>mobil</span>
                                        </div>
                                        <div
                                            className={css["platform-selector-menu-item"] + " " + (this.state.selectedPlatform === 'pc' ? css["active"] : '')}
                                            onClick={this.setPlatform.bind(this, 'pc')}>
                                            <div className={css.circle}>
                                                <FontAwesomeIcon icon={faDesktop} size={"3x"}/>
                                            </div>
                                            <span className={css["platform-selector-menu-item-text"]}>PC</span>
                                        </div>
                                        <div
                                            className={css["platform-selector-menu-item"] + " " + (this.state.selectedPlatform === 'web' ? css["active"] : '')}
                                            onClick={this.setPlatform.bind(this, 'web')}>
                                            <div className={css.circle}>
                                                <FontAwesomeIcon icon={faWindowMaximize} size={"3x"}/>
                                            </div>
                                            <span className={css["platform-selector-menu-item-text"]}>web</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {defaultPlatformContent}

                            <div className={css["platform-content"]}>

                                <div
                                    className={css["platform"] + " " + css["mobile"] + " " + (this.state.selectedPlatform === 'mobile' ? css["active"] : '')}>
                                    <div className={css["platform-container"]}>
                                        <h4 className={css["platform-text"] + " slide-left " + (this.state.selectedPlatform === 'mobile' ? 'in' : 'out')}>
                                            Manapság a mobil kiemelkedő szerepet tölt be a gazdaság minden területén,
                                            legyen az B2C vagy B2B kapcsolat. Aki nem akar lemaradni ezen a gyorsan
                                            fejlődő piacon, annak szüksége lesz egy olyan mobil szoftverre, melynek
                                            minősége és megjelenése egyben tükrözi magát a márkát is.
                                        </h4>
                                        <h4 className={css["platform-text"] + " slide-left " + (this.state.selectedPlatform === 'mobile' ? 'in' : 'out')}>
                                            A piacra lépés során is segítséget nyújtunk. Megfelelően beállítjuk az
                                            alkalmazás boltok oldalait, valamint kiépítünk automatizált frissítési
                                            folyamatokat is. Ezek után se hagyjuk, hogy vakon repülj. Tudjuk milyen
                                            fontos látni, hogy a kiadott alkalmazás hogyan teljesít, ezért különböző
                                            analitikai és monitorozó technológiákat is integrálunk megoldásunkba.
                                        </h4>

                                        <div className={extractClassNames('text-center button-perspective', appCss)}>
                                            <Link to="/szolgaltatasok/szoftverfejlestes/mobil"
                                                  className={extractClassNames('btn p-3', appCss) + ' ' + extractClassNames('btn software-platform-btn', css)}>
                                                <span>bővebben</span>
                                                <FontAwesomeIcon icon={faChevronRight}/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={css["platform"] + " " + css["pc"] + " " + (this.state.selectedPlatform === 'pc' ? css["active"] : '')}>
                                    <div className={css["platform-container"]}>
                                        <h4 className={css["platform-text"] + " slide-left " + (this.state.selectedPlatform === 'pc' ? 'in' : 'out')}>
                                            Mikor a web kereteiből ki kell törni, vagy csupán a helyi maximális
                                            teljesítmény elérése az elsődleges, akár offline megoldásokban gondolkozunk
                                            egy asztali alkalmazás megoldást jelenthet!
                                        </h4>
                                        <h4 className={css["platform-text"] + " slide-left " + (this.state.selectedPlatform === 'pc' ? 'in' : 'out')}>
                                            Lépjen velünk kapcsolatba, hogy kihozhassuk a legtöbbet asztali eszközeiből!
                                        </h4>

                                        <div className={extractClassNames('text-center button-perspective', appCss)}>
                                            <Link to="/szolgaltatasok/szoftverfejlestes/asztali"
                                                  className={extractClassNames('btn p-3', appCss) + ' ' + extractClassNames('btn software-platform-btn', css)}>
                                                <span>bővebben</span>
                                                <FontAwesomeIcon icon={faChevronRight}/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className={css["platform"] + " " + css["web"] + " " + (this.state.selectedPlatform === 'web' ? css["active"] : '')}>
                                    <div className={css["platform-container"]}>
                                        <h4 className={css["platform-text"] + " slide-left " + (this.state.selectedPlatform === 'web' ? 'in' : 'out')}>
                                            A web, mint platform napjaink legáltalánosabb és legelterjedtebb módja az
                                            információközlésnek. Ma már mindennapjaink részét képezi. Éppen ezért az
                                            elmúlt évek folyamán óriási fejlődésen mentek keresztül a különböző nyelvek,
                                            keretrendszerek és böngészők. Előtérbe került az úgynevezett UX - User
                                            Experience - , mely a felhasználói élményt hivatott definiálni és javítani.
                                        </h4>
                                        <h4 className={css["platform-text"] + " slide-left " + (this.state.selectedPlatform === 'web' ? 'in' : 'out')}>
                                            Ma már egy jó weboldal nem csupán a helyes működést kell megvalósítsa. Ezen
                                            túlmutató feladatai is vannak. A megfelelő felhasználói élmény elérése
                                            érdekében számos eszközt kell bevetni, valamint írott és íratlan szabályt
                                            kell követni.</h4>

                                        <div className={extractClassNames('text-center button-perspective', appCss)}>
                                            <Link to="/szolgaltatasok/szoftverfejlesztes/web"
                                                  className={extractClassNames('btn p-3', appCss) + ' ' + extractClassNames('btn software-platform-btn', css)}>
                                                <span>bővebben</span>
                                                <FontAwesomeIcon icon={faChevronRight}/>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </ToggleAnimation>

                    </Topic>

                    <div className={appCss["container"] + " " + appCss["text-right"]}>
                        <ToggleAnimation togglePercent={15} animationClassName="slide-bottom" id="figure1">
                            <ReactSVG src={SoftwareFigure}/>
                        </ToggleAnimation>
                    </div>

                    {/* Servicing Section */}
                    <Topic parallaxImage={AnalyticsImage}
                           icon={faCog}
                           className={css["servicing"]}
                           heading={"üzemeltetés"}
                           text={(
                               <React.Fragment>
                                   <p className={appCss['text-justify']}>
                                       Bármely informatikai beállítottságú cég igényel üzemeltetést és karbantartást,
                                       csupán
                                       nem biztos, hogy tud róla. Legyen szó gépekről, hálózatról vagy szoftverről,
                                       mindegyik terület
                                       igényli a folyamatos karbantartást és felügyeletet. Így elkerülhetők az egyszeri
                                       nagy
                                       kiadások, melyek megzavarhatják a megszokott cashflow-t. Ezen túl a sűrűbb
                                       karbantartások és vizsgálatok csökkentik a rendszerkiesést ami veszteséghez is
                                       vezethet akár.
                                   </p>
                                   <p className={appCss['text-justify']}>Széles skálán kínálunk megoldást különböző
                                       üzemeltetési stratégiákat igénylő
                                       projektekre. Képesek vagyunk a megrendelő igényéhez igazítani az üzemeltetési
                                       technológiákat, megoldásokat, legyen szó egyszerű vagy összetett
                                       infrastruktúráról!</p>
                                   <div className={extractClassNames('text-center my-5 button-perspective', appCss)}>
                                       <Link to="/szolgaltatasok/rendszeruzemeltetes"
                                             className={extractClassNames('btn p-3', appCss) + ' ' + extractClassNames('btn servicing-btn', css)}>
                                           <span>bővebben</span>
                                           <FontAwesomeIcon icon={faChevronRight}/>
                                       </Link>
                                   </div>
                               </React.Fragment>
                           )}
                    />

                    <div className={appCss["container"] + " " + appCss["text-right"]}>
                        <ToggleAnimation togglePercent={15} animationClassName="slide-bottom" id="figure2">
                            <ReactSVG src={ServicingFigure} svgClassName="figure"/>
                        </ToggleAnimation>
                    </div>

                    {/* Network Building */}
                    <Topic parallaxImage={NetworksImage}
                           icon={faNetworkWired}
                           className={css["network-building"]}
                           heading={"hálózatfejlesztés"}
                           text={(
                               <React.Fragment>
                                   <p className={appCss['text-justify']}>
                                       Mikor azt mondjuk, hogy hálózat, akkor több mindenre is gondolhatunk, mert ebben
                                       a
                                       körforgásban nagyon sok eszköz és technológia vesz részt. Azonban azzal mindenki
                                       tisztában van, hogy egyre nagyobb file-okkal dolgozunk a
                                       mindennapokban, így fontos, hogy rendszeresen és időben eljussanak hozzánk.
                                       Amennyiben
                                       erre nem akarunk egyre többet várni úgy a hálózatunkat is fejlesztenünk kell,
                                       hogy
                                       képes legyen a megfelelő sávszélesség lebonyolítására.
                                   </p>
                                   <p className={appCss['text-justify']}>
                                       Ami mellet nem mehetünk el szó nélkül, hogy a felhasználók a helyi eszközök által
                                       érintkeznek az internettel, mely potenciális veszélyeket hordoz magában. Jelenleg
                                       minden kormány által elismert probléma a kibertámadás. Méréseink alapján egy
                                       átlagos <i>(akár
                                       lakossági)</i> végponton napi szinten több, mint 250 betörési kísérlet törtnénik.
                                       A folyamatosan fejlődő kibertechnológiák miatt fontos, hogy tűzfalnak használt
                                       eszközeink és hálózatunk naprakész legyen.
                                   </p>
                                   <div className={extractClassNames('text-center my-5 button-perspective', appCss)}>
                                       <Link to="/szolgaltatasok/halozatfejlesztes"
                                             className={extractClassNames('btn p-3', appCss) + ' ' + extractClassNames('btn networking-btn', css)}>
                                           <span>bővebben</span>
                                           <FontAwesomeIcon icon={faChevronRight}/>
                                       </Link>
                                   </div>
                               </React.Fragment>
                           )}
                    />

                    <div className={appCss["text-right"]}>
                        <ToggleAnimation togglePercent={15} animationClassName="slide-bottom" id="figure3">
                            <ReactSVG src={NetworkingFigure} svgClassName="figure"/>
                        </ToggleAnimation>
                    </div>

                    {/* Brand Slogan */}
                    <section className={css["slogan"]}>
                        <div className={appCss["container"]}>
                            <ToggleAnimation animationClassName="slide-bottom" togglePercent={10}>
                                <p className={css["slogan-text"]}>
                                    <span className={appCss["arial"]}>"</span>
                                    &nbsp;Life is too short for imperfect solutions..&nbsp;
                                    <span className={appCss["arial"]}>"</span>
                                </p>
                            </ToggleAnimation>
                        </div>
                    </section>

                    <section className={css.technologies}>
                        <ToggleAnimation togglePercent={15} animationClassName="slide-bottom">
                            <div className={appCss["container"] + " " + appCss["text-center"]}>
                                <TechnologyList technologies={TechnologiesList}/>
                            </div>
                        </ToggleAnimation>
                    </section>

                    {/* Contact Part */}
                    <section className={extractClassNames('pt-5 pb-3', appCss)}>
                        <div className={extractClassNames('container my-5', appCss)}>
                            <div className={appCss['row']}>
                                <div className={extractClassNames('col-12 col-md-5 pb-3 pb-md-0', appCss)}>
                                    <ToggleAnimation togglePercent={15} animationClassName="slide-left">
                                        <h5 className={appCss['text-uppercase']}>kapcsolat</h5>
                                        <p className="font-weight-light">Vegye fel velünk a kapcsolatot és mi rövid időn
                                            belül
                                            válaszolunk Önnek!</p>
                                        <div className={css['contact-methods']}>
                                            <table>
                                                <tbody>
                                                <tr>
                                                    <td><FontAwesomeIcon icon={faPhone} size={"3x"}/></td>
                                                    <td><a href="tel:+36 30 963 3126" className={appCss['underlined']}>+36
                                                        30 963 3126</a>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><FontAwesomeIcon icon={faAt} size={"3x"}/></td>
                                                    <td><p>info@szunisoft.hu</p></td>
                                                </tr>
                                                {/*<tr>
                                                    <td><FontAwesomeIcon icon={faMapMarkerAlt} size={"3x"}/></td>
                                                    <td><p>1181 Budapest, Darányi Ignác u. 9</p></td>
                                                </tr>*/}
                                                </tbody>
                                            </table>
                                        </div>
                                    </ToggleAnimation>
                                </div>
                                <div className={extractClassNames('col-12 col-md-7 pt-3 pt-md-0', appCss)}>
                                    <ToggleAnimation togglePercent={15} animationClassName="slide-right">
                                        <div className={appCss['card']} id="contactCard">
                                            <div className={appCss['card-body']}>
                                                <ContactForm/>
                                            </div>
                                        </div>
                                    </ToggleAnimation>
                                </div>
                            </div>
                        </div>
                    </section>

                </div>

            </LoadGently>
        )
    }

};

export default Index
