import React, {Component} from "react";
import {Link, NavLink} from "react-router-dom";
import css from "./Header.module.scss";
import appCss from "../../App.module.scss";
import ReactSVG from "react-svg";
import IconLogo from "../../assets/icon-logo.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAlignLeft,
    faBars,
    faCode,
    faGlobe,
    faProjectDiagram,
    faSignInAlt,
    faTimes,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {faLightbulb} from "@fortawesome/free-regular-svg-icons";
import LoadGently from "../LoadGently";
import {extractClassNames} from "../../helpers/ExtractClassNames";

class Header extends Component {

    state = {
        passed: false,
        mobileActive: false
    };

    componentDidMount() {
        document.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.onScroll);
    }

    onScroll = (e) => {
        const passed = document.documentElement.scrollTop > 0;
        if (passed !== this.state.passed) {
            this.setState({passed: passed});
        }
    };

    showMobile = () => {
        this.setState({mobileActive: true});
    };

    hideMobile = () => {
        this.setState({mobileActive: false});
    };

    render() {
        return (
            <ReactCSSTransitionGroup
                component={LoadGently}
                className={css["header-wrapper"] + ' bg-theme'}
                id={this.props.id}
                transitionName={{
                    enter: 'slide-top',
                    enterActive: 'in',
                    leave: 'slide-top',
                    leaveActive: 'out'
                }}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}
                transitionEnter={true}
                transitionLeave={true}>

                <header key="header" className={(this.state.passed ? css["header-active"] : null)}>
                    {/*container p-0 d-flex*/}
                    <div className={css['header-container']}>
                        {
                            this.props.withoutLogo === true
                                ? null
                                : <div className={"icon-logo " + css.logo}>
                                    <Link to="/">
                                        <ReactSVG src={IconLogo}/>
                                    </Link>
                                </div>
                        }
                        <div
                            className={css["menu-open-indicator-wrapper"]}>
                            <a onClick={this.showMobile}
                               className={css["menu-open-indicator"]}>
                                <FontAwesomeIcon icon={faBars} size={"2x"}/>
                            </a>
                        </div>
                        <div className={css["common-menu"] + " " + (this.state.mobileActive ? css.mobile : '')}>
                            <a onClick={this.hideMobile}
                               className={css["menu-close-indicator"]}>
                                <FontAwesomeIcon icon={faTimes} size={"2x"}/>
                            </a>
                            <ul>
                                <li>
                                    <NavLink onClick={this.hideMobile} to='/' activeClassName={css["link-active"] + ' theme-inverse-color'} exact={true}>
                                        <FontAwesomeIcon icon={faGlobe} className={appCss['mr-2']}/>
                                        <span>főoldal</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.hideMobile} to='/hirek' activeClassName={css["link-active"] + ' theme-inverse-color bg-theme-lighten'}>
                                        <FontAwesomeIcon icon={faAlignLeft} className={appCss['mr-2']}/>
                                        <span>hírek</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.hideMobile} to='/szolgaltatasok' activeClassName={css['link-active'] + ' theme-inverse-color bg-theme-lighten'}>
                                        <FontAwesomeIcon icon={faLightbulb} className={appCss['mr-2']}/>
                                        <span>szolgáltatások</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.hideMobile} to='/termekek' activeClassName={css["link-active"] + ' theme-inverse-color bg-theme-lighten'}>
                                        <FontAwesomeIcon icon={faCode} className={appCss['mr-2']}/>
                                        <span>termékek</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.hideMobile} to='/projektek' activeClassName={css["link-active"] + ' theme-inverse-color bg-theme-lighten'}>
                                        <FontAwesomeIcon icon={faProjectDiagram} className={appCss['mr-2']}/>
                                        <span>projektek</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.hideMobile} to='/rolunk' activeClassName={css["link-active"] + ' theme-inverse-color bg-theme-lighten'}>
                                        <FontAwesomeIcon icon={faUsers} className={appCss['mr-2']}/>
                                        <span>rólunk</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <a href="https://portal.szunisoft.hu/" className={extractClassNames('px-lg-4', appCss)}>
                                        <FontAwesomeIcon icon={faSignInAlt} className={extractClassNames('mr-2 mr-lg-0', appCss)}/>
                                        <span className={extractClassNames('d-inline-block d-lg-none', appCss)}>bejelentkezés</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </header>

            </ReactCSSTransitionGroup>
        );
    }
}

Header.propTypes = {
    withoutLogo: PropTypes.bool,
    id: PropTypes.string
};

export default Header;
