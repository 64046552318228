import React, {Component} from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/icon-logo.svg';
import css from './IconLogoLoader.module.scss';
import ReactSVG from "react-svg";

class IconLogoLoader extends Component {

    static defaultProps = {
        style: {},
        className: '',
        inverse: false,
    };

    render() {
        return (
            <div className={css.loader + ' ' + (this.props.className || '') + ' ' + (this.props.inverse ? css.inverse : '')} style={(this.props.style || {})}>
                <ReactSVG src={logo} svgClassName={"icon-logo-loader"}/>
                {this.props.children}
            </div>
        );
    }
}

IconLogoLoader.propTypes = {
    style: PropTypes.any,
    className: PropTypes.string,
    inverse: PropTypes.bool
};

export default IconLogoLoader;
