import React, {Component} from 'react';
import LoadGently from "../../../layout/LoadGently";
import Heading from "../../../layout/Heading";
import {faGlobe, faMobileAlt, faTabletAlt} from "@fortawesome/free-solid-svg-icons";
import css from "./DigiKIP.module.scss";
import ProductTheme from "../../Products/ProductTheme";
import ScrollToTopOnMount from "../../../helpers/ScrollToTopOnMount";
import {Section} from "../../Products/Product";
import appCss from "../../../App.module.scss";
import {Helmet} from "react-helmet";
import {extractClassNames} from "../../../helpers/ExtractClassNames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TechnologyList from "../../../shared/Technologies/TechnologyList";
import {Technologies} from "../../Index/Technologies";
import LoaderParallax from "../../../layout/LoaderParallax";
import ImageLoader from "../../../shared/ImageLoader";
import AnimatedLoader from "../../../shared/AnimatedLoader";
import {DynamicLocation} from "../../../helpers/DynamicLocation";

const Section1Image = require('./assets/section-2.jpg');
const Section2Image = require('./assets/section-3.jpg');
const Section3Image = require('./assets/section-4.jpg');

const ParallaxStyle = {
    height: '50vw',
    minHeight: '500px',
};

const PreloadImages = [
    Section1Image,
    Section2Image,
    Section3Image,
];

class DigiKIP extends Component {
    render() {
        return (
            <React.Fragment>

                <Helmet>
                    <title>Digi KIP | SzuniSOFT</title>
                    <meta name="keywords"
                          content="DIGIKIP,KIP,Összetett,Oktatás,Tanár,Diák,Egyedi,Módszer,Web,Mobil"/>
                    <meta name="description"
                          content="A papír forma újjászületett digitális köntösben. Olyan tanítási módszerek olyan webes és mobil eszközökkel amit a 21. század elvár ez a DIGIKIP."/>
                    <meta property="og:title" content="Digi KIP | SzuniSOFT"/>
                    <meta property="og:description"
                          content="A papír forma újjászületett digitális köntösben. Olyan tanítási módszerek olyan webes és mobil eszközökkel amit a 21. század elvár ez a DIGIKIP."/>
                    <meta property="og:image" content={DynamicLocation.build(PreloadImages[0].src)}/>
                </Helmet>

                <LoadGently>

                    <ProductTheme productThemeId={css['project-digi-kip-product-theme']}
                                  siteThemeId={css['project-digi-kip-site-theme']}
                    >
                        <ScrollToTopOnMount/>

                        <ImageLoader multiple={PreloadImages}
                                     loading={<AnimatedLoader/>}
                                     loaded={
                                         <React.Fragment>

                                             <Heading heading="Digitális Komplex Instrukciós Program"
                                                      breadCrumb={[
                                                          {text: 'Projektek', to: '/projektek'},
                                                          'Digi KIP'
                                                      ]}
                                             />

                                             <Section themeCss={css}>

                                                 <div>
                                                     <div className={appCss['container']}>
                                                         <div
                                                             className={extractClassNames('d-flex flex-column text-center text-lg-left flex-lg-row align-items-center', appCss) + ' ' + css['meta']}>
                                                             <div className={extractClassNames('flex-grow-1', appCss)}>
                                                                 <h5 className={css['theme']}>Mi a Digi KIP?</h5>
                                                                 <ul>
                                                                     <li>Ügyfél: <b>KLIKK</b></li>
                                                                     <li>Év: <b>2018</b></li>
                                                                     <li>Időtartam: <b>3 hónap</b></li>
                                                                 </ul>
                                                             </div>

                                                             <div
                                                                 className={extractClassNames('flex-grow-0 flex-shrink-0 mt-4 mt-lg-0 mb-lg-0', appCss)}>
                                                                 <div className={css['icons']}>
                                                                     {[faGlobe, faTabletAlt, faMobileAlt].map((icon, i) => <div
                                                                         className={css['icon']}
                                                                         key={i}>
                                                                         <div className={css['icon-wrapper']}>
                                                                             <FontAwesomeIcon icon={icon}/>
                                                                         </div>
                                                                     </div>)}
                                                                 </div>
                                                             </div>

                                                         </div>

                                                     </div>

                                                     <div className={extractClassNames('py-3', appCss) + ' ' + css['bg-theme']}>
                                                         <div className={appCss['container']}>
                                                             <div>
                                                                 <TechnologyList technologies={[
                                                                     Technologies.Angular,
                                                                     Technologies.Docker,
                                                                     Technologies.Kotlin,
                                                                     Technologies.PHP,
                                                                     Technologies.Laravel
                                                                 ]}/>
                                                             </div>
                                                         </div>
                                                     </div>

                                                     <div className={appCss['container']}>

                                                         <p className={css['theme']}>A Digi KIP projekt egy már alkalmazásban lévő analóg
                                                             oktatási módszer digitalizálása. A
                                                             KIP (Komplex Instrukciós Program) lényegében különböző kompetenciák szerint 4-6
                                                             fős
                                                             heterogén csoportokba rendezi a diákokat. A módszer szerint specifikusan kell a
                                                             diákokra
                                                             meghatározni mind a csoport és egyéni feladatokat.</p>
                                                         <p className={css['theme']}>Egy 45 perces óra alapvetően négy fő fázisból épül fel.
                                                             Első körben a tanár
                                                             felfedi az úgy nevezett "Nagy Gondolatot", mely az óra témakörét és / vagy fő
                                                             kérdését veti fel.
                                                         </p>
                                                         <p className={css['theme']}>A következő fordulóban a diákok az aktuális nagy
                                                             gondolathoz kapcsolódó
                                                             csoportfeladatot kapnak. A feladatot közösen oldják meg.
                                                         </p>
                                                         <p className={css['theme']}>Ezt követően az adott csoportban szereplő diákok
                                                             mindegyike a beadott
                                                             csoportfeladattal és a témával kapcsolatos egyéni feladatával találkozik.
                                                         </p>
                                                         <p className={css['theme']}>
                                                             A beadott csoport és egyéni feladatokat követően a diákok előadják az órai
                                                             munkájukat egy pár perces idősávban. Ezt követően értékelhetik a diákok az órát.
                                                         </p>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <LoaderParallax srcSet={Section1Image.srcSet} src={Section1Image.src} parallaxStrength={500}>
                                                 <div style={ParallaxStyle}/>
                                             </LoaderParallax>

                                             <Section themeCss={css}>
                                                 <div>
                                                     <div className={appCss['container']}>
                                                         <h5 className={css['theme']}>Megrendelői igények</h5>
                                                         <p className={css['theme']}>Az ügyfél különböző megkötéseket tett, miszerint a
                                                             diákok
                                                             mindegyike egy tablet készüléket fog használni landscape (elforgatott) módban.
                                                             Ehhez
                                                             szükséges volt egy mobil / tablet alkalmazás lefejlesztése.</p>
                                                         <p className={css['theme']}>Ezen túl a rendszernek teljesen interaktívnak kellett
                                                             lennie. Valós időben kellett az információt eljuttatni a diákokhoz és az összes
                                                             szóban forgó eszközt az aktuális óra fázisához szinkronizálni (automatikusan
                                                             navigálni).</p>
                                                         <p className={css['theme']}>Azonban az automatikus navigálás nem egy vica verza
                                                             rendszer
                                                             kellett, hogy legyen. A Nagy Gondolat megjelenése után a tanár tovább engedheti
                                                             a
                                                             diákokat a csoportfeladatokra. Azonban vissza nem navigálhatók a diákok
                                                             automatikusan. Ennek oka az, hogy feltehetően egy, vagy több csoport már
                                                             megkezdte a
                                                             feladatának megoldását. Ettől függetlenül azonban a diákok képesek a Nagy
                                                             Gondolat
                                                             fázisára egyénileg visszatérni.
                                                         </p>
                                                         <p className={css['theme']}>
                                                             Összegezve az egyértelmű előre haladás automatikus, azt követően a visszafelé
                                                             történő navigálás manuális kellett, hogy legyen.
                                                         </p>
                                                         <p className={css['theme']}>
                                                             Továbbá a tanároknak is szükségük volt egy alkalmazásra, mely képes az
                                                             osztályokat,
                                                             órákat és órai sablonokat előállítani, lekezelni. Ezen felül igény volt egy
                                                             központosított portál rendszerre is, aminek elsődleges célja a különböző Digi
                                                             KIP-et
                                                             alkalmazó iskolák közötti tantárgy sablonok megosztása volt. Így lehetősége van
                                                             a
                                                             tanároknak egy centralizált helyen megosztani saját óravázlatukat más
                                                             tanárokkal,
                                                             akik szintén alkalmazhatják a sablonok egészét vagy részegységét saját órájukon.
                                                         </p>
                                                     </div>

                                                     <LoaderParallax srcSet={Section2Image.srcSet} src={Section2Image.src} parallaxStrength={500}>
                                                         <div style={ParallaxStyle}/>
                                                     </LoaderParallax>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}>
                                                 <div>
                                                     <div className={appCss['container']}>
                                                         <h5 className={css['theme']}>Digitalizálás megvalósításának feltételei</h5>
                                                         <p className={css['theme']}>Hálózattervezési szempontból fontos volt az órák
                                                             megtarthatóságának stabilitása, a távoli hozzáférés biztosítása a tanárok és az
                                                             esetlegesen otthon maradt diákok részére. Ennek megoldására a felhő és helyi (On
                                                             Premise) hibrid rendszerét készítettük el.</p>
                                                     </div>
                                                 </div>
                                             </Section>

                                             <Section themeCss={css}>
                                                 <div>

                                                     <div className={appCss['container']}>
                                                         <h5 className={css['theme']}>Valós idejű kommunikáció</h5>
                                                         <p className={css['theme']}>Minden valós idejű információáramoltatást igénylő
                                                             ökoszisztéma egyik legfontosabb komponense az erre alkalmazott technológia vagy
                                                             technológiák. Felmerült a Long Polling (BOSCH) technika bevetése is, azonban
                                                             mivel gyakorlatilag egységes célhardvereket szolgáltatott az ügyfél minden óra
                                                             megtartásához így végül a Websocket mellett tettük le a voksunkat. Egyszerűbb
                                                             volt a különböző eseményekre reagálni és kevesebb munkát is jelentett a kliens
                                                             és szerver oldalon egyaránt.
                                                         </p>
                                                         <p className={css['theme']}>Ezen túl szempont volt az is, hogy a megrendelő
                                                             minimálisra kívánta szorítani a szükséges performansz mértékét. Amennyiben egy
                                                             osztályban 28 diák vesz részt úgy a tanárral együtt ez legalább 29 aktív
                                                             kapcsolatot jelent. Amennyiben átlagban egy évfolyaman két osztály van, ez máris
                                                             48. Általános esetben pedig 4 évfolyamot kell kiszolgálni, mely így legalább
                                                             összesen 192 aktív kapcsolatot jelent.
                                                         </p>
                                                     </div>
                                                 </div>

                                                 <LoaderParallax srcSet={Section3Image.srcSet} src={Section3Image.src} parallaxStrength={500}>
                                                     <div style={ParallaxStyle}/>
                                                 </LoaderParallax>
                                             </Section>

                                             <Section themeCss={css}>
                                                 <div>
                                                     <div className={appCss['container']}>
                                                         <h5 className={css['theme']}>Alkalmazott technológiák</h5>
                                                         <p className={css['theme']}>Mivel minden iskolában helyileg kihelyezett rendszert
                                                             terveztünk meg, így elkerülhetetlen volt egy lokális monolit alkalmazáscsomag
                                                             létrehozása, mely Docker konténeres technológiával működik. Tartalmazza az
                                                             intelligencia backend részt, mely PHP, Laravel-ben került megvalósításra. Ezen
                                                             túl egy Redis RAM Cache szervert és egy MariaDB-t a relációk perzisztálásához.
                                                         </p>
                                                         <p className={css['theme']}>A fejlesztés során megfelelő szakmai és üzemeltetési
                                                             támogatást nyújtottunk az ügyfél számára. Fontos volt az elkészült helyi
                                                             alkalmazások megjelenésének minősége. Igyekeztünk előtérbe helyezni a
                                                             felhasználói élményt, ugyanis fontosnak tartottuk a felületek letisztultságát és
                                                             intuitív megjelenését a tanárok különböző korosztályára való tekintettel.
                                                         </p>
                                                     </div>
                                                 </div>
                                             </Section>
                                         </React.Fragment>
                                     }
                        />

                    </ProductTheme>

                </LoadGently>
            </React.Fragment>
        );
    }
}

DigiKIP.propTypes = {};

export default DigiKIP;
