import React from 'react';
import PropTypes from 'prop-types';
import {Route} from "react-router";

class Status extends React.PureComponent {
    render() {
        return (
            <Route
                render={({staticContext}) => {

                    if (staticContext) {
                        staticContext.status = this.props.code;
                    }

                    return (this.props.children || null);
                }}
            />
        );
    }
}

Status.propTypes = {
    code: PropTypes.number.isRequired
};

export default Status;
