import React from 'react';
import PropTypes from 'prop-types';
import appCss from "../../App.module.scss";
import {extractClassNames} from "../../helpers/ExtractClassNames";

export class Section extends React.Component {

    static defaultProps = {
        style: {}
    };

    render() {
        return (
            <section className={
                this.props.themeCss['product'] + ' ' +
                (this.props.layout ? (this.props.themeCss['layout-' + this.props.layout]) : '') + ' ' +
                (this.props.screenHeightOnLarge ? this.props.themeCss['m-s-h-lg'] : '') + ' ' +
                (this.props.inverse ? this.props.themeCss['inverse'] : this.props.themeCss[(this.props.theme ? 'theme' : '')]) + ' ' +
                (this.props.className || '')
            }
                     style={this.props.style}
            >
                {this.props.children}
            </section>
        );
    }
}

Section.propTypes = {
    themeCss: PropTypes.any.isRequired,
    layout: PropTypes.oneOf([
        'first-left-last-right',
        'first-right-last-left',
        'column'
    ]),
    theme: PropTypes.bool,
    inverse: PropTypes.bool,
    className: PropTypes.string,
    screenHeightOnLarge: PropTypes.bool,
    style: PropTypes.object
};

export class BlurryBanner extends React.Component {
    render() {
        return (
            <div className={extractClassNames('py-3 flex-grow-0', appCss)}>
                <div className={appCss['position-relative']}>
                    <div className={this.props.themeCss['blurry-banner']}>
                        <div className="" style={{
                            background: 'url(' + this.props.imageSrc + ') no-repeat center',
                            backgroundSize: 'cover'
                        }}/>
                    </div>
                    <div className={appCss['container']}>
                        <img src={this.props.imageSrc} srcSet={this.props.imageSrcSet} alt="Section Image"
                             className={extractClassNames('img-fluid w-100', appCss)}/>
                    </div>
                </div>
            </div>
        );
    }
}

BlurryBanner.propTypes = {
    themeCss: PropTypes.object.isRequired,
    imageSrc: PropTypes.string.isRequired,
    imageSrcSet: PropTypes.any,
};
