import React, {Component} from 'react';
import Loader from "./Loader";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import appCss from "../App.module.scss";
import {extractClassNames} from "../helpers/ExtractClassNames";
import LoadGently from "../layout/LoadGently";

class AnimatedLoader extends Component {

    render() {
        return (
            <ReactCSSTransitionGroup
                className={extractClassNames("justify-content-center overflow-hidden align-items-center d-flex flex-grow-1", appCss)}
                component={LoadGently}
                transitionName={{
                    enter: 'slide-top',
                    enterActive: 'in',
                    leave: 'slide-top',
                    leaveActive: 'out',
                    appear: 'slide-top',
                    appearActive: 'in'
                }}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}
                transitionAppearTimeout={500}
                transitionEnter={true}
                transitionLeave={true}
                transitionAppear={true}>
                <Loader/>
            </ReactCSSTransitionGroup>
        );
    }
}

AnimatedLoader.propTypes = {};

export default AnimatedLoader;
