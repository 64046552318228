import React from "react";
import css from "../../shared/Technologies/TechnologyViewer.module.scss";

const whenToUseClass = `${css['when-to-use']} bg-theme-lighten theme-color`;

export const Docker = {
    name: "Docker",
    subTitle: "platform, 2013",
    img: require('../../assets/technologies/docker.png'),
    content: (
        <React.Fragment>
            <p>A Docker egy virtualizációs eszköz, mely konténeres technológiára alapul. Segítségével
                hatékonyabban, biztonságosabban és gyorsabban fejleszthetők, élesíthetők és üzemeltethetők az
                alkalmazások.</p>
            <p>Számos előnye közül a legkiemelkedőbb, mely lehetővé teszi, hogy alkalmazásainkat egy ún. képben
                tároljuk. Azonban fontos kiemelni, hogy ez a kép nem összetévesztendő a virtuális gépek által
                használt képekkel. Egy képben tárolt alkalmazás elindítása csupán milliszekundumokat vesz
                igénybe, ellentétben egy virtuális géppel, melynek boot ideje is legalább másodpercekben
                mérendő.</p>
            <p>Továbbá fontos megemlíteni a portolhatóságot is, ugyanis a Docker-el kompatibilis alkalmazásokat
                költöztetni nagyon egyszerű.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Fejlesztés közben, demó és végleges projektek kialakításához.</li>
                    <li>Meglévő alkalmazások portolásához.</li>
                    <li>On premise <i>(helyileg kihelyezett)</i> rendszerek üzemeltetéséhez.</li>
                    <li>Felhőkre tervezett alkalmazások futtatásához.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const Kotlin = {
    name: "Kotlin",
    subTitle: "nyelv, 2010",
    img: require('../../assets/technologies/kotlin.png'),
    content: (
        <React.Fragment>
            <p>A Kotlin egy <b>JVM</b>-re <i>(Java Virtual Machine)</i> épülő nyelv, melyet
                a <i>Jetbrains</i> fejlesztett ki és fejleszt a mai napig. A nyelv meglehetősen új. Ebből adódóan
                friss koncepciókkal debütált a piacra. A modern felfogásnak köszönhetően a legjobb és legfontosabb
                részegységeit és koncepcióit használta fel más nyelveknek <i>(C#, Java, JavaScript, stb)</i>, így
                csökkentve a
                fejlesztők számára a bonyolult kódsorok mennyiségét és növelve a hatékonyságot.</p>
            <p>Önmagában rendelkezik saját VM-el, de alapvetően forgatható <b>Java</b> kódra. Ezen túl fordítható
                még <b>JavaScript</b>-re <i>(ECMAScript 5)</i> is, így lehetőség nyílik arra, hogy weboldalakat,
                webes rendszereket fejlesszünk Kotlinban.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Új és meglévő, Java-alapú alkalmazások létrehozásához és továbbfejlesztéséhez.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const Flutter = {
    name: "Flutter",
    subTitle: "SDK, 2015",
    img: require('../../assets/technologies/flutter.png'),
    content: (
        <React.Fragment>
            <p>A Flutter egy, a Google által létrehozott alkalmazás fejlesztői csomag <i>(SDK)</i>, mellyel
                könnyedén fejleszthető <b>Android</b> és <b>iOS</b> alkalmazás natív felhasználói élménnyel.</p>
            <p>Az eszközökbe beépített grafikus processzorok használatával akár régebbi eszközökön is elérhető az
                átlagos <b>60FPS</b> <i>- folyékony -</i> felhasználói élmény. Erre a mai napig a legtöbb natív megoldás
                sem képes.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Ideális portfóliós alkalmazások számára.</li>
                    <li>Olyan alkalmazások esetén, melyek a felhasználói élményt hivatottak előtérbe helyezni.</li>
                    <li>Demó és pilot alkalmazások gyors előállítása esetén.</li>
                    <li>Mikor nincs szükség sok platform-specifikus eszköz használatára.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const NodeJS = {
    name: "NodeJS",
    subTitle: "platform, 2009",
    img: require('../../assets/technologies/nodejs.png'),
    content: (
        <React.Fragment>
            <p>A <i>NodeJS</i> egy nyíltforráskódú, szerveroldali platform és környezet. Mára egy igen elterjedt
                platformmá nőtte ki magát. Ennek sok oka van. Elsősorban a <i>NodeJS</i> multiplatform támogatása
                miatt, másfelől pedig azért, mert <i>JavaScript</i>-alapú.</p>
            <p>A <i>Google Chrome</i>-ban implementált V8-as motort használja alapul.
                A <i>JavaScript</i> mivoltjából adódóan egy úgymond <b>non-blocking, asynchronous</b> rendszerről
                van szó, mely természeténél fogva napjainkban meglehetősen ideális <i>micro-service</i> rendszerek
                fejlesztésére.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Programozók fejlesztés során, fejlesztői eszközként.</li>
                    <li>Backend (üzleti logika) fejlesztéséhez.</li>
                    <li>Micro-service, komplex felhőkompatibilis rendszerek előállításához.</li>
                    <li>REST API kommunikációhoz.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const ReactJS = {
    name: "React",
    subTitle: "keretrendszer / könyvtár, 2013",
    img: require('../../assets/technologies/react.png'),
    content: (
        <React.Fragment>
            <p>A React, vagy React.js egy <i>JavaScript</i> könyvtár, amit a <i>Facebook</i> fejlesztett ki.</p>
            <p>Mára óriási fejlesztői közösség áll a projekt mögött. Ez köszönhető annak, hogy
                a <i>React</i> alapvetően egy könnyű és laza szerkezetű, könnyen tanulható és fejleszthető koncepcióval
                rendelkezik.</p>
            <p>Napjainkra nem csak a webet hódította meg. Lehetőség van a <i>React Native</i>-al mobilalkalmazásokat
                is készíteni.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Webes frontend <i>(UI / UX)</i> esetén.</li>
                    <li>Egyszerűtől a komplex igényekig.</li>
                    <li>SPA <i>(Single Page Application)</i> esetén.</li>
                    <li>Portfóliós oldalakhoz.</li>
                    <li>Adminisztrátori oldalakhoz.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const Lumen = {
    name: "Lumen",
    subTitle: "keretrendszer",
    img: require('../../assets/technologies/lumen.png'),
    content: (
        <React.Fragment>
            <p>A <i>Lumen</i> egy <i>PHP</i> micro webes keretrendszer. Úgy is ismerik, mint
                a <i>Laravel</i> kistestvérét.</p>
            <p>Micro-service rendszerek készítéséhez a legideálisabb <i>PHP</i>-nyelvű analógia. A titok az
                egyszerűségében és az átgondoltságában rejlik.</p>
            <p>A platform egyéb kihívó keretrendszerei között is kiemelkedő a teljesítménye. A nagyméretű munkálkodó
                közösség miatt gyors és hatékony fejlesztést tesz lehetővé.</p>
            <div className={whenToUseClass}>
                <h5>Mikor haználjuk?</h5>
                <ul>
                    <li>Micro-service backend <i>(üzleti logika)</i> fejlesztéséhez.</li>
                    <li>Ideális az olyan rendszerekhez, melyeknek az operatív teljesítési és válaszideje alacsony.</li>
                    <li>Hosszútávú üzemeltetéshez.</li>
                    <li>REST API kommunikációhoz.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const Laravel = {
    name: "Laravel",
    subTitle: "keretrendszer, 2011",
    img: require('../../assets/technologies/laravel.png'),
    content: (
        <React.Fragment>
            <p>A <i>Laravel</i> napjaink legelterjedtebb <i>PHP</i> keretrendszere. Gondosan megtervezett
                hierarchiával rendelkezik és megfelelő mértékben ad szabadságot a fejlesztők és cégek számára, hogy
                saját elképzeléseiket és megvalósítási konvencióikat követhessék.</p>
            <p>Az amúgy is gyors <i>Symfony</i> keretrendszerre épül, melyet hatékony eszközökkel turbózott fel.</p>
            <p>Alapvetően rendelkezik authentikációs sémával, migrációs modellel, ORM-el és még sorolhatnánk.</p>
            <p>A sok általa nyújtott eszköz lehetőséget ad rá, hogy a valódi üzleti problémára fókuszáljon a
                fejlesztő és kevésbé a technológiai kihívásokra.</p>
            <p>A nagy fejlesztői közösség által, egy hosszútávon támogatott keretrendszerről van szó.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Komplex, egyedi igényű monolit alkalmazások egyik legjobb alapja.</li>
                    <li>Modularizálás esetén.</li>
                    <li>Meglévő <i>PHP</i> alkalmazások portolására.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const AzureDevOps = {
    name: "Azure DevOps",
    subTitle: "platform, szolgáltatás, 2005",
    img: require('../../assets/technologies/azure-devops.png'),
    content: (
        <React.Fragment>
            <p>Az <i>Azure DevOps</i> egy Microsoft által létrehozott forráskód menedzser platform fejlesztők
                számára. Régi nevén, <i>Visual
                    Studio Team Services (VSTS)</i> volt ismert.</p>
            <p>Kiemelkedően jó eszközöket ad programozók számára a fejlesztői szerveződéshez, feladatok
                kezeléséhez.</p>
            <p>Ezen túl teljes kompatibilitást kínál a <i>Microsoft</i> elsőszámú felhőjéhez is <i>(Azure)</i>.</p>
            <p>Segítségével automatizálhatók az alkalmazások tesztelései és frissítései <i>(CI / CD)</i>.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Bármilyen projekt esetében ideális fejlesztői és DevOps eszközöket kínál.</li>
                    <li>Felhőben futó alkalmazások és szoftvercsomagok esetén.</li>
                    <li>On premise <i>(helyileg kihelyezett)</i> alkalmazások és szoftvercsomagok esetén.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const PHP = {
    name: "PHP",
    subTitle: "nyelv, 1995",
    img: require('../../assets/technologies/php.png'),
    content: (
        <React.Fragment>
            <p>A <i>PHP (Hypertext Preprocessor)</i> egy szerveroldali scriptnyelv, melyet <i>C</i>-ben és <i>C++</i>-ban írtak meg.</p>
            <p>Az egyik első szerveroldali nyelv, melynek segítségével dinamikus tartalom állítható elő. Korának és népszerűségének köszönhetően számos keretrendszer érhető el rá.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Asszinkronitást nem igénylő projektek vagy szegmensek esetén.</li>
                    <li>Egyszerűbb dinamikus tartalmak előállításához.</li>
                    <li>Egyedi szoftverek készítéséhez.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const Java = {
    name: "Java",
    subTitle: "nyelv, 1995",
    img: require('../../assets/technologies/java.png'),
    content: (
        <React.Fragment>
            <p>A <i>Java</i> volt az első olyan nyelv, mely lehetőséget kínált cross-platform alkalmazások
                létrehozására.</p>
            <p>Ez egy ún. virtuális gépes nyelv <i>(JVM)</i>, mely azt jelenti, hogy a létrejött kód
                nem <b>gépi</b> kód, hanem egy <b>köztes nyelv</b>. Ezt a kódot
                értelmezi és futtatja egy virtuális gép az adott operációs rendszeren.</p>
            <p>Mára nagyon elterjedt. A <i>Google</i> Android rendszeréhez használt elsődleges nyelvként is ismert,
                továbbá lehetővé teszi asztali, valamint webes alkalmazások megvalósítását is.</p>
            <p>Sok technológiának és nyelvnek adott biztos támpontot ez a nyelv, mint például a <i>Kotlin</i>-nak,
                vagy a <i>Groovy</i>-nak.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Amennyiben teljesítményorientált megoldást kell választani multiplatform igény esetén.</li>
                    <li>Meglévő alkalmazások esetén.</li>
                    <li>Natív Android alkalmazások esetén.</li>
                    <li>Üzleti intelligencia fejlesztése esetén.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const Spring = {
    name: "Spring",
    subTitle: "keretrendszer, 2002",
    img: require('../../assets/technologies/spring.png'),
    content: (
        <React.Fragment>
            <p>A <i>Spring</i> a leghasználtabb és legáltalánosabb keretrendszer <i>Java</i>-ra.</p>
            <p>Professzionális és komplex üzleti rendszerek kialakítására hivatott. A nagy múltra visszatekintő
                keretrendszer számtalan lehetőséget kínál.</p>
            <p>Nem véletlen, hogy sok nagy banki rendszer és média szolgáltató infrastruktúrája is erre a
                keretrendszerre épül.</p>
            <p>A sokszínű eszközkészletéből adódóan mondhatni, hogy A-tól Z-ig terjedő megoldásokat kínál.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Komplex vállalati logika esetén kíváló választás.</li>
                    <li>Sokrétegű és összetett felhőalapú micro-service alkalmazások esetén.</li>
                    <li>Monolit alkalmazás esetén.</li>
                    <li>Hosszútávú, rendszeresen fejlesztendő projektek esetén.</li>
                    <li>Teljesítmény-orientált projektek esetén.</li>
                    <li>Aszinkron, eseményvezérelt architektúrák esetén.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const Azure = {
    name: "Azure",
    subTitle: "platform, szolgáltatás, 2010",
    img: require('../../assets/technologies/azure.png'),
    content: (
        <React.Fragment>
            <p>Az <i>Azure</i> napjaink három legnagyobb felhőszolgáltató <i>(Microsoft, Google, Amazon)</i> egyike,
                melyet a <i>Microsoft</i> üzemeltet.</p>
            <p>Alkalmazások <b>építésére</b>, <b>tesztelésére</b> és <b>üzemeltetésére</b> hivatott.</p>
            <p>Szolgáltat <i>SaaS</i>, <i>PaaS</i> és <i>IaaS</i> megoldásokat.</p>
            <p>Bármilyen komplex üzleti rendszer üzemeltetésére képes. Széleskörű technológiai palettája lehetőséget
                ad fejlesztő és üzemeltető cégek számára, hogy a lehető legstabilabb és legmodernebb környezetben
                tudhassák infrastruktúráikat.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Felhőalapú egyszerű és komplex rendszerek esetén.</li>
                    <li>On premise <i>(helyileg kihelyezett)</i> rendszerek bekötéséhez.</li>
                    <li>Dinamikus alkalmazások skálázásaihoz.</li>
                    <li>Adatbázisok üzemeltetéséhez.</li>
                    <li>Adatok redundáns és geo-redundáns tárolásához.</li>
                    <li>Fallback szolgáltatások futtatásához.</li>
                    <li>Kis és nagyteljesítményű alkalmazások üzemeltetéséhez.</li>
                    <li>Naplók, logok auditjára.</li>
                    <li>Klaszterek építésére.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const Angular = {
    name: "Angular",
    subTitle: "keretrendszer, 2010",
    img: require('../../assets/technologies/angular.png'),
    content: (
        <React.Fragment>
            <p>Az <i>Angular</i> a <i>Google</i> által fejlesztett, <i>JavaScript</i>-alapú <i>SPA (Single Page
                Application)</i> keretrendszere böngészőkre.</p>
            <p>Nagyméretű és kohézív frontend felületek fejlesztésére az egyik legalkalmasabb keretrendszer. Sok
                kompetitorával szemben az Angular szigorú szabályokkal rendelkezik. Ezzel utat és irányt ad a
                fejlesztőknek.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Nagyméretű frontendes alkalmazások esetén.</li>
                    <li>Adminisztrátori alkalmazások esetén.</li>
                    <li>Speciális dinamikus tartalom megjelenítéséhez.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const Dart = {
    name: "Dart",
    subTitle: "nyelv, 2011",
    img: require("../../assets/technologies/dart.png"),
    content: (
        <React.Fragment>
            <p>A <i>Dart</i> Google által fejlesztett objektum orientált nyelv. Rendelkezik Garbage Collector-al,
                mint pl a <i>Java (JVM)</i> vagy a <i>C# (.NET)</i>.</p>
            <p>Lehetőség van a <i>Dart</i> kódot <i>JavaScript</i> kóddá fordítani.</p>
            <p>Elsősorban webes alkalmazásokat <i>(Angular)</i> és mobil alkalmazásokat <i>(Flutter)</i> írnak vele.
            </p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li><i>Flutter</i> mobil alkalmazások esetén.</li>
                    <li><i>Angular</i> webes alkalmazások esetén.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const JavaScript = {
    name: "JavaScript",
    subTitle: "nyelv, 1995",
    img: require("../../assets/technologies/javascript.png"),
    content: (
        <React.Fragment>
            <p>A <i>JavaScript</i> egy objektum orientált, gyengén típusos, prototípus-alapú nyelv.</p>
            <p>A mai böngészőkben használatos elsősorban, de mára már üzleti intelligencia fejlesztésre is
                alkalmas <i>(NodeJS)</i>.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Webes alkalmazások megalkotásához.</li>
                    <li>Üzleti intelligencia fejlesztéséhez.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const TypeScript = {
    name: "TypeScript",
    subTitle: "nyelv, 2012",
    img: require("../../assets/technologies/typescript.jpg"),
    content: (
        <React.Fragment>
            <p>A <i>TypeScript</i> egy nyíltforráskódú nyelv, melyet a <i>Microsoft</i> fejleszt.</p>
            <p>A nyelv elsődleges célja, hogy a típusokban szegény <i>JavaScript</i> nyelvet
                helyettesítse erősen típusos megvalósítással. Az erősen típusos kódközeg kevesebb hibázásra ad
                lehetőséget.</p>
            <p>Ebből adódóan könnyebben tudnak <i>JavaScript</i> alkalmazásokat írni azok a programozók, akik
                úgymond erősen típusos nyelveket használtak korábban, pl: <i>C#, Java</i>.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Hosszútávú projektek esetén.</li>
                    <li>Külső munkavégző kollégákkal való együttműködés esetén ideális biztonságot nyújt.</li>
                    <li>Főleg <i>React</i> és <i>Angular</i> projektekben.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const Jetbrains = {
    name: "Jetbrains",
    subTitle: "platform, 2000",
    img: require("../../assets/technologies/jetbrains.png"),
    content: (
        <React.Fragment>
            <p>A <i>Jetbrains - IntelliJ Software s.r.o. -</i> egy szoftverfejlesztést támogató cég. Számos termékével
                segíti elő a fejlesztők mindennapi munkáit.</p>
            <p>Fő termékeik IDE <i>(Integrated Development Environment)</i> alkalmazások, amik speciális kódszerkesztő
                programok fejlesztők számára.</p>
            <div className={whenToUseClass}>
                <h5>Mikor használjuk?</h5>
                <ul>
                    <li>Minden szoftveresen támogatható projekthez.</li>
                    <li>Mindennapokban.</li>
                </ul>
            </div>
        </React.Fragment>
    )
};

export const Technologies = {
    JavaScript,
    TypeScript,
    Java,
    Kotlin,
    Flutter,
    Dart,
    PHP,
    Laravel,
    Lumen,
    Angular,
    ReactJS,
    NodeJS,
    Spring,
    Docker,
    AzureDevOps,
    Azure,
    Jetbrains,
};

export const TechnologiesList = Object.keys(Technologies).map(k => Technologies[k]);
