import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import ProductTheme from "../ProductTheme";
import css from "./CSP.module.scss";
import LoadGently from "../../../layout/LoadGently";
import ScrollToTopOnMount from "../../../helpers/ScrollToTopOnMount";
import Heading from "../../../layout/Heading";
import ImageLoader from "../../../shared/ImageLoader";
import AnimatedLoader from "../../../shared/AnimatedLoader";
import {extractClassNames} from "../../../helpers/ExtractClassNames";
import appCss from "../../../App.module.scss";
import ToggleAnimation from "../../../shared/ToggleAnimation";
import {Section} from "../Product";
import {Parallax} from "react-parallax";
import ContactForm from "../../../shared/ContactForm";
import {DynamicLocation} from "../../../helpers/DynamicLocation";

const PreloadImages = [
    require('./assets/section-1.jpg?size=1200'),
    require('./assets/section-2.jpg?quality=65'),
    require('./assets/section-3.jpg?quality=65'),
    require('./assets/section-4.jpg?size=1200'),
    require('./assets/section-5.jpg?quality=70'),
    require('./assets/section-6.jpg?size=1200'),
    require('./assets/contact.jpg?size=1200'),
];

const ParallaxStyle = {
    height: '40vw',
    minHeight: '500px',
};

class CSP extends Component {
    render() {
        return (
            <LoadGently>

                <Helmet>
                    <title>Csomópont | Termékek | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Webshop,Beszállító,Termékek,Portfolió,Eladás,Árrés,Értékestés,Felület,Automatizálás,Szinkron"/>
                    <meta name="description"
                          content="Kifejezetten célirányos és indokolt projektek esetében javasoljuk az asztali alkalmazásokat. Jellemzően akkor lehet szükség, mikor kénytelenek vagyunk kilépni a böngészők korlátaiból."/>
                    <meta property="og:title" content="Csomópont | Termékek | SzuniSOFT"/>
                    <meta property="og:description" content="Kifejezetten célirányos és indokolt projektek esetében javasoljuk az asztali alkalmazásokat. Jellemzően akkor lehet szükség, mikor kénytelenek vagyunk kilépni a böngészők korlátaiból."/>
                    <meta property="og:image" content={DynamicLocation.build(require('./assets/section-1.jpg?size=800').src)}/>
                </Helmet>

                <ProductTheme productThemeId={css['csp-product-theme']}
                              siteThemeId={css['csp-site-theme']}
                >
                    <ScrollToTopOnMount/>

                    <ImageLoader multiple={PreloadImages}
                                 loading={
                                     <AnimatedLoader/>
                                 }
                                 loaded={
                                     <div className={extractClassNames("d-flex flex-grow-1 flex-column", appCss)}>
                                         <Heading heading="Csomópont"
                                                  breadCrumb={[
                                                      {text: 'Termékek', to: '/termekek'},
                                                      'CSP'
                                                  ]}
                                         />
                                         <div className={extractClassNames('container-fluid p-0 flex-grow-1', appCss)}>

                                             <Section themeCss={css}
                                                      layout={"first-left-last-right"}
                                                      screenHeightOnLarge
                                             >

                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-left w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: `url(${PreloadImages[0].src}) no-repeat right center`,
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('d-flex align-items-center container', appCss)}>
                                                     <div>
                                                         <ToggleAnimation animationClassName="slide-right"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Bevezető</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>A
                                                                 terméklogisztikai iparágban általános probléma a cégek
                                                                 és partnerek közötti folytonos termékadat szinkron
                                                                 megoldása. Különböző cégek más-más üzleti intelligencia
                                                                 szoftvert használnak, ami sok esetben okoz mindennapos
                                                                 fejfájást és kényszerít ki bérköltség növekedést.</p>

                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>A
                                                                 webshopok és kasszaszoftverek számára fontos, hogy a
                                                                 lehető legfrissebb adatokkal tudjanak szolgálni a
                                                                 termékek árát, beszállítói készlet és beszerzési
                                                                 státuszait tekintve. Ezeken felül gyakran kiemelkedően
                                                                 fontos szerepet játszik még a szállítási idő is. Amikor
                                                                 egy kereskedelmi cég több beszállítóval áll
                                                                 kapcsolatban, többezer termékkel dolgozik,
                                                                 nyilvánvalóan fontos a terméktörzs adatok automatizált
                                                                 karbantartása és ellenőrzése. Ez nem csupán jelentősen
                                                                 csökkentheti az improduktív bérköltséget, de a hibázás
                                                                 lehetőségét is visszaszorítja.</p>

                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>
                                                                 Azonban a megoldás korántsem olyan egyszerű. Azon túl,
                                                                 hogy a cégek általában különböző szoftvereket
                                                                 használnak az adatok nyilvántartására, a technológiák
                                                                 gyakran típusukban sem egyeznek meg. Vannak, akik ERP
                                                                 rendszert használnak, mások csak MRP vagy egyszerű
                                                                 készletkezelő szoftvert. Vannak, akik fejlett B2B/B2C
                                                                 webshopban teszik elérhetővé ezeket az információkat,
                                                                 mások csupán adatszinkron szervert biztosítanak. De nem
                                                                 ritka az időnként hírlevéllel kiküldött táblázat vagy
                                                                 tájékoztató levél sem, és még sorolhatnánk a különböző
                                                                 eseteket. Gyakorlatilag ahány cég, annyi megoldás.
                                                                 <b>Ezért amennyire általános ez a probléma éppen
                                                                     annyira
                                                                     specifikus a megoldás is!</b></p>

                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>A
                                                                 Csomópont egy erre a problémára létrehozott
                                                                 szoftverkészlet, melynek segítségével specifikus
                                                                 szinkronizáció hozható létre beszállítók vagy más
                                                                 partnercégek között. Ezzel automatizálva olyan
                                                                 feladatokat, amiket sokszor ember végez, növelve ezzel
                                                                 a hibák lehetőségét és az improduktív költségeket
                                                                 is.</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"column"}
                                                      inverse
                                             >

                                                 <Parallax bgImage={PreloadImages[1].src}
                                                           bgImageSrcSet={PreloadImages[1].srcSet}
                                                           strength={500}
                                                 >
                                                     <div style={ParallaxStyle}/>
                                                 </Parallax>

                                                 <div
                                                     className={extractClassNames('d-flex flex-column mb-5', appCss)}>
                                                     <div>
                                                         <div className={appCss['container']}>
                                                             <ToggleAnimation animationClassName="slide-top"
                                                                              togglePercent={10}>
                                                                 <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Automatizálás</h5>
                                                             </ToggleAnimation>

                                                             <ToggleAnimation animationClassName="slide-bottom"
                                                                              togglePercent={10}>
                                                                 <p className={css['theme']}>
                                                                     Lehetővé válik a különböző cégek közötti
                                                                     termékállapot és információ szinkronizációja, az
                                                                     érintett partner által biztosított adatok alapján.
                                                                     Ezzel létrehozható időzített, vagy esemény vezérelt
                                                                     folytonos szinkronizáció.
                                                                 </p>
                                                                 <p className={css['theme']}>
                                                                     Amennyiben egy beszállító változtatja árait, a
                                                                     kereskedő minél hamarabb szeretne reagálni a
                                                                     változásokra, hiszen a beszerzési árhoz kell
                                                                     igazítania az értékesítési árakat.
                                                                 </p>
                                                             </ToggleAnimation>
                                                         </div>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"column"}
                                                      theme
                                             >

                                                 <Parallax bgImage={PreloadImages[2].src}
                                                           bgImageSrcSet={PreloadImages[2].srcSet}
                                                           strength={500}
                                                 >
                                                     <div style={ParallaxStyle}/>
                                                 </Parallax>

                                                 <div
                                                     className={extractClassNames('d-flex flex-column mb-5', appCss)}>
                                                     <div>
                                                         <div className={appCss['container']}>
                                                             <ToggleAnimation animationClassName="slide-top"
                                                                              togglePercent={10}>
                                                                 <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Konkurens
                                                                     kapcsolatok kezelése</h5>
                                                             </ToggleAnimation>

                                                             <ToggleAnimation animationClassName="slide-bottom"
                                                                              togglePercent={10}>
                                                                 <p className={css['theme']}>Nem ritka eset, amikor egy
                                                                     cégnek több beszállítója van. Ebben az esetben
                                                                     pedig gyakran fordul elő, hogy a két beszállítói
                                                                     kínálat között vannak átfedések, az-az két
                                                                     beszállítótól is beszerezhető egy termék. Ebben az
                                                                     esetben jó lehet, ha egy automatika ár,
                                                                     készletmennyiség, vagy más számított súlyozás
                                                                     alapján eltudná dönteni, hogy melyik beszállítót
                                                                     helyezze prioritásba. A Csomóponttal lehetőség van
                                                                     ilyen jellegű megoldásokra is! </p>
                                                             </ToggleAnimation>
                                                         </div>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"first-right-last-left"}
                                                      screenHeightOnLarge
                                             >

                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-right w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: `url(${PreloadImages[3].src}) no-repeat right center`,
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('d-flex align-items-center container', appCss)}>
                                                     <div>
                                                         <ToggleAnimation animationClassName="slide-left"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Folyamatok
                                                                 - Audit fázisa</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>Első
                                                                 körben egy komplex auditot hajtunk végre, mely
                                                                 tartalmazza a következő vizsgálatokat...</p>

                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"column"}
                                                      inverse
                                             >

                                                 <Parallax bgImage={PreloadImages[4].src}
                                                           bgImageSrcSet={PreloadImages[4].srcSet}
                                                           strength={500}
                                                 >
                                                     <div style={ParallaxStyle}/>
                                                 </Parallax>

                                                 <div
                                                     className={extractClassNames('d-flex flex-column mb-5', appCss)}>
                                                     <div>
                                                         <div className={appCss['container']}>
                                                             <ToggleAnimation animationClassName="slide-top"
                                                                              togglePercent={10}>
                                                                 <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Folyamat
                                                                     - Jelenlegi
                                                                     infrastruktúra</h5>
                                                             </ToggleAnimation>

                                                             <ToggleAnimation animationClassName="slide-bottom"
                                                                              togglePercent={10}>
                                                                 <p className={css['theme']}>A jelenleg használt
                                                                     szoftver, vagy szoftverek összessége. Azok között
                                                                     történő kommunikáció szükséges mértékű teljes
                                                                     feltérképezése.
                                                                 </p>
                                                             </ToggleAnimation>
                                                         </div>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css}
                                                      layout={"first-left-last-right"}
                                                      screenHeightOnLarge
                                             >

                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-left w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: `url(${PreloadImages[5].src}) no-repeat right center`,
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>
                                                 <div
                                                     className={extractClassNames('d-flex align-items-center container', appCss)}>
                                                     <div>
                                                         <ToggleAnimation animationClassName="slide-right"
                                                                          togglePercent={10}>
                                                             <h5 className={css['theme'] + ' ' + appCss['flex-grow-0']}>Folyamat
                                                                 - Partnertechnológiák
                                                                 megismerése</h5>
                                                         </ToggleAnimation>
                                                         <ToggleAnimation animationClassName="slide-bottom"
                                                                          togglePercent={10}>
                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>Megismerkedünk
                                                                 a cég partnerei által használt szoftverekkel,
                                                                 technológiákkal. Készítünk egy technikai audit
                                                                 elemzést. A konklúzió segítséget ad, hogy kiszűrjük
                                                                 azokat a partnereket, akiket nem lehetséges
                                                                 automatizálni.</p>

                                                             <p className={css['theme'] + ' ' + appCss['flex-grow-0']}>Ezen
                                                                 túl az audit rámutat olyan lehetséges sérülékeny
                                                                 pontokra, melyekről korábban senki sem tudott!</p>
                                                         </ToggleAnimation>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <Section themeCss={css}
                                                      screenHeightOnLarge
                                                      layout={"first-right-last-left"}
                                                      className={extractClassNames('bg-dark text-white justify-content-center', appCss)}>

                                                 <ToggleAnimation
                                                     animationClassName={extractClassNames('slide-right w-100 h-100', appCss)}
                                                     togglePercent={10}>
                                                     <div style={{
                                                         background: `url(${PreloadImages[6].src}) no-repeat 20% center`,
                                                         backgroundSize: 'cover',
                                                         width: '100%',
                                                         height: '100%',
                                                         minHeight: '450px'
                                                     }}/>
                                                 </ToggleAnimation>

                                                 <div className={appCss['container']}>
                                                     <div>
                                                         <h4 className={extractClassNames('my-5 text-center', appCss)}>Spóroljon
                                                             automatizálással!</h4>
                                                     </div>
                                                     <ToggleAnimation
                                                         animationClassName={extractClassNames('slide-bottom row', appCss)}
                                                         togglePercent={10}>
                                                         <div
                                                             className={extractClassNames('col-12 col-md-8 offset-md-2 ', appCss)}>
                                                             <div id="contactCard"
                                                                  className={extractClassNames('mb-5 bg-white', appCss)}>
                                                                 <ContactForm/>
                                                             </div>
                                                         </div>
                                                     </ToggleAnimation>
                                                 </div>
                                             </Section>

                                         </div>
                                     </div>
                                 }
                    />

                </ProductTheme>
            </LoadGently>
        );
    }
}

CSP.propTypes = {};

export default CSP;
