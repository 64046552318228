import React, {Component} from 'react';
import PropTypes from 'prop-types';
import appCss from "../../App.module.scss";
import {extractClassNames} from "../../helpers/ExtractClassNames";

class PriceTable extends Component {
    render() {
        return (
            <React.Fragment>
                <div
                    className={extractClassNames('d-flex flex-column flex-lg-row flex-grow-1 justify-content-center', appCss)}
                    id={this.props.id}>
                    <div
                        className={extractClassNames('d-flex d-lg-none', appCss) + ' ' + extractClassNames('cards products', this.props.css)}>
                        {
                            this.props.variations.map((variation, variationIndex) => {
                                return (<div key={variationIndex}
                                             className={this.props.css['product'] + " " + (variation.highlighted === true ? this.props.css['highlighted'] : '')}>
                                    <div
                                        className={this.props.css['heading'] + " " + (variation.highlighted === true ? this.props.css['highlighted'] : '')}>
                                        <div className={this.props.css['wrapper']}>
                                            {variation.heading}
                                        </div>
                                    </div>
                                    <div className={appCss['p-2']}>
                                        {
                                            Object.keys(variation.attributes).map(attributeIndex => (
                                                <div key={attributeIndex}
                                                     className={extractClassNames('row py-2', appCss)}>
                                                    <h5 className={extractClassNames('col-12 col-md-6', appCss) + ' ' + this.props.css['attribute-name']}>{this.props.attributes[attributeIndex]}</h5>
                                                    <div
                                                        className={extractClassNames('col-12 col-md-6', appCss) + ' ' + this.props.css['attribute-value']}>{
                                                        typeof variation.attributes[attributeIndex] === "undefined"
                                                            ? null
                                                            : (typeof variation.attributes[attributeIndex] === "boolean"
                                                            ? variation.attributes[attributeIndex] === true
                                                                ? (<span
                                                                    className={this.props.css['allowed']}>{this.props.allowedIcon}</span>)
                                                                : (<span
                                                                    className={this.props.css['disallowed']}>{this.props.disallowedIcon}</span>)
                                                            : variation.attributes[attributeIndex])
                                                    }</div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>)
                            })
                        }
                    </div>
                    <div
                        className={extractClassNames('d-none d-lg-table', appCss) + ' ' + extractClassNames('products tbl', this.props.css)}>
                        <div className={this.props.css['heading'] + ' ' + appCss['d-table-row']}>

                            {/* Dummy cell */}
                            <div className={extractClassNames('d-table-cell bg-white', appCss)}/>

                            {this.props.variations.map((variation, variationIndex) =>
                                <React.Fragment key={variationIndex}>
                                    <div
                                        className={this.props.css['heading'] + " " + appCss['d-table-cell'] + ' ' +  (variation.highlighted === true ? this.props.css['highlighted'] : '')}>
                                        <div className={this.props.css['wrapper']}>
                                            {variation.heading}
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                        {
                            Object.keys(this.props.attributes).map(attributeIndex => (
                                <div className={appCss['d-table-row'] + ' ' + this.props.css['d-table-row']} key={attributeIndex}>
                                    <div
                                        className={appCss['d-table-cell'] + ' ' + this.props.css['attribute-name']}>{this.props.attributes[attributeIndex]}</div>
                                    {this.props.variations.map((variation, variationIndex) => (
                                        <div
                                            className={extractClassNames('d-table-cell text-center', appCss) + ' ' + this.props.css['attribute-value'] + ' ' + (variation.highlighted === true ? this.props.css['highlighted'] : '')}
                                            key={variationIndex + ' ' + attributeIndex}>
                                            <div className={this.props.css['wrapper']}>
                                                {
                                                    typeof variation.attributes[attributeIndex] === "undefined"
                                                        ? null
                                                        : (typeof variation.attributes[attributeIndex] === "boolean"
                                                        ? variation.attributes[attributeIndex] === true
                                                            ? (<span
                                                                className={this.props.css['allowed']}>{this.props.allowedIcon}</span>)
                                                            : (<span
                                                                className={this.props.css['disallowed']}>{this.props.disallowedIcon}</span>)
                                                        : variation.attributes[attributeIndex])
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

PriceTable.propTypes = {
    css: PropTypes.any,
    attributes: PropTypes.object,
    variations: PropTypes.array,
    recommended: PropTypes.number,
    id: PropTypes.string,
    allowedIcon: PropTypes.any,
    disallowedIcon: PropTypes.any
};

export default PriceTable;
