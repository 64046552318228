import React, {Component} from 'react';
import PropTypes from 'prop-types';

class SiteTheme extends Component {

    componentDidMount() {
        SiteTheme.getBody().id = this.props.id;
    }

    componentWillUnmount() {
        SiteTheme.getBody().id = "";
    }

    static getBody() {
        return document.getElementsByTagName('body')[0];
    }

    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}

SiteTheme.propTypes = {
    id: PropTypes.string.isRequired
};

export default SiteTheme;
