import {Link} from "react-router-dom";
import css from "./ListItem.module.scss";
import appCss from "../../App.module.scss";
import {extractClassNames} from "../../helpers/ExtractClassNames";
import ImageLoader from "../../shared/ImageLoader";
import IconLogoLoader from "../../shared/IconLogoLoader";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTags} from "@fortawesome/free-solid-svg-icons";
import {faClock, faUser} from "@fortawesome/free-regular-svg-icons";
import Moment from "react-moment";
import PropTypes from "prop-types";
import React from "react";

export default class ListItem extends React.Component {

    render() {
        return (
            <Link
                to={"/hirek/cikk/" + this.props.article.id + "/" + this.props.article.shortUrl.replace(/ /g, "-").toLowerCase()}
                key={this.props.article.id} className={css.article + ' ' + appCss['card']}>
                <div
                    className={css['thumbnail-wrapper'] + ' ' + extractClassNames('overflow-hidden d-flex justify-content-center', appCss)}>
                    {
                        !!this.props.article.thumbnail
                            ? <ImageLoader
                                src={this.props.article.thumbnail}
                                loading={(
                                    <IconLogoLoader className={extractClassNames('w-100 bg-dark', appCss)}/>
                                )}
                                loaded={(
                                    <div
                                        className={css['thumbnail'] + ' ' + extractClassNames('fade in flex-grow-1', appCss)}
                                        style={{
                                            background: "url(" + this.props.article.thumbnail + ") no-repeat center center",
                                            backgroundSize: "cover"
                                        }}/>
                                )}
                            />
                            : null
                    }
                    <div className={css['tags']}>
                        <FontAwesomeIcon icon={faTags} size={"2x"} className={css['tags-icon']}/>
                        <div className={css['wrapper']}>
                            {this.props.article.tags.slice(0, 5).map(t => (<span key={t}>#{t}</span>))}
                        </div>
                    </div>
                </div>
                <div className={appCss['card-header']}>
                    <div className={extractClassNames('card-title mb-0 d-flex align-items-center', appCss)}>
                        <h5 className={extractClassNames('flex-grow-1 text-truncate mb-0 pr-4', appCss)}>
                            {this.props.article.title}
                        </h5>
                        <div className={extractClassNames('flex-grow-0 flex-shrink-0', appCss)}>
                            <small>
                                <FontAwesomeIcon icon={faClock} className={appCss['mr-2']}/>
                                <span>{this.props.article.minReadMinutes}</span>
                                {(
                                    this.props.article.minReadMinutes !== this.props.article.maxReadMinutes
                                        ? (<span>-{this.props.article.maxReadMinutes}</span>)
                                        : null
                                )}p
                            </small>
                        </div>
                    </div>
                </div>
                <div className={appCss['card-body']}>
                    <p className={css['intro']}>{this.props.article.previewContent}</p>
                </div>
                <div className={extractClassNames('card-footer overflow-hidden', appCss)}>
                    <div className={extractClassNames('d-flex align-items-center position-relative', appCss)}>
                        <div className={extractClassNames('flex-grow-1 text-truncate', appCss)}>
                            <FontAwesomeIcon icon={faUser} className={appCss['mr-2']}/>
                            <small>{this.props.article.authorName || 'ismeretlen'}</small>
                        </div>
                        <div className={css['dates']}>
                            <small className={css['date-from-now']}><Moment
                                format="YYYY MMM DD">{this.props.article.createdAt}</Moment></small>
                            <small className={css['date-capture']}><Moment
                                fromNow>{this.props.article.createdAt}</Moment>
                            </small>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

ListItem.propTypes = {
    article: PropTypes.object
};
