import React, {Component} from 'react';
import { DynamicLocation } from "../../../../helpers/DynamicLocation";
import ScrollToTopOnMount from "../../../../helpers/ScrollToTopOnMount";
import Heading from "../../../../layout/Heading";
import {faWindowMaximize} from "@fortawesome/free-regular-svg-icons";
import appCss from "../../../../App.module.scss";
import css from "./Web.module.scss";
import serviceCss from "../../Services.module.scss";
import softwareCss from "../Software.module.scss";
import {extractClassNames} from "../../../../helpers/ExtractClassNames";
import {faHashtag} from "@fortawesome/free-solid-svg-icons";
import Examples from "../../../../shared/Examples";
import {Background, Parallax} from "react-parallax";
import LoadGently from "../../../../layout/LoadGently";
import LoaderParallax from "../../../../layout/LoaderParallax";
import {Helmet} from "react-helmet";
import {Platforms} from "../index";
import ToggleAnimation from "../../../../shared/ToggleAnimation";
import ContactForm from "../../../../shared/ContactForm";

const parallaxStyle = {
    height: '30vw',
    minHeight: '400px'
};
const WebOgImage = require('./assets/image-nowadays.jpg');
class Web extends Component {
    render() {
        return (
            <React.Fragment>

                <Helmet>
                    <title>Webfejlesztés | Szoftverfejlesztés | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Webfejlesztés,Single,Multi,Page,Protfolió,Üzleti,intelligencia,Universal,Isomorphic,App"/>
                    <meta name="description"
                          content="Legyen bármilyen szoftver platform, mi gondoskodunk róla, hogy az Ön elképzeléseit a legideálisabb és a legmegfelelőbb technológiai építőkockák támogassák."/>
                    <meta property="og:title" content="Webfejlesztés | Szoftverfejlesztés | SzuniSOFT"/>
                    <meta property="og:description" content="Legyen bármilyen szoftver platform, mi gondoskodunk róla, hogy az Ön elképzeléseit a legideálisabb és a legmegfelelőbb technológiai építőkockák támogassák."/>
                    <meta property="og:image" content={DynamicLocation.build(WebOgImage.src)}/>
                </Helmet>

                <LoadGently className={appCss['flex-grow-1']}>
                    <ScrollToTopOnMount/>
                    <Heading icon={faWindowMaximize}
                             heading="Webfejlesztés"
                             breadCrumb={[
                                 {text: 'Szolgáltatások', to: '/szolgaltatasok'},
                                 {text: 'Szoftverfejlesztés', to: '/szolgaltatasok/szoftverfejlesztes'},
                                 'Webfejlesztés'
                             ]}
                    />
                    <div
                        className={extractClassNames("d-flex flex-column flex-grow-1", appCss) + ' ' + css['web'] + ' ' + softwareCss['software'] + ' ' + serviceCss['service']}>

                        <LoaderParallax {...require("./assets/image-nowadays.jpg")}
                                        style={parallaxStyle}
                                        loaderInverse
                                        parallaxStrength={500}
                                        parallaxBgClassName={"fade in"}
                                        parallaxBgImageStyle={{marginTop: '-30%'}}
                        />

                        <div>
                            <div className={extractClassNames('container py-5', appCss)}>
                                <h2>A web napjainkban</h2>
                                <p className={serviceCss['subheading']}>Valósítsa meg elképzeléseit egy univerzális
                                    platformon!</p>

                                <p>A web, mint platform napjaink legáltalánosabb és legelterjedtebb módja az
                                    információközlésnek. Ma
                                    már mindennapjaink részét képezi. Éppen ezért az elmúlt évek folyamán óriási
                                    fejlődésen
                                    mentek
                                    keresztül a különböző nyelvek, keretrendszerek és böngészők. Előtérbe került az
                                    úgynevezett UX <i> - User Experience - </i>, mely a felhasználói élményt hivatott
                                    definiálni
                                    és
                                    javítani.</p>

                                <p>Ma már egy jó weboldal nem csupán a helyes működést kell megvalósítsa. Ezen túlmutató
                                    feladatai
                                    is vannak. A megfelelő felhasználói élmény elérése érdekében számos eszközt kell
                                    bevetni,
                                    valamint írott és íratlan szabályt kell követni.</p>

                                <h2>Milyen egy jó weboldal?</h2>
                                <p className={serviceCss['subheading']}>Minőség és funkcionalitás kéz a kézben!</p>
                                <p>Jelenleg egy <i>"jó weboldal"</i> nem csupán annyit tesz, hogy az információt humán
                                    olvasható
                                    formában közöljük. A szóban forgó alkalmazás mivoltjától függ a felépítése.
                                    Például más fajta felépítési struktúrával rendelkezik egy portfóliós weboldal, vagy
                                    egy
                                    adminisztrációs oldal <i>(portál)</i>.</p>
                                <p>Egy <i>"jó weboldal"</i> intuitív. Ez azt jelenti, hogy kezelésének szempontjából a
                                    bonyolultságától függően szükség van az interaktív segítségnyújtásra, megjelenésre.
                                    Például
                                    habár napjainkban még
                                    mindig szükség van általános felhasználói dokumentációra, egyre gyakoribb megoldás,
                                    hogy
                                    maga a
                                    weboldal válik <i>"élővé"</i>. Ezzel a felhasználó ott kapja meg az információt,
                                    ahol
                                    éppen
                                    szüksége van rá.</p>
                                <p>Az intuitivitás részét képezik a különböző gesztusok érzékelése. Például egy mobil
                                    teljesen
                                    más
                                    perifériával rendelkezik, mint egy számítógép. Éppen ezért a megfelelő optimalizáció
                                    érdekében többlet munkára van szükség.</p>
                                <p>Az animációk is legalább annyira hozzájárulnak egy jó arculathoz, mint maguk a színek
                                    és
                                    formák.</p>

                            </div>

                            <LoaderParallax {...require('./assets/image-1.jpg')}
                                            loaderInverse
                                            parallaxStrength={500}
                                            parallaxBgClassName={"fade in"}
                                            style={parallaxStyle}
                            />

                            <div className={extractClassNames('container py-5', appCss)}>

                                <h2>Behatárolás funkcionalitás szerint</h2>
                                <p>Funkcionalitás szempontjából két fő kategóriába sorolhatók a weboldalak. <b>Üzleti
                                    intelligencia</b>, valamint <b>portfóliós</b> kategóriákba. Azért fontos ezt a
                                    megkülönböztetést
                                    megtenni, mert más elvárásokat kell támasztani a két kategóriával szemben.</p>

                                <h3 className={serviceCss['secondary']}>Üzleti intelligencia</h3>
                                <div className={appCss['row']}>
                                    <div className={extractClassNames('col-12 col-lg-7', appCss)}>
                                        <p>Az üzleti intelligenciát támogató weboldalak esetében prioritást kapnak a
                                            funkciók és
                                            kevésbé
                                            jelentőségteljes a megjelenítés. Az ilyen szoftverek esetén általában nincs
                                            szükség
                                            SEO <i> - <a
                                                className={appCss['underlined']}
                                                href="https://en.wikipedia.org/wiki/Search_engine_optimization"
                                                target="_blank">Search
                                                Engine Optimization</a> - </i> alkalmazására. Jellemzően a dinamikus
                                            adatok
                                            megfelelő
                                            kezeléséért felelős.</p>
                                    </div>
                                    <div className={extractClassNames('col-12 col-lg-5', appCss)}>
                                        <Examples itemIcon={faHashtag}
                                                  listClassName={'text-right'}
                                                  items={[
                                                      "Adminisztrátor felületek",
                                                      "Adat export és import",
                                                      "Statisztikai elemzők",
                                                  ]}/>
                                    </div>
                                </div>

                                <h3 className={serviceCss['secondary']}>Portfólió</h3>
                                <div className={appCss['row']}>
                                    <div className={extractClassNames('col-12 col-lg-7', appCss)}>
                                        <p>Portfóliós oldalak esetében kiemelkedően fontos az ügyfél meggyőzése. Ehhez
                                            különböző
                                            eszközök
                                            vethetők be. Fontos, hogy statikus tartalom vizualizálásakor <i>- egy
                                                termékhez
                                                szeretnénk landoló oldalt készíteni - </i> a megjelenített szöveghez,
                                            méretéhez és mennyiségéhez legyen igazítva a
                                            látványvilág
                                            (színek, formák, animációk).</p>
                                        <p>Kiemelkedően fontos szerepet kapnak a SEO elemek is. Ezzel növelhetők a
                                            találati
                                            arányok,
                                            megfelelő kampányok esetében pedig a konverziók.</p>
                                        <p>Elengedhetetlen, hogy minden fontos információt
                                            statisztikai
                                            formában nyomon tudjunk követni. Erre több megoldás létezik. Egy jól
                                            beállított
                                            analitikai
                                            szoftver a valódi monitorozása a felhasználói forgalomnak. Az így gyűjtött a
                                            statisztikai
                                            adatokból levont konklúziók segítségével megfelelő döntési helyzetbe
                                            kerülünk.</p>
                                    </div>
                                    <div className={extractClassNames('col-12 col-lg-5', appCss)}>
                                        <Examples itemIcon={faHashtag}
                                                  items={[
                                                      "Céges bemutatkozó oldalak",
                                                      "Bemutatkozó felületek",
                                                      "Branding oldalak",
                                                      "Landoló oldalak"
                                                  ]}/>
                                    </div>
                                </div>

                                <h3 className={serviceCss['secondary']}>Hibrid</h3>
                                <div className={appCss['row']}>
                                    <div className={extractClassNames('col-12 col-lg-7', appCss)}>
                                        <p>Legtöbb esetben hibrid megoldásra van szükség. Az alkalmazás egy része
                                            statikus
                                            tartalmat
                                            jelenít meg, egy másik pedig dinamikusat. Például képzeljük el egy fotós
                                            szakember
                                            weboldalát.
                                            Részben tartalmaz statikusan megjelenő elemeket, de lehetősége van a
                                            fotósnak új
                                            képeket
                                            feltölteni a weboldalra.</p>
                                    </div>
                                    <div className={extractClassNames('col-12 col-lg-5', appCss)}>
                                        <Examples itemIcon={faHashtag}
                                                  items={[
                                                      "Időjárás előrejelzők",
                                                      "Foglalórendszerek",
                                                      "CMS rendszerek",
                                                      "Webshoppok",
                                                      "Blog",
                                                  ]}/>
                                    </div>
                                </div>


                            </div>

                            <div className={extractClassNames('container py-5', appCss)}>

                                <h2>Behatárolás működés szerint</h2>
                                <p>Alapvetően három különböző fajta webalkalmazást különböztetünk meg működésük
                                    szempontjából. A következő három típus részben meghatározza a felhasználási
                                    területet
                                    is,
                                    természetesen sokszor vannak átfedések.</p>

                                <h3 className={serviceCss['secondary']}>Multi Page Application - MPA</h3>
                                <div className={appCss['row']}>
                                    <div className={extractClassNames('col-12 col-lg-7', appCss)}>
                                        <p>Az MPA gyakorlatilag az a fajta hagyományos működés, amit a web kezdete óta
                                            használunk. A
                                            felhasználó
                                            navigáció hatására új kérést küld el a szerver felé, mely válaszban az új
                                            oldal
                                            tartalmát
                                            generálja ki. A legtöbb weboldal ezzel a működési elvvel operál napjainkban
                                            is.</p>
                                    </div>
                                    <div className={extractClassNames('col-12 col-lg-5', appCss)}>
                                        <Examples itemIcon={faHashtag}
                                                  items={[
                                                      "Kevésbé felhasználói élmény orientált",
                                                      "Egyszerű oldalak",
                                                      "Funkció az első",
                                                      "SEO"
                                                  ]}/>
                                    </div>
                                </div>


                                <h3 className={serviceCss['secondary']}>Single Page Application - SPA</h3>
                                <div className={appCss['row']}>
                                    <div className={extractClassNames('col-12 col-lg-7', appCss)}>
                                        <p>A SPA lényege, hogy a tartalom nem a szerver oldalt kerül kigenerálásra,
                                            hanem a
                                            kliens
                                            oldalán -
                                            jellemzően a böngészőben. Ez a megoldás azért hatékony, mert a terhelést így
                                            csökkenteni
                                            lehet a
                                            szerveren, ezzel pedig olcsóbbá válik az üzemeltetés. Azonban ezzel a
                                            metodikával
                                            nem lehet
                                            SEO
                                            kompatibilitást biztosítani. Továbbá a navigációs tevékenység animálhatóvá
                                            válik.</p>
                                        <p>Továbbá a felhasználó navigációja itt szintén csupán a böngészőben történik
                                            meg.
                                            A
                                            szerver
                                            szempontjából mondhatni imitálva van a navigáció tevékenysége. A tartalom
                                            jellemzően
                                            dinamikusan
                                            kerül betöltésre a navigáció hatására.</p>
                                    </div>
                                    <div className={extractClassNames('col-12 col-lg-5', appCss)}>
                                        <Examples itemIcon={faHashtag}
                                                  items={[
                                                      "Felhasználói élmény orientált",
                                                      "SEO nem szempont",
                                                      "Dizájncentrikus",
                                                  ]}/>
                                    </div>
                                </div>

                            </div>

                            <LoaderParallax {...require('./assets/image-universal-app.jpg')}
                                            loaderInverse
                                            parallaxStrength={500}
                                            parallaxBgClassName={"fade in"}
                                            style={parallaxStyle}
                            />

                            <section className={serviceCss['inverse']}>
                                <div className={extractClassNames('container py-5', appCss)}>

                                    <h3>Universal App - Isomorphic App</h3>
                                    <div className={appCss['row']}>
                                        <div className={extractClassNames('col-12 col-lg-7', appCss)}>
                                            <p>Izomorfikus, vagy univerzális alkalmazások képesek egyszerre MPA és SPA
                                                metódussal is
                                                működni.
                                                Ezzel minden előnyét kiaknázva mind a két típusnak. Fontos megemlíteni,
                                                hogy
                                                egy
                                                univerzális
                                                alkalmazás meglehetősen összetetté tud válni, függően az alkalmazás
                                                funkcióbeli
                                                méretétől.</p>
                                            <p>Mindazonáltal jelenleg piacvezető technológiának számít és a
                                                soron következő
                                                alkalmazások nagy része erre a technológiára fog épülni.</p>
                                        </div>
                                        <div className={extractClassNames('col-12 col-lg-5', appCss)}>
                                            <Examples className={serviceCss.examples}
                                                      itemIcon={faHashtag}
                                                      items={[
                                                          "Felhasználói élmény orientált",
                                                          "Hibrid megoldások",
                                                          "Üzleti intelligencia",
                                                          "Komplex igények",
                                                          "Hosszútávú",
                                                          "Korszerű",
                                                          "SEO"
                                                      ]}/>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <Platforms active="web"/>

                            <section>
                                <div className={extractClassNames('pt-5 py-md-5', appCss)}>
                                    <div className={appCss['container']}>
                                        <div>
                                            <h4 className={extractClassNames('my-5 text-center', appCss)}>Vegye fel velünk a kapcsolatot
                                                bátran!</h4>
                                        </div>
                                        <ToggleAnimation
                                            animationClassName={extractClassNames('slide-bottom row', appCss)}
                                            togglePercent={10}>
                                            <div
                                                className={extractClassNames('col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3', appCss)}>
                                                <div id="contactCard" className={extractClassNames('bg-white', appCss)}>
                                                    <ContactForm/>
                                                </div>
                                            </div>
                                        </ToggleAnimation>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </LoadGently>
            </React.Fragment>
        );
    }
}

Web.propTypes = {};

export default Web;
