import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ScrollToTopOnMount from "../../helpers/ScrollToTopOnMount";
import Heading from "../../layout/Heading";
import {faChevronRight, faCode, faCog, faNetworkWired} from "@fortawesome/free-solid-svg-icons";
import css from "./Services.module.scss";
import appCss from "../../App.module.scss";
import {Link} from "react-router-dom";
import {faMicrosoft} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {extractClassNames} from "../../helpers/ExtractClassNames";
import {faLightbulb} from "@fortawesome/free-regular-svg-icons";
import LoadGently from "../../layout/LoadGently";
import ImageLoader from "../../shared/ImageLoader";
import IconLogoLoader from "../../shared/IconLogoLoader";
import {Helmet} from "react-helmet";
import LoaderParallax from "../../layout/LoaderParallax";

const Items = [
    {
        title: 'Szoftverfejlesztés',
        uri: 'szoftverfejlesztes',
        id: "software",
        icon: faCode,
        banner: require('../../assets/parallax/code.jpg'),
        intro: (
            <React.Fragment>
                <p>
                    A kifejezés önmagában rendkívül szerteágazó, ettől függetlenül manapság nagyon felkapott és sokszor
                    emlegetett. Mindig fontos tisztázni milyen platformra milyen nyelven milyen technikákkal kerül
                    megvalósításra egy szoftver. Ezek a faktorok mind időre, hatékonyságra és nem utolsó sorban az árra
                    is
                    hatással vannak.
                </p>
                <p>
                    Bármilyen projektről is legyen szó, az esetek többségében a megvalósításuk számtalan formában
                    elképzelhető. Mi segítünk szakértelmünkkel meghatározni a megvalósulási lehetőségeket és azok közül
                    is
                    kiválasztani a legmegfelelőbbet, legyen szó mobil, web vagy asztali alkalmazásról vagy ezek komplex
                    összességeiről!
                </p>
            </React.Fragment>
        )
    },
    {
        title: 'Üzemeltetés',
        uri: 'rendszeruzemeltetes',
        id: "servicing",
        icon: faCog,
        banner: require('../../assets/parallax/analytics.jpg'),
        intro: (
            <React.Fragment>
                <p>
                    Bármely informatikai beállítottságú cég igényel üzemeltetést és karbantartást, csupán nem biztos,
                    hogy
                    tud róla. Legyen szó gépekről, hálózatról vagy szoftverről, mindegyik terület igényli a folyamatos
                    karbantartást és felügyeletet. Így elkerülhetők az egyszeri nagy kiadások, melyek megzavarhatják a
                    megszokott cashflow-t. Ezen túl a sűrűbb karbantartások és vizsgálatok csökkentik a rendszerkiesést
                    ami
                    veszteséghez is vezethet akár.
                </p>
                <p>
                    Széles skálán kínálunk megoldást különböző üzemeltetési stratégiákat igénylő projektekre. Képesek
                    vagyunk a megrendelő igényéhez igazítani az üzemeltetési technológiákat, megoldásokat, legyen szó
                    egyszerű vagy összetett infrastruktúráról!
                </p>
            </React.Fragment>
        )
    },
    {
        title: 'Hálózatfejlesztés',
        uri: 'halozatfejlesztes',
        id: "networkServicing",
        icon: faNetworkWired,
        banner: require('../../assets/parallax/network.jpg'),
        intro: (
            <React.Fragment>
                <p>
                    Mikor azt mondjuk, hogy hálózat, akkor több mindenre is gondolhatunk, mert ebben a körforgásban
                    nagyon
                    sok eszköz és technológia vesz részt. Azonban azzal mindenki tisztában van, hogy egyre nagyobb
                    file-okkal dolgozunk a mindennapokban, így fontos, hogy rendszeresen és időben eljussanak hozzánk.
                    Amennyiben erre nem akarunk egyre többet várni úgy a hálózatunkat is fejlesztenünk kell, hogy képes
                    legyen a megfelelő sávszélesség lebonyolítására.
                </p>
                <p>
                    Ami mellet nem mehetünk el szó nélkül, hogy a felhasználók a helyi eszközök által érintkeznek az
                    internettel, mely potenciális veszélyeket hordoz magában. Jelenleg minden kormány által elismert
                    probléma a kibertámadás. Méréseink alapján egy átlagos (akár lakossági) végponton napi szinten több,
                    mint 250 betörési kísérlet törtnénik. A folyamatosan fejlődő kibertechnológiák miatt fontos, hogy
                    tűzfalnak használt eszközeink és hálózatunk naprakész legyen.
                </p>
            </React.Fragment>
        )
    },
    {
        title: 'Microsoft terméktámogatás',
        type: 'wide',
        uri: 'microsoft-termektamogatas',
        id: "microsoftServicing",
        icon: faMicrosoft,
        banner: require('../../assets/parallax/mslogo.jpg?quality=80'),
        intro: (
            <React.Fragment>
                A Microsoft világszerte ismert és méltán elismert termékei, technológiái a multinacionális környezet
                működését éppoly hatékonysággal képesek támogatni, mint akár a legkisebb mikrovállalkozás munkáját,
                fejlődését. Bevezetésük, üzemeltetésük bármilyen egyszerűnek tűnik is, kíván némi szakértelmet.
                Támogatási szolgáltatásunk kiterjed a tervezéstől a bevezetésen át a fenntartási időszak feladataira is
                úgy lokális mint felhő alapú megoldásoknál.
            </React.Fragment>
        )
    }
];

class ListItem extends Component {
    render() {
        return (
            <div
                className={extractClassNames('card', appCss) + ' ' + extractClassNames('card servicing', css)}
                id={extractClassNames(this.props.item.id + 'ServiceTheme', css)}>
                <div className={appCss['extended-container']}>
                    <div className={css['wrapper']}>
                        <div className={css['image']}>

                            {this.props.item.type === 'wide'
                                ? <ImageLoader src={this.props.item.banner.src}
                                               srcSet={this.props.item.banner.srcSet}
                                               loading={<IconLogoLoader/>}
                                               loaded={
                                                   <img srcSet={this.props.item.banner.srcSet}
                                                        src={this.props.item.banner.src}
                                                        alt={this.props.item.title}
                                                        className={appCss['img-fluid']}
                                                   />
                                               }
                                />
                                : <LoaderParallax src={this.props.item.banner.src}
                                                  srcSet={this.props.item.banner.srcSet}
                                                  loaderInverse
                                                  style={{
                                                      minHeight: '450px',
                                                      height: '100%'
                                                  }}
                                                  parallaxStrength={500}
                                                  parallaxBgClassName={'fade in'}
                                />
                            }
                        </div>
                        <div
                            className={css['intro'] + ' ' + extractClassNames('d-flex justify-content-center flex-column container', appCss)}>
                            <div>
                                <Link to={"/szolgaltatasok/" + this.props.item.uri}>
                                    <h2 className={extractClassNames('card-title flex-grow-0 mb-5 text-truncate', appCss) + ' ' + css['card-title']}>
                                        <FontAwesomeIcon icon={this.props.item.icon} className={appCss['mr-4']}/>
                                        {this.props.item.title}
                                    </h2>
                                </Link>

                                <div className={appCss['text-justify']}>{this.props.item.intro}</div>
                                <div>
                                    <div className={extractClassNames('text-center mt-5 button-perspective', appCss)}>
                                        <Link to={"/szolgaltatasok/" + this.props.item.uri}
                                              className={extractClassNames('btn p-3', appCss) + ' ' + extractClassNames('btn', css)}>
                                            <span>bővebben</span>
                                            <FontAwesomeIcon icon={faChevronRight}/>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ListItem.propTypes = {
    item: PropTypes.object
};

class Services extends Component {

    render() {
        return (
            <React.Fragment>

                <Helmet>
                    <title>Szolgáltatások | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Szolgáltatások,Szoftverfejlesztés,Web,Mobil,Üzemeltetés,Rendszer,Hálózat fejlesztés,Microsoft"/>
                    <meta name="description"
                          content="Tekintse meg miben lehetünk segítségére kérjen egyedi ajánlatot."/>
                    <meta property="og:title" content="Szolgáltatások | SzuniSOFT"/>
                    <meta property="og:description"
                          content="Tekintse meg miben lehetünk segítségére kérjen egyedi ajánlatot."/>
                </Helmet>

                <ScrollToTopOnMount/>
                <Heading heading="Szolgáltatások" icon={faLightbulb}/>
                <LoadGently>
                    <div id={css['services']}>
                        <div className={extractClassNames('d-flex flex-grow-1 flex-column', appCss)}>
                            {Items.map(i => <div key={i.title}
                                                 className={extractClassNames('fade in', appCss) + ' ' + css['servicing-wrapper']}>
                                <ListItem item={i}/>
                            </div>)}
                        </div>
                    </div>
                </LoadGently>
            </React.Fragment>
        );
    }
}

Services.propTypes = {};

export default Services;
