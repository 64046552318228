import React, {Component} from 'react';
import { DynamicLocation } from "../../../helpers/DynamicLocation";
import ScrollToTopOnMount from "../../../helpers/ScrollToTopOnMount";
import Heading from "../../../layout/Heading";
import {
    faCircle,
    faCode,
    faCodeBranch,
    faDesktop,
    faInfo,
    faMobileAlt,
    faServer
} from "@fortawesome/free-solid-svg-icons";
import appCss from "../../../App.module.scss";
import css from "./Software.module.scss";
import serviceCss from "../Services.module.scss";
import {extractClassNames} from "../../../helpers/ExtractClassNames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {faAndroid, faApple, faWindows} from "@fortawesome/free-brands-svg-icons";
import {faWindowMaximize} from "@fortawesome/free-regular-svg-icons";
import {Parallax} from "react-parallax";
import LoadGently from "../../../layout/LoadGently";
import ToggleAnimation from "../../../shared/ToggleAnimation";
import LoaderParallax from "../../../layout/LoaderParallax";
import {Helmet} from "react-helmet";

const parallaxStyle = {
    height: '40vw',
    minHeight: '400px',
    maxHeight: '800px'
};

export const Platforms = (props) => (
    <div className={extractClassNames('bg-dark pt-5 py-lg-5', appCss)}>
        <div className={extractClassNames('container position-relative', appCss)}>
            <div className={css['platforms-heading']}>
                <h2 className={serviceCss['ignored']}>Válasszon platformot!</h2>
            </div>
            <div className={appCss['row'] + ' ' + css['inverse']}>
                <div className={extractClassNames('col-12 col-lg-4 py-3 d-flex', appCss)}>
                    <Link to="/szolgaltatasok/szoftverfejlesztes/web"
                          className={css['platform'] + ' ' + (props.active === 'web' ? css['active'] : '')}>
                        <h3 className={css['ignored']}>
                            <FontAwesomeIcon icon={faWindowMaximize} className={appCss['mr-2']}/>
                            <span>Web</span>
                        </h3>
                        <div>
                            <small>
                                <FontAwesomeIcon icon={faCircle} className={appCss['mr-2']}/>
                                Statikus
                            </small>
                        </div>
                        <div>
                            <small>
                                <FontAwesomeIcon icon={faServer} className={appCss['mr-2']}/>
                                Dinamikus
                            </small>
                        </div>
                    </Link>
                </div>
                <div className={extractClassNames('col-12 col-lg-4 py-3 d-flex', appCss)}>
                    <Link to="/szolgaltatasok/szoftverfejlestes/mobil"
                          className={css['platform'] + ' ' + (props.active === 'mobile' ? css['active'] : '')}>
                        <h3 className={css['ignored']}>
                            <FontAwesomeIcon icon={faMobileAlt} className={appCss['mr-2']}/>
                            <span>Mobil</span>
                        </h3>
                        <div>
                            <small>
                                <FontAwesomeIcon icon={faAndroid} className={appCss['mr-1']}/>
                                Android
                            </small>
                        </div>
                        <div>
                            <small>
                                <FontAwesomeIcon icon={faApple} className={appCss['mr-1']}/>
                                iOS
                            </small>
                        </div>
                    </Link>
                </div>
                <div className={extractClassNames('col-12 col-lg-4 py-3 d-flex', appCss)}>
                    <Link to="/szolgaltatasok/szoftverfejlestes/asztali"
                          className={css['platform'] + ' ' + (props.active === 'desktop' ? css['active'] : '')}>
                        <h3 className={css['ignored']}>
                            <FontAwesomeIcon icon={faDesktop} className={appCss['mr-2']}/>
                            <span>Asztali</span>
                        </h3>
                        <div>
                            <small>
                                <FontAwesomeIcon icon={faWindows} className={appCss['mr-2']}/>
                                Windows
                            </small>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    </div>
);
const SoftwareOgImage = require('./assets/image-1.jpg');

class Software extends Component {
    render() {

        return (
            <React.Fragment>

                <Helmet>
                    <title>Szoftverfejlesztés | Szolgáltatás | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Szoftverfejlesztés,Egyedi,Igény,Továbbfejlesztés,Technológia,Platform,Web,Mobil,Desktop"/>
                    <meta name="description"
                          content="Legyen bármilyen szoftver platform, mi gondoskodunk róla, hogy az Ön elképzeléseit a legideálisabb és a legmegfelelőbb technológiai építőkockák támogassák."/>
                    <meta property="og:title" content="Szoftverfejlesztés | Szolgáltatás | SzuniSOFT"/>
                    <meta property="og:description" content="Legyen bármilyen szoftver platform, mi gondoskodunk róla, hogy az Ön elképzeléseit a legideálisabb és a legmegfelelőbb technológiai építőkockák támogassák."/>
                    <meta property="og:image" content={DynamicLocation.build(SoftwareOgImage.src)}/>
                </Helmet>

                <LoadGently className={extractClassNames('d-flex flex-column flex-grow-1', appCss)}>
                    <ScrollToTopOnMount/>
                    <Heading heading="Szoftverfejlesztés"
                             icon={faCode}
                             breadCrumb={[
                                 {text: 'Szolgáltatások', to: '/szolgaltatasok'},
                                 'Szoftverfejlesztés'
                             ]}
                    />
                    <div className={css['software'] + ' ' + serviceCss['service']}>

                        <LoaderParallax {...require('./assets/image-1.jpg')}
                                        style={parallaxStyle}
                                        parallaxStrength={500}
                                        loaderInverse
                                        parallaxBgImageStyle={{marginTop: '-2%'}}
                                        parallaxBgClassName={'fade in'}
                        />

                        <div className={extractClassNames('container py-5', appCss)}>

                            <h2>
                                <FontAwesomeIcon icon={faInfo} className={appCss['mr-2']}/>
                                Általános szoftverfejlesztés
                            </h2>
                            <p>Amennyiben Ön már meghatározta üzleti problémáját, állunk rendelkezésére a közös megoldás
                                érdekében!</p>
                            <p>Legyen szó bármilyen platformról mi gondoskodunk róla, hogy az Ön elképzeléseit a
                                legideálisabb
                                és a legmegfelelőbb technológiai építőkockák támogassák. Nem csak a kezdetleges
                                verziót tartjuk szem előtt. Természetesen hosszútávú megoldásokat kínálunk mindenki
                                számára.</p>

                            <h2>
                                <FontAwesomeIcon icon={faCodeBranch} className={appCss['mr-2']}/>
                                Továbbfejlesztés
                            </h2>
                            <p>Már meglévő projekteket is tovább fejlesztünk igény szerint. Fontos megemlíteni,
                                hogy korábbi, ismeretlen munkák esetében <i>- különösen, mikor mások által létrehozott
                                    alkalmazásról van szó -</i> egy programozói és környezeti felmérést kell elvégeznünk
                                a minél
                                pontosabb ár meghatározása érdekében. Ezen túl ez az audit segítséget nyújt a garancia
                                vállalás
                                kiterjedésének meghatározására is.</p>
                            <p>Az ügyfél igényének és az audit eredményének függvényében érdemes megkülönböztetni a
                                meghatározott kiindulási pontú és végeredményű projektet a lazább, rugalmasabb (agilis)
                                módszertanokat követő együttműködéstől. Ez utóbbi esetre a havidíjas <Link
                                    className={appCss['underlined']}
                                    to="/szolgaltatasok/rendszeruzemeltetes">üzemeltetésű konstrukciók</Link> valósabb
                                megoldást
                                jelentenek.</p>
                            <p>Korábbi projektek fejlesztésének átvétele esetében különböző faktorok befolyásolják az
                                árképzést.</p>

                            <h3>Alkalmazott platformok és technológiák kora</h3>
                            <p>A szóban forgó projektek többsége sok éve került lefejlesztésre. Mindazonáltal
                                előfordulhat, hogy
                                az
                                ügyfél igényt tart a szoftver kiegészítésére, karbantartására. Azonban régebbi, nem
                                karbantartott szoftverek esetében könnyedén előfordulhatnak biztonsági kockázatok.
                                Továbbá
                                fontos
                                megemlíteni, hogy a folytonos karbantartás nem csupán a biztonságot növeli, sok esetben
                                jobban
                                optimalizálttá teszi a szoftvert, ezzel kevesebb erőforrással is beérve. Így pedig végső
                                soron
                                költségcsökkentést eredményezve a vállalkozás vagy vállalkozó számára.</p>

                            <h3>Üzleti probléma formája és mélysége</h3>
                            <p>Szintén fontos faktorát képezi az árszabásnak az aktuális üzleti probléma mibenléte.
                                Jellemzően
                                minél specifikusabb üzleti tevékenységet fed le egy szoftver annál összetettebb
                                elvárásokat
                                támasztanak a megrendelők a programozók felé.</p>
                            <p>Napjainkban a legmegfelelőbb módja egy szoftver elkészítésének, ha a szoftvert fejlesztő
                                csapat
                                tagjai megismerkednek az üzleti problémával, bizonyos esetekben és mélységben pedig a
                                szükséges szakmával,
                                szakterülettel. A megfelelő kompetenciát elsajátítva így biztonságosabb és kevesebb idő
                                a
                                szoftvert lefejleszteni.</p>
                            <p>Ehhez azonban szükség van egy szakemberre, akitől a fejlesztői csapat emberei képesek a
                                megfelelő szaktudást elsajátítani.</p>

                        </div>

                        <ToggleAnimation animationClassName="slide-bottom" togglePercent={15}>
                            <Platforms/>
                        </ToggleAnimation>
                    </div>
                </LoadGently>
            </React.Fragment>
        );
    }
}

Software.propTypes = {};

export default Software;
