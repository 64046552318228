import React, {Component} from 'react';
import PropTypes from 'prop-types';

class ImageLoader extends Component {

    static defaultProps = {
        srcSet: null,
        sizes: null,
        multiple: null,
        disable: false,
        onLoad: () => {
        }
    };

    state = {
        loaded: false
    };

    img: HTMLImageElement = null;

    componentDidMount(): void {

        const images: Array = this.props.multiple
            ? this.props.multiple
            : [{src: this.props.src, srcSet: this.props.srcSet}];

        if (!images.length) {
            this.onLoad();
            return;
        }

        Promise.all(images.map(image => new Promise((resolve, reject) => {

            this.img = new Image();
            this.img.addEventListener('load', resolve);
            this.img.addEventListener('error', reject);

            this.img.src = image.src;
            this.img.srcset = image.srcSet || '';
            this.img.sizes = image.sizes || '';

            if (this.img.complete) {
                this.img.removeEventListener('load', resolve);
                this.img.removeEventListener('error', reject);
                resolve();
            }

        })))
            .then(this.onLoad)

    }

    componentWillUnmount(): void {
        if (this.img) {
            this.img.removeEventListener('load', this.onLoad)
        }
    }

    onLoad = () => {
        this.setState({loaded: true});
        this.props.onLoad();
    };

    render() {

        if (typeof window === "undefined") {
            return this.props.loaded;
        }

        return !this.state.loaded
            ? this.props.loading
            : this.props.loaded;
    }
}

ImageLoader.propTypes = {
    src: PropTypes.string,
    srcSet: PropTypes.any,
    multiple: PropTypes.array,
    sizes: PropTypes.any,

    loading: PropTypes.any,
    loaded: PropTypes.any,
    onLoad: PropTypes.func,
};

export default ImageLoader;
