import React from 'react';
import PropTypes from 'prop-types';
//import {Background, Parallax} from "react-parallax/src/modules";
import {Background, Parallax} from "react-parallax";
import ImageLoader from "../../shared/ImageLoader";
import IconLogoLoader from "../../shared/IconLogoLoader";

class LoaderParallax extends React.PureComponent {

    static defaultProps = {
        inverse: false
    };

    static componentProps(src, prefix) {
        return Object.keys(src)
            .filter(key => key.startsWith(prefix))
            .reduce((obj, key) => {
                let normalizedKey = key.replace(prefix, '');
                normalizedKey = normalizedKey[0].toLowerCase() + normalizedKey.substr(1);
                obj[normalizedKey] = src[key];
                return obj;
            }, {});
    }

    loaderProps() {
        return {
            ...LoaderParallax.componentProps(this.props, 'loader'),
            style: {
                ...this.props.style,
                ...this.props.loaderStyle,
            }
        };
    }

    parallaxProps() {
        return {
            ...LoaderParallax.componentProps(this.props, 'parallax'),
            bgImage: this.props.src,
            bgImageSrcSet: this.props.srcSet,
            style: {
                ...this.props.style,
                ...this.props.parallaxStyle,
            }
        };
    }

    render() {

        const loaderProps = this.loaderProps();
        const parallaxProps = this.parallaxProps();

        return <ImageLoader
            src={this.props.src}
            srcSet={this.props.srcSet}
            loading={
                React.cloneElement(<IconLogoLoader/>, loaderProps)
            }
            loaded={
                React.cloneElement(<Parallax/>, parallaxProps, this.props.children || <div style={{
                    height: parallaxProps.style.height || 'auto',
                    minHeight: parallaxProps.style.minHeight || 'auto',
                    maxHeight: parallaxProps.style.maxHeight || 'auto'
                }}/>)
            }
        />
    }
}

LoaderParallax.propTypes = {

    // General
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.any.isRequired,

    style: PropTypes.object,

    // Loader
    loaderInverse: PropTypes.bool,
    loaderStyle: PropTypes.object,
    loaderClassName: PropTypes.string,

    // Parallax
    parallaxBgClassName: PropTypes.string,

    // Overridden by `src`
    //parallaxBgImage: PropTypes.string,
    // Overridden by `srcSet`
    //parallaxBgImageSrcSet: PropTypes.string,

    parallaxBgImageAlt: PropTypes.string,
    parallaxBgImageSizes: PropTypes.string,

    parallaxBgImageStyle: PropTypes.object,
    parallaxBgStyle: PropTypes.object,
    parallaxBlur: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
    parallaxClassName: PropTypes.string,
    parallaxContentClassName: PropTypes.string,
    parallaxDisabled: PropTypes.bool,
    parallaxLog: PropTypes.bool,
    parallaxParent: PropTypes.any,
    parallaxRenderLayer: PropTypes.func,
    parallaxStrength: PropTypes.number,
    parallaxStyle: PropTypes.object,
};

export default LoaderParallax;
