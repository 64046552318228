let staticContext = null;

export const DynamicLocation = {

    setStaticContext(context) {
        staticContext = context;
    },

    build(to : string) {

        if (/^https?:\/\//.test(to)) {
            return to;
        }

        return `${this.domain()}/${to}`;
    },

    domain() {
        return staticContext
            ? staticContext.domain
            : `${location.protocol}://${location.host}`
    },

    fullUrl() {
        return staticContext
            ? staticContext.fullUrl
            : location.href
    }

};
