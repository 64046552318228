import React, {Component} from 'react';
import appCss from "../../App.module.scss";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {faAlignLeft, faSearch} from "@fortawesome/free-solid-svg-icons";
import ScrollToTopOnMount from "../../helpers/ScrollToTopOnMount";
import {Route} from "react-router-dom";
import AnimatedLoader from "../../shared/AnimatedLoader";
import Heading from "../../layout/Heading";
import Article from "../Article";
import {extractClassNames} from "../../helpers/ExtractClassNames";
import {Switch} from "react-router";
import type {LoadNewsParams} from "../../store/news/actions";
import {cancelNewsSource, loadNews, setArticles, setMeta, setNews} from "../../store/news/actions";
import {connect} from "react-redux";
import LoadGently from "../../layout/LoadGently";
import ListItem from "./ListItem";
import Pagination from "../../navigation/Pagination";
import PaginationConfig from "../../layout/Pagination/PaginationConfig";
import SmartInput from "../../shared/SmartInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MaxLength} from "../../shared/SmartInput/Validators";
import * as queryString from "../../helpers/QueryString";
import NotFound from "../../navigation/NotFound";
import {Helmet} from "react-helmet";
import {DynamicLocation} from "../../helpers/DynamicLocation";

const PerPage = 10;
const SearchDebounceTime = 1000;
const AllowedQueryParameters = ['keywords'];

class News extends Component {

    static serverReq = null;

    static preload = (match, req) => {

        const loaders = [];
        News.serverReq = queryString.parseUrl(req.url);

        if (News.paramsAreValid(match.params)) {
            loaders.push(
                loadNews(News.buildSearchCriteria(match, News.serverReq.query))
            );
        }

        return loaders;
    };

    static buildSearchCriteria(match, query): LoadNewsParams {
        return {
            page: (match.params.page || 1),
            count: PerPage,
            ...(!!query.keywords ? {keywords: query.keywords} : {})
        };
    };

    static buildSearchQuery(rawQuery) {

        const query: Object = typeof rawQuery === "object"
            ? rawQuery
            : queryString.parse(rawQuery);

        const stringQuery = queryString.stringify(Object.keys(query)
            .filter(key => AllowedQueryParameters.includes(key))
            .reduce((obj, key) => {
                obj[key] = query[key];
                return obj;
            }, {}));

        return !!stringQuery ? `?${stringQuery}` : '';
    }

    static paramsAreValid(params) {

        const page = params.page ? parseInt(params.page) : null;

        if (page !== null && (isNaN(page) || page < 1)) {
            return false;
        }

        return true;
    }

    state = {
        activeArticle: null,
        doSearch: false,
        keywordSnapshot: null,
        mounted: false
    };

    componentDidMount(): void {

        // Check if the current URL contains any necessary parameters.
        const query = queryString.parse(this.props.location.search);
        const keywords = query.keywords ? query.keywords.trim() : null;

        // Ensure articles were loaded already by the server.
        if (this.props.articles === null) {
            this.props.loadNews(News.buildSearchCriteria(this.props.match, query));
        }

        if (!!keywords) {

            // Fill search input with keywords.
            this.searchInput.value = keywords;
            // Set keyword snapshot: coming from the server.
            this.setState({keywordSnapshot: keywords});
        }

        this.setState({mounted: true});
    }

    componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any): void {

        const query = queryString.parse(nextProps.location.search);
        const keywords = query.keywords ? query.keywords.trim() : null;


        if (this.searchInput && this.searchInput.value.trim() !== keywords) {
            this.searchInput.value = (keywords || '');
        }

        // Means that keyword was changed.
        if (this.state.mounted && this.state.keywordSnapshot !== keywords) {

            // Reset articles.
            this.props.setArticles(null);
            // Reset meta data.
            this.props.setMeta(null);

            this.setState(() => {

                // Fetch new data.
                this.props.loadNews({
                    page: 1,
                    keywords: query.keywords
                });

                return {keywordSnapshot: keywords};
            });
        }

        // When simply navigated onto another page.
        else if (nextProps.match.params.page !== this.props.match.params.page) {

            // Indicate loader..
            this.props.setArticles(null);

            // Retrieve given page.
            this.props.loadNews({
                page: parseInt(nextProps.match.params.page || 1),
                count: PerPage
            })
        }

    }

    componentWillUnmount(): void {

        // Restore articles.
        this.props.setArticles(null);

        // Restore meta.
        this.props.setMeta(null);

        // Cancel current request.
        this.props.cancelNewsSource();

        // Clearing search input timer.
        this.clearDebounce();
    }

    searchDebounce = null;

    clearDebounce = () => {
        if (this.searchDebounce) {
            clearTimeout(this.searchDebounce);
            this.searchDebounce = null;
        }
    };

    captureKeyword = () => {

        this.clearDebounce();

        this.searchDebounce = setTimeout(() => {

            const keywords = this.searchInput.value.trim();

            if (this.state.keywordSnapshot !== keywords) {
                this.props.history.push({
                    pathname: '/hirek/1',
                    search: `?${queryString.stringify({keywords})}`
                });
            }
        }, SearchDebounceTime);
    };

    setArticle = (to) => {
        if (!!to.thumbnail) {
            this.setState({activeArticle: to});
        }
    };

    unsetArticle = () => {
        if (this.state.activeArticle !== null) {
            this.setState({activeArticle: null});
        }
    };

    backgroundCover = () => (
        <ReactCSSTransitionGroup
            component="div"
            transitionName={{
                enter: 'cover',
                enterActive: 'in',
                leave: 'cover',
                leaveActive: 'out'
            }}
            transitionEnterTimeout={500}
            transitionLeaveTimeout={500}
            transitionEnter={true}
            transitionLeave={true}>
            {this.state.activeArticle
                ? (
                    <div id="backgroundPlaceholder" key={this.state.activeArticle.id} style={{
                        background: "url(" + this.state.activeArticle.thumbnail + ") no-repeat center center",
                        backgroundSize: "cover"
                    }}/>
                )
                : null}
        </ReactCSSTransitionGroup>
    );

    searchInput: HTMLInputElement = null;

    articles = () => (
        <LoadGently className={extractClassNames('overflow-hidden flex-grow-1 d-flex flex-column', appCss)}>
            <div className={extractClassNames('overflow-hidden flex-grow-1 d-flex', appCss)}>
                <div className={extractClassNames('container slide-bottom in', appCss)}>

                    {
                        (this.props.articles && !this.props.articles.length)
                            ? <h5 className={extractClassNames('text-center', appCss)}>Nem találtunk ilyen
                                cikket..</h5>
                            : <div className={appCss['row']}>
                                {this.props.articles.map(a => (
                                    <div className={extractClassNames('py-3 p-md-3 p-lg-4 col-12 col-md-6', appCss)}
                                         key={a.id}>
                                        <div
                                            onMouseEnter={this.setArticle.bind(this, a)}
                                            onMouseLeave={this.unsetArticle}>
                                            <ListItem article={a}/>
                                        </div>
                                    </div>)
                                )}
                            </div>
                    }

                </div>
            </div>
        </LoadGently>
    );

    searchBar = () => (
        <div className={extractClassNames('flex-grow-0 flex-shrink-0 bg-white py-2 slide-top in', appCss)}>
            <div className={extractClassNames('container px-4', appCss)}>
                <div className={appCss['row']}>
                    <SmartInput validateOn={["keyUp"]}
                                autofocus
                                label={(
                                    <label htmlFor="search">
                                        <FontAwesomeIcon icon={faSearch}/> Keresés
                                    </label>
                                )}
                                onPassing={this.captureKeyword}
                                onFailing={this.clearDebounce}
                                counter={20}
                                immediate={true}
                                validators={[
                                    new MaxLength(20)
                                ]}
                                inputRef={(e) => this.searchInput = e}
                                id="search"
                                template={(params => (
                                    <div className={extractClassNames(
                                        (params.isTouched ? (params.isPassing ? '' : 'danger') : ''),
                                        'form-group position-relative col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3',
                                        appCss
                                    )}>
                                        {params.input}
                                        {params.label}
                                        <div className={
                                            extractClassNames(
                                                (params.isCounterPassing ? 'text-success' : 'text-danger'),
                                                (params.focused ? 'in' : 'out'),
                                                'p-1 to-right position-absolute slide-left',
                                                appCss
                                            )
                                        }>
                                            {params.counter}
                                        </div>
                                        <div
                                            className={extractClassNames('text-center mt-2 text-danger slide-right in', appCss)}>
                                            {params.errors}
                                        </div>
                                    </div>
                                ))}
                    />
                </div>
            </div>
        </div>
    );

    list = () => {

        /* In case of badly formatted parameters. */
        if (!News.paramsAreValid(this.props.match.params)) {
            return <NotFound/>
        }

        /* Render list. */
        return (
            <React.Fragment>

                <Helmet>
                    <title>Hírek | SzuniSOFT</title>
                    <meta property="og:title" content="Hírek | SzuniSOFT"/>
                    <meta property="og:description"
                          content="Olvassa el oldalunkon található híreinket, amelyeket szakemberink készítenek, hogy informálják és segítsék a közösségeket."/>
                    <meta name="keywords"
                          content="Hírek,Újdonságok,Technólógia,Programozás,Üzemeltetés,Praktikák,Megoldás,Kérdés,Válasz,Érték"/>
                    <meta name="description"
                          content="Olvassa el oldalunkon található híreinket, amelyeket szakemberink készítenek, hogy informálják és segítsék a közösségeket."/>
                    {
                        this.props.articles && this.props.articles.length
                            ? <meta property="og:image" content={DynamicLocation.build(this.props.articles[0].thumbnail)}/>
                            : null
                    }
                </Helmet>

                <LoadGently className={extractClassNames('d-flex flex-grow-1 flex-column', appCss)}>
                    <Heading heading="Hírek" icon={faAlignLeft}/>

                    {/* Interactive background cover */}
                    {
                        (this.props.articles !== null && this.props.articles.length)
                            ? this.backgroundCover()
                            : null
                    }
                    {/* Search bar */}
                    {
                        this.searchBar()
                    }
                    {/* Animated loader */}
                    {
                        (this.props.articles === null)
                            ? <AnimatedLoader/>
                            : this.articles()
                    }
                    {/* Pagination */}
                    {
                        (this.props.meta !== null)
                            ? <Pagination
                                url="/hirek/:page"
                                search={(
                                    News.buildSearchQuery((
                                        __SERVER__
                                            ? News.serverReq.query
                                            : this.props.location.search
                                    ))
                                )}
                                page={parseInt(this.props.match.params.page || 1)}
                                lastPage={this.props.meta.last_page}
                                limit={3}
                                templates={PaginationConfig}
                            />
                            : null
                    }

                </LoadGently>
            </React.Fragment>
        )
    };

    render() {
        return (
            <React.Fragment>
                <ScrollToTopOnMount/>
                <Switch>
                    <Route exact path="/hirek/:page?" render={this.list}/>
                    <Route exact path="/hirek/cikk/:id/:title" component={Article}/>
                </Switch>
            </React.Fragment>
        );
    }
}

News.propTypes = {};

const mapStateToProps = (state) => ({
    articles: state.news.news.articles,
    meta: state.news.news.meta
});

const mapDispatchToProps = (dispatch) => ({
    loadNews: (params: LoadNewsParams) => dispatch(loadNews(params)),
    setArticles: (to) => dispatch(setArticles(to)),
    setMeta: (to) => dispatch(setMeta(to)),
    cancelNewsSource: () => dispatch(cancelNewsSource())
});

export default connect(mapStateToProps, mapDispatchToProps)(News);
