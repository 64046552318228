import React from 'react';
import {Helmet} from "react-helmet";
import LoadGently from "../../../layout/LoadGently";
import ProductTheme from "../../Products/ProductTheme";
import css from "./ProjectManager.module.scss";
import ScrollToTopOnMount from "../../../helpers/ScrollToTopOnMount";
import ImageLoader from "../../../shared/ImageLoader";
import AnimatedLoader from "../../../shared/AnimatedLoader";
import Heading from "../../../layout/Heading";
import {Section} from "../../Products/Product";
import appCss from "../../../App.module.scss";
import {extractClassNames} from "../../../helpers/ExtractClassNames";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TechnologyList from "../../../shared/Technologies/TechnologyList";
import {Technologies} from "../../Index/Technologies";
import LoaderParallax from "../../../layout/LoaderParallax";
import {DynamicLocation} from "../../../helpers/DynamicLocation";

const ParallaxStyle = {
    height: '50vw',
    minHeight: '500px',
};

const Section1Image = require('./assets/section-1.jpg');
const Section2Image = require('./assets/section-2.jpg');
const Section3Image = require('./assets/section-3.jpg');

const PreloadImages = [
    Section1Image,
    Section2Image,
    Section3Image,
];

class ProjectManager extends React.PureComponent {
    render() {
        return (
            <React.Fragment>

                <Helmet>
                    <title>Projekt és munka idő kezelő | Projektek | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Projekt,Idő,Kezelő,Munka,Beosztás,Hatékony,Nyomonkövetés,Rendezés,Szoftver,Web"/>
                    <meta name="description"
                          content="Projekt és munkaidő kezelő szoftverünket ügyfelünk rendelte meg, hogy segítse projektjei nyomonkövetését, rendezését és ügyfeleinek pontos díjszabását."/>
                    <meta property="og:title" content="Projekt és munka idő kezelő | Projektek | SzuniSOFT"/>
                    <meta property="og:description"
                          content="	Projekt és munkaidő kezelő szoftverünket ügyfelünk rendelte meg, hogy segítse projektjei nyomonkövetését, rendezését és ügyfeleinek pontos díjszabását."/>
                    <meta property="og:image" content={DynamicLocation.build(PreloadImages[1].src)}/>
                </Helmet>

                <LoadGently>

                    <ProductTheme productThemeId={css['project-project-manager-product-theme']}
                                  siteThemeId={css['project-project-manager-site-theme']}
                    >
                        <ScrollToTopOnMount/>

                        <ImageLoader multiple={PreloadImages}
                                     loading={<AnimatedLoader/>}
                                     loaded={
                                         <React.Fragment>

                                             <Heading heading="Projekt és munkaidő kezelő"
                                                      breadCrumb={[
                                                          {text: 'Projektek', to: '/projektek'},
                                                          'Projekt Menedzser'
                                                      ]}
                                             />

                                             <LoaderParallax srcSet={Section1Image.srcSet} src={Section1Image.src}
                                                             parallaxStrength={-500}>
                                                 <div style={ParallaxStyle}/>
                                             </LoaderParallax>

                                             <Section themeCss={css}>

                                                 <div>
                                                     <div className={appCss['container']}>
                                                         <div
                                                             className={extractClassNames('d-flex flex-column text-center text-lg-left flex-lg-row align-items-center', appCss) + ' ' + css['meta']}>
                                                             <div className={extractClassNames('flex-grow-1', appCss)}>
                                                                 <h5 className={css['theme']}>Fény derült az
                                                                     igazságra!</h5>
                                                                 <ul>
                                                                     <li>Ügyfél: <b>Lounge Communication</b></li>
                                                                     <li>Év: <b>2017</b></li>
                                                                     <li>Időtartam: <b>6 hónap</b></li>
                                                                 </ul>
                                                             </div>

                                                             <div
                                                                 className={extractClassNames('flex-grow-0 flex-shrink-0 mt-4 mt-lg-0 mb-lg-0', appCss)}>
                                                                 <div className={css['icons']}>
                                                                     {[faGlobe].map((icon, i) =>
                                                                         <div
                                                                             className={css['icon']}
                                                                             key={i}>
                                                                             <div className={css['icon-wrapper']}>
                                                                                 <FontAwesomeIcon icon={icon}/>
                                                                             </div>
                                                                         </div>)}
                                                                 </div>
                                                             </div>

                                                         </div>

                                                     </div>

                                                     <div
                                                         className={extractClassNames('py-3', appCss) + ' ' + css['bg-theme']}>
                                                         <div className={appCss['container']}>
                                                             <div>
                                                                 <TechnologyList technologies={[
                                                                     Technologies.PHP,
                                                                     Technologies.Laravel,
                                                                     Technologies.Azure,
                                                                     Technologies.JavaScript
                                                                 ]}/>
                                                             </div>
                                                         </div>
                                                     </div>

                                                     <div className={appCss['container']}>

                                                         <p className={css['theme']}>Az ügyfelünk olyan igényt tárt
                                                             elénk, amivel szeretné hatékonyabbá tenni a projektek
                                                             nyomon követését, valamint a saját ügyfelei irányába történő
                                                             pénzügyi elszámolást. Szeretné nyomon követni, hogy
                                                             alkalmazottai hozzávetőlegesen mennyi időt fordítanak az
                                                             egyes ügyfelekre.
                                                             Fontos elem volt, hogy bár a megoldáshoz rengeteg eddig nem
                                                             gyűjtött információt kell bekérni a dolgozóktól
                                                             munkájukról, a rendszer használata a lehető legkisebb
                                                             többletterhelés mellett váljon működőképessé.</p>
                                                     </div>
                                                 </div>

                                             </Section>

                                             <LoaderParallax srcSet={Section2Image.srcSet} src={Section2Image.src}
                                                             parallaxStrength={500}>
                                                 <div style={ParallaxStyle}/>
                                             </LoaderParallax>

                                             <Section themeCss={css}>
                                                 <div>
                                                     <div className={appCss['container']}>
                                                         <h5 className={css['theme']}>Egyszerű, de praktikus!</h5>
                                                         <p className={css['theme']}>Az ügyfél egyéni igényeit és
                                                             munkamódszerét megvizsgálva a program felületeit
                                                             szerepkörök szerint bontottuk szét. Minden felhasználó csak
                                                             a neki szerepköre szerint szükséges elemekkel találkozik s
                                                             a napi munkában leggyakoribb elemek kerülnek a
                                                             leggyorsabban elérhető helyekre.
                                                             Az integráció során meghatároztuk a kezdeti felhasználáshoz
                                                             legfontosabb adatokat, melyek feltöltésében közvetlenül is
                                                             segédkeztünk. Igyekeztünk automatikusan előállítható vagy
                                                             intuitíven megbecsülhető adatok körét úgy kiszélesíteni,
                                                             hogy a szoftver minimális gépelés mellett, néhány
                                                             egérkattintással is hasznos statisztikákat eredményezzen.
                                                         </p>
                                                     </div>

                                                     {/*<LoaderParallax srcSet={Section2Image.srcSet} src={Section2Image.src} parallaxStrength={500}>
                                                         <div style={ParallaxStyle}/>
                                                     </LoaderParallax>*/}
                                                 </div>
                                             </Section>

                                             <LoaderParallax srcSet={Section3Image.srcSet} src={Section3Image.src}
                                                             parallaxStrength={500}>
                                                 <div style={ParallaxStyle}/>
                                             </LoaderParallax>

                                             <Section themeCss={css}>
                                                 <div>
                                                     <div className={appCss['container']}>
                                                         <h5 className={css['theme']}>Az igazi lényeg
                                                         </h5>
                                                         <p className={css['theme']}>Megoldásunkkal elértük, hogy a
                                                             vezetőség könnyedén jutott olyan információhoz, ami
                                                             támogatta döntéseit az ügyfelekkel szembeni árképzéseknél,
                                                             a munkavállalói hatékonyság kiértékelésekor és a projektek
                                                             állapotának követésében. Összességében hozzájárult a
                                                             vállalat nyereségtermelő képességének növeléséhez.
                                                         </p>
                                                     </div>
                                                 </div>
                                             </Section>


                                         </React.Fragment>
                                     }
                        />

                    </ProductTheme>

                </LoadGently>
            </React.Fragment>
        );
    }
}

ProjectManager.propTypes = {};

export default ProjectManager;
