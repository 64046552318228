import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SiteTheme from "../../layout/SiteTheme";

class ProductTheme extends Component {
    render() {
        return (
            <div id={this.props.productThemeId}>
                <SiteTheme id={this.props.siteThemeId}>
                    {this.props.children}
                </SiteTheme>
            </div>
        );
    }
}

ProductTheme.propTypes = {
    productThemeId: PropTypes.string.isRequired,
    siteThemeId: PropTypes.string.isRequired
};

export default ProductTheme;
