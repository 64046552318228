import React, {Component} from 'react';
import PropTypes from 'prop-types';
import ScrollTrigger from "react-scroll-trigger";
import Breakpoints from "../breakpoints";

class ScrollThresholdTrigger extends Component {

    state = {
        previousPosition: null,
        status: null,
        wasInEver: false,
        mounted: false,
        isMobile: false
    };

    debug(...args) {
        if (this.props.debug === true) {
            console.log(...args);
        }
    }

    componentDidMount() {
        this.setState({mounted: true});
        window.addEventListener('resize', this.ensureMobile);
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.ensureMobile);
    }

    ensureMobile = () => {

        const isMobile = typeof window !== "undefined" && window.innerWidth <= parseInt(Breakpoints["grid-breakpoints"]["md"]);

        if (isMobile !== this.state.isMobile) {
            this.setState({isMobile});
        }
    };

    onProgress = (progress, e) => this.setState((prevState) => {

        let newState = {
            previousPosition: e.state.lastScrollPosition
        };

        const progressPercent = progress.progress * 100;

        if (prevState.status !== 'in' &&
            progressPercent > this.props.triggerPercent) {

            newState.status = 'in';
            newState.wasInEver = true;
            if (this.props.onIn && typeof this.props.onIn === "function") {
                this.props.onIn();
            }

        } else if (prevState.status !== 'out' &&
            prevState.wasInEver === true &&
            !!prevState.previousPosition &&
            progressPercent <= this.props.triggerPercent) {

            newState.status = 'out';
            if (this.props.onOut && typeof this.props.onOut === "function") {
                this.props.onOut();
            }
        }

        return newState;
    });

    render() {

        if (this.state.isMobile) {
            return (typeof this.props.children === "function")
                ? this.props.children({isIn: false, isOut: false})
                : this.props.children;
        }

        return (
            <ScrollTrigger onProgress={this.onProgress} triggerOnLoad={true} throttleScroll={50}>
                {
                    typeof this.props.children === "function"
                        ? this.props.children({isIn: this.state.status === "in", isOut: this.state.status === "out"})
                        : this.props.children
                }
            </ScrollTrigger>
        );
    }
}

ScrollThresholdTrigger.propTypes = {
    triggerPercent: PropTypes.number,
    onIn: PropTypes.func,
    onOut: PropTypes.func,
    debug: PropTypes.bool
};

export default ScrollThresholdTrigger;
