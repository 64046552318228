import React from 'react';
import PropTypes from 'prop-types';
import {generatePath, NavLink} from "react-router-dom";

class Pagination extends React.PureComponent {

    static defaultProps = {
        simplified: false,
        limit: 5,
        showPrevNext: true,
        templates: {},
        params: {}
    };

    generatePath(to) {
        return generatePath(this.props.url, {
            ...this.props.params,
            page: to
        });
    }

    outSourceLinkGeneration(to, which) {

        return which({
            pathname: this.generatePath(to),
            search: this.props.search,
            page: to
        });
    }

    render() {

        /* Return nothing in case of only page. */
        if (this.props.lastPage <= 1) {
            return (<React.Fragment/>);
        }

        /* Including first page */
        const links = [];
        const lowestLimit = this.props.page - this.props.limit - 1;

        /* First page */
        if (this.props.page > 1 && lowestLimit > 0) {
            links.push(
                React.cloneElement((
                    this.props.templates.firstNav
                        ? this.outSourceLinkGeneration(1, this.props.templates.firstNav)
                        : (<NavLink to={this.generatePath(1)}>első</NavLink>)
                ), {key: 'first-page'})
            );
        }

        /* Prev page */
        if (this.props.showPrevNext === true && this.props.page > 2) {
            links.push(
                React.cloneElement((
                    this.props.templates.prevNav
                        ? this.outSourceLinkGeneration(this.props.page - 1, this.props.templates.prevNav)
                        : (<NavLink to={this.generatePath(this.props.page - 1)}>előző</NavLink>)
                ), {key: 'prev-page'})
            )
        }

        /* Intermediate links */
        let i = this.props.page - 1;
        const previousOnes = [];
        while (i > 0 && i > lowestLimit) {
            previousOnes.push(
                React.cloneElement((
                    this.props.templates.stepNav
                        ? this.outSourceLinkGeneration(i, this.props.templates.stepNav)
                        : (<NavLink to={this.generatePath(i)}>{i}</NavLink>)
                ), {key: i})
            );
            i--;
        }
        /* Append generated elements in a reversed order. */
        links.push(...previousOnes.reverse());

        /* Append CURRENT page */
        links.push(
            React.cloneElement((
                this.props.templates.currentNav
                    ? this.outSourceLinkGeneration(this.props.page, this.props.templates.currentNav)
                    : (<NavLink to={this.generatePath(this.props.page)}>{this.props.page}</NavLink>)
            ), {key: this.props.page})
        );

        /* Continue with upcoming page links.. */
        i = this.props.page + 1;
        let highestLimit = this.props.page + this.props.limit + 1;
        if (highestLimit > this.props.lastPage + 1) {
            highestLimit = this.props.lastPage + 1;
        }
        while (i <= this.props.lastPage && i < highestLimit) {
            links.push(
                React.cloneElement((
                    this.props.templates.stepNav
                        ? this.outSourceLinkGeneration(i, this.props.templates.stepNav)
                        : (<NavLink to={this.generatePath(i)}>{i}</NavLink>)
                ), {key: i})
            );
            i++;
        }

        /* Next page */
        if (this.props.showPrevNext === true &&
            this.props.page < this.props.lastPage - 1) {
            links.push(
                React.cloneElement((
                    this.props.templates.nextNav
                        ? this.outSourceLinkGeneration(this.props.page + 1, this.props.templates.nextNav)
                        : (<NavLink to={this.generatePath(this.props.page + 1)}>következő</NavLink>)
                ), {key: 'next-page'})
            );
        }

        /* Last page */
        if (highestLimit <= this.props.lastPage) {
            links.push(
                React.cloneElement((
                    this.props.templates.lastNav
                        ? this.outSourceLinkGeneration(this.props.lastPage, this.props.templates.lastNav)
                        : (<NavLink to={this.generatePath(this.props.lastPage)}>utolsó</NavLink>)
                ), {key: 'last-page'})
            );
        }

        //links = links.map((e, i) => React.cloneElement(e, {key: i}));

        if (this.props.templates.wrapper) {
            const wrapper = typeof this.props.templates.wrapper === "function"
                ? this.props.templates.wrapper()
                : this.props.templates.wrapper;

            return React.cloneElement(wrapper, {children: links})
        } else {
            return links;
        }
    }
}

Pagination.propTypes = {

    url: PropTypes.string.isRequired,
    params: PropTypes.any,
    search: PropTypes.string,
    page: PropTypes.number.isRequired,
    lastPage: PropTypes.number.isRequired,
    limit: PropTypes.number,
    showPrevNext: PropTypes.bool,

    templates: PropTypes.shape({
        wrapper: PropTypes.any,
        firstNav: PropTypes.func,
        lastNav: PropTypes.func,
        nextNav: PropTypes.func,
        prevNav: PropTypes.func,
        stepNav: PropTypes.func,
        currentNav: PropTypes.func
    })
};

export default Pagination;
