import React from 'react';
import {NavLink} from "react-router-dom";
import appCss from '../../App.module.scss';
import css from './Pagination.module.scss';
import {extractClassNames} from "../../helpers/ExtractClassNames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

export default {
    firstNav: ({pathname, search}) => (
        <NavLink className={css.link} to={{pathname, search}}>első</NavLink>
    ),
    lastNav: ({pathname, search}) => (
        <NavLink className={css.link} to={{pathname, search}}>utolsó</NavLink>
    ),
    nextNav: ({pathname, search}) => (
        <NavLink className={css.link} to={{pathname, search}}><FontAwesomeIcon icon={faChevronRight}/></NavLink>
    ),
    prevNav: ({pathname, search}) => (
        <NavLink className={css.link} to={{pathname, search}}><FontAwesomeIcon icon={faChevronLeft}/></NavLink>
    ),
    stepNav: ({pathname, search, page}) => (
        <NavLink className={css.link} to={{pathname, search}}>{page}</NavLink>
    ),
    currentNav: ({pathname, search, page}) => (
        <NavLink activeClassName={css.active} className={extractClassNames('active link', css)} to={{pathname, search}}>{page}</NavLink>
    ),
    wrapper:  <ReactCSSTransitionGroup
        className={css.pagination + ' ' + extractClassNames('flex-grow-0 flex-shrink-0 row py-4 bg-dark', appCss)}
        component="div"
        transitionName={{
            enter: 'scale-vertical-center',
            enterActive: 'in',
        }}
        transitionEnterTimeout={500}
        transitionLeave={false}
    />
}
