import React, {Component} from 'react';
import appCss from "../../../App.module.scss";
import {extractClassNames} from "../../../helpers/ExtractClassNames";
import ScrollToTopOnMount from "../../../helpers/ScrollToTopOnMount";
import Heading from "../../../layout/Heading";
import {faMicrosoft} from "@fortawesome/free-brands-svg-icons";
import css from "./Microsoft.module.scss";
import PriceTable from "../../../shared/PriceTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import LoadGently from "../../../layout/LoadGently";
import {Helmet} from "react-helmet";

const Apps = (
    <React.Fragment>
        <img src={require('./assets/Word.png')} alt="Office Word" className={appCss['mx-1']}/>
        <img src={require('./assets/Excel.png')} alt="Office Excel" className={appCss['mx-1']}/>
        <img src={require('./assets/PowerPoint.png')} alt="Office Power Point" className={appCss['mx-1']}/>
        <img src={require('./assets/Outlook.png')} alt="Office Outlook" className={appCss['mx-1']}/>
        <img src={require('./assets/OneNote.png')} alt="Office One Note" className={appCss['mx-1']}/>
        <img src={require('./assets/Publisher.png')} alt="Office Publisher" className={appCss['mx-1']}/>
    </React.Fragment>
);

class O365Prices extends Component {
    render() {
        return (
            <LoadGently className={extractClassNames('d-flex flex-column flex-grow-1', appCss)}>

                <Helmet>
                    <title>O365 | Szolgáltatás | SzuniSOFT</title>
                    <meta name="keywords"
                          content="Microsoft,Termék,Office,Azure,Irodai,Szoftvercsomag,Megoldások,Használhatóság"/>
                    <meta name="description"
                          content="Több éves Microsoft partneri tapasztalattal ajánljuk a termékeiket a mindennapi és a szélsőséges szükségletekhez."/>
                    <meta property="og:title" content="Microsoft terméktámogatás | Szolgáltatás | SzuniSOFT"/>
                    <meta property="og:description"
                          content="Több éves Microsoft partneri tapasztalattal ajánljuk a termékeiket a mindennapi és a szélsőséges szükségletekhez."/>
                </Helmet>


                <ScrollToTopOnMount/>
                <Heading heading="O365 csomagok és árak"
                         icon={faMicrosoft}
                         breadCrumb={[
                             {text: 'Szolgáltatások', to: '/szolgaltatasok'},
                             {text: 'Microsoft terméktámogatás', to: '/szolgaltatasok/microsoft-termektamogatas'},
                             'Office 365'
                         ]}
                />
                <div className={extractClassNames('extended-container d-flex justify-content-lg-center py-lg-5 px-0 px-lg-5', appCss)} id={css['microsoftServicing']}>
                    <PriceTable
                        id={css['office365PriceTable']}
                        css={css}
                        allowedIcon={(<FontAwesomeIcon icon={faCheck}/>)}
                        disallowedIcon={(<FontAwesomeIcon icon={faTimes}/>)}
                        attributes={{
                            price: 'Ár',
                            englishName: 'Angol elnevezés',
                            maxUser: 'Felhasználók maximum száma',
                            office2016Apps: 'Office 2016 alkalmazások',
                            iPadAndApps: 'iPad és okostelefon app-ok',
                            officeOnline: 'Office Online',
                            oneTbOneDrive: '1 TB OneDrive-tárterülettel',
                            exchangeEmail: '50GB E-mail (Exchange Online)',
                            onlineChat: 'Online értekezletek, chat (Sykpe)',
                            groupWebsites: 'Csoportwebhely (Sharepoint)',
                            commitment: 'Elkötelezettség'
                        }}
                        variations={[
                            {
                                heading: (<div className={extractClassNames('d-flex flex-column', appCss)}>
                                    <h5 className={extractClassNames('m0 py-2 text-center flex-grow-1 mb-0', appCss)}>Exchange<br/>online</h5>
                                    <h4 className={extractClassNames('m0 pb-2 text-center flex-grow-0 mb-0 text-truncate', appCss)}>1074Ft / hó</h4>
                                </div>),
                                attributes: {
                                    price: '€ 4.2 + ÁFA',
                                    englishName: 'Exchange Online',
                                    maxUser: 'korlátlan',
                                    office2016Apps: false,
                                    iPadAndApps: false,
                                    officeOnline: false,
                                    oneTbOneDrive: true,
                                    exchangeEmail: true,
                                    onlineChat: false,
                                    groupWebsites: false,
                                    commitment: '12 hónap'
                                }
                            },
                            {
                                heading: (<div className={extractClassNames('d-flex flex-column', appCss)}>
                                    <h5 className={extractClassNames('m0 py-2 text-center flex-grow-1 mb-0', appCss)}>Office 365<br/>vállalati</h5>
                                    <h4 className={extractClassNames('m0 pb-2 text-center flex-grow-0 mb-0 text-truncate', appCss)}>2820Ft / hó</h4>
                                </div>),
                                highlighted: true,
                                attributes: {
                                    price: '€ 8,8 + ÁFA',
                                    englishName: 'Office 365 Business',
                                    maxUser: '300',
                                    office2016Apps: Apps,
                                    iPadAndApps: true,
                                    officeOnline: true,
                                    oneTbOneDrive: true,
                                    exchangeEmail: false,
                                    onlineChat: false,
                                    groupWebsites: false,
                                    commitment: '12 hónap'
                                }
                            },
                            {
                                heading: (<div className={extractClassNames('d-flex flex-column', appCss)}>
                                    <h5 className={extractClassNames('m0 py-2 text-center flex-grow-1 mb-0', appCss)}>Office 365<br/>vállalati prémium</h5>
                                    <h4 className={extractClassNames('m0 pb-2 text-center flex-grow-0 mb-0 text-truncate', appCss)}>3318Ft / hó</h4>
                                </div>),
                                attributes: {
                                    price: '€ 10.5 + ÁFA',
                                    englishName: 'Office 365 Business Premium',
                                    maxUser: '300',
                                    office2016Apps: Apps,
                                    iPadAndApps: true,
                                    officeOnline: true,
                                    oneTbOneDrive: true,
                                    exchangeEmail: true,
                                    onlineChat: true,
                                    groupWebsites: true,
                                    commitment: '12 hónap'
                                }
                            }
                        ]}
                        recommended={3}
                    />
                </div>
            </LoadGently>
        );
    }
}

O365Prices.propTypes = {};

export default O365Prices;
