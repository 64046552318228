import React, {Component} from 'react';
import css from "./Article.module.scss";
import appCss from "../../App.module.scss";
import NotFound from "../../navigation/NotFound";
import Moment from "react-moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faClock, faUser} from "@fortawesome/free-regular-svg-icons";
import {faFacebookSquare, faTwitterSquare} from "@fortawesome/free-brands-svg-icons";
import {Sticky, StickyContainer} from "react-sticky";
import AnimatedLoader from "../../shared/AnimatedLoader";
import {connect} from "react-redux";
import {cancelArticleSource, loadArticle, setArticle} from "../../store/news/actions";
import type {ArticleT} from "../../store/news/types";
import {extractClassNames} from "../../helpers/ExtractClassNames";
import LoadGently from "../../layout/LoadGently";
import ArticleContent from "./ArticleContent";
import ImageLoader from "../../shared/ImageLoader";
import IconLogoLoader from "../../shared/IconLogoLoader";
import SearchLink from "../News/SearchLink";
import * as queryString from "../../helpers/QueryString";
import Status from "../../navigation/Status";
import {Helmet} from "react-helmet";
import {FacebookShareButton, TwitterShareButton} from "react-share";
import {DynamicLocation} from "../../helpers/DynamicLocation";

class Article extends Component {

    static serverReq = null;

    static preload = (match, req) => {

        const loaders = [];
        Article.serverReq = queryString.parseUrl(req.url);

        if (Article.paramsAreValid(match.params)) {
            loaders.push(
                loadArticle(match.params.id)
            );
        }

        return loaders;
    };

    static paramsAreValid(params) {

        const id = params.id ? parseInt(params.id) : null;

        if (id !== null && (isNaN(id) || id < 1)) {
            return false;
        }

        return true;

    }

    componentDidMount() {
        if (this.props.article === null) {
            this.props.loadArticle(this.props.match.params.id);
        }
    }

    componentWillUnmount(): void {

        // Clear current request.
        this.props.cancelArticleSource(this.props.match.params.id);

        // Reset article.
        this.props.setArticle(null);

    }

    getActualUrl() {
        return __SERVER__
            ? this.props.staticContext.fullUrl
            : window.location.toString();
    }

    article = () => {

        // SERVER: In case of coming with right identifier but the short URI not equals.
        if (__SERVER__ && this.props.article.shortUrl !== this.props.match.params.title) {

            // Set redirection path.
            this.props.staticContext.url = `/hirek/cikk/${this.props.match.params.id}/${this.props.article.shortUrl}`
            return (<Status code={301}/>)
        }

        return (
            <LoadGently>

                <Helmet>
                    <title>{this.props.article.title} | SzuniSOFT</title>
                    <meta name="keywords"
                          content={this.props.article.tags.join(",")}/>
                    <meta name="description"
                          content={this.props.article.previewContent}/>
                    <meta property="og:type" content="article"/>
                    <meta property="og:title" content={`${this.props.article.title} | SzuniSOFT`}/>
                    <meta property="og:description" content={this.props.article.previewContent}/>
                    {
                        this.props.article.thumbnail
                            ? <meta property="og:image" content={DynamicLocation.build(this.props.article.openGraphThumbnail)}/>
                            : null
                    }
                </Helmet>

                <div>
                    {
                        !!this.props.article.thumbnail
                            ? <div className={css['cover']}>
                                <ImageLoader
                                    src={this.props.article.thumbnail}
                                    loading={(
                                        <IconLogoLoader className={extractClassNames('w-100 h-100 bg-dark', appCss)}/>

                                    )}
                                    loaded={(
                                        <div className={extractClassNames('w-100 h-100 fade in', appCss)} style={{
                                            background: "url(" + this.props.article.thumbnail + ") no-repeat center center",
                                            backgroundSize: "cover"
                                        }}/>
                                    )}
                                >
                                </ImageLoader>
                            </div>
                            : null
                    }
                    <div className={extractClassNames('container flex-grow-1', appCss)}>
                        <div className={appCss['row']}>
                            <StickyContainer className={extractClassNames('col-12 col-lg-4 container', appCss)}>
                                <Sticky
                                    topOffset={(__BROWSER__
                                            ? parseFloat(getComputedStyle(document.documentElement).fontSize) * -4
                                            : 0
                                    )}
                                    disableCompensation={true}
                                >{({style}) =>
                                    <div className={css['heading']} style={style}>
                                        <h2 className={extractClassNames('slide-top in', appCss)}>{this.props.article.title}</h2>
                                        <div className={appCss['pt-2']}>
                                            <div
                                                className={extractClassNames('my-1 d-flex align-items-center', appCss)}>

                                                {
                                                    (!!this.props.article.authorAvatar)
                                                        ? <div
                                                            className={css['avatar-wrapper'] + ' ' + extractClassNames('flex-grow-0 flex-shrink-0', appCss)}>
                                                            <ImageLoader src={this.props.article.authorAvatar}
                                                                         loading={(
                                                                             <IconLogoLoader
                                                                                 className={extractClassNames('w-100 bg-dark', appCss) + ' ' + css['avatar']}/>
                                                                         )}
                                                                         loaded={(
                                                                             <img
                                                                                 className={css['avatar'] + ' ' + extractClassNames('fade in', appCss)}
                                                                                 src={this.props.article.authorAvatar}
                                                                                 alt="Author avatar"/>
                                                                         )}/>
                                                        </div>
                                                        : <FontAwesomeIcon icon={faUser}
                                                                           className={extractClassNames('mr-2 scale-center in', appCss)}/>
                                                }
                                                <h5 className={
                                                    (!!this.props.article.authorAvatar ? appCss['pl-3'] : '') + ' ' +
                                                    extractClassNames('mb-0 slide-right in flex-grow-1', appCss)
                                                }>{this.props.article.authorName}</h5>
                                            </div>
                                            <div className={appCss['my-1']}>
                                                <small>
                                                    <FontAwesomeIcon icon={faClock}
                                                                     className={extractClassNames('mr-2 scale-center in', appCss)}/>
                                                    <span>{this.props.article.minReadMinutes}</span>
                                                    {(
                                                        this.props.article.minReadMinutes !== this.props.article.maxReadMinutes
                                                            ? (<span>-{this.props.article.maxReadMinutes}</span>)
                                                            : null
                                                    )}p
                                                </small>
                                            </div>
                                            <div className={appCss['my-1']}>
                                                <FontAwesomeIcon icon={faCalendar}
                                                                 className={extractClassNames('mr-2 scale-center in', appCss)}/>
                                                <small>
                                                    <Moment format="YYYY MMM DD">{this.props.article.createdAt}</Moment>
                                                </small>
                                                <span> - </span>
                                                <small>
                                                    <Moment fromNow>{this.props.article.createdAt}</Moment>
                                                </small>
                                            </div>
                                        </div>
                                        <div
                                            className={extractClassNames('slide-bottom in mt-2', appCss) + ' ' + css['social']}>
                                            <FacebookShareButton url={this.getActualUrl()} className={appCss['mr-2']}>
                                                <FontAwesomeIcon icon={faFacebookSquare} size={"2x"}
                                                                 className={extractClassNames('text-facebook-hover', appCss)}/>
                                            </FacebookShareButton>
                                            <TwitterShareButton url={this.getActualUrl()} className={appCss['ml-2']}>
                                                <FontAwesomeIcon icon={faTwitterSquare} size={"2x"}
                                                                 className={extractClassNames('text-twitter-hover', appCss)}/>
                                            </TwitterShareButton>
                                        </div>
                                        <div className={css['tags']}>
                                            {this.props.article.tags.map(t => (
                                                <SearchLink key={t} keywords={t}>
                                                    <small>{t}</small>
                                                </SearchLink>
                                            ))}
                                        </div>
                                    </div>
                                }</Sticky>
                            </StickyContainer>
                            <div className={extractClassNames('col-12 col-lg-8 container flex-grow-1', appCss)}>
                                <ArticleContent className={extractClassNames('fade in', appCss) + ' ' + css['content']}
                                                content={this.props.article.content}/>
                            </div>
                        </div>
                    </div>
                </div>
            </LoadGently>
        );
    };

    render() {

        /* In case of badly formatted parameters. */
        if (!Article.paramsAreValid(this.props.match.params)) {
            return <NotFound/>
        }

        return (
            <div className={css['article']}>
                {
                    this.props.article === null
                        ? <AnimatedLoader/>
                        : this.props.article === false
                        ? <NotFound/>
                        : this.article()
                }
            </div>
        );
    }
}

Article.propTypes = {};

const mapStateToProps = (state) => ({
    article: state.news.article
});

const mapDispatchToProps = (dispatch) => ({
    loadArticle: (id: number) => dispatch(loadArticle(id)),
    setArticle: (to: ArticleT | null) => dispatch(setArticle(to)),
    cancelArticleSource: (id: number) => dispatch(cancelArticleSource(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Article);
