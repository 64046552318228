export function extractClassNames(names, from) {

    if (arguments.length < 2) {
        throw new Error('Class extractor awaits at least two arguments!');
    }

    const params = [].slice.call(arguments);

    names = params.slice(0, params.length - 1);
    from = params[params.length - 1];

    // Convert raw string into an array
    if (!Array.isArray(names)) {
        names = [names];
    }

    names = names.map(name => (typeof name === "string" ? name.split(" ") : name));
    names = names.flat();

    return names.map(n => from[n] || n).join(" ");
}
